import React, { useEffect, useState } from "react";
// import logo from "../../Assets/images/logo.png";
import { Link, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { listAllUsers } from "../../Redux/Actions/allUsersActions";
import { secret_key } from "../../APIProxy/secret";
import * as CryptoJS from "crypto-js";

export default function Sidebar({ isOpen }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.allUsers);
  const [userType, setUserType] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [clientParentId, setClientParentId] = useState(null);
  const isLinkActive = (path) => {
    return location.pathname.includes(path);
  };

  // useEffect(() => {
  //   dispatch(listAllUsers());
  // }, [dispatch]);

  useEffect(() => {
    const userRole = Cookies.get("userRole");
    const encryptedUserId = Cookies.get("userId");
    const encryptedParentId = Cookies.get("parentId");
    if (!!userRole && !!encryptedUserId) {
      const decryptedBytes =
        !!encryptedUserId && CryptoJS.AES.decrypt(encryptedUserId, secret_key);
      const userId = decryptedBytes.toString(CryptoJS.enc.Utf8);
      // const user =
      //   users &&
      //   users.allUsers.listData &&
      //   users.allUsers.listData.find((item) => item.id === userId);

        const parentDecryptedBytes =
        !!encryptedParentId && CryptoJS.AES.decrypt(encryptedParentId, secret_key);
      const parentId = parentDecryptedBytes.toString(CryptoJS.enc.Utf8);
        
        const loggedInUserId = userId;
        const loggedInParentId = parentId;

        setClientId(loggedInUserId);
        setClientParentId(loggedInParentId);
      // user && setClientId(user.id);
      // user && setClientParentId(user.parentID);
      if (userRole && userRole === "Admin") {
        setUserType("Admin");
      }
      if (userRole && userRole === "Employee") {
        setUserType("Employee");
      }
      if (userRole && userRole === "Client") {
        setUserType("Client");
      }
      if (userRole && userRole === "ClientUser") {
        setUserType("ClientUser");
      }
    }
  }, [users, clientId]);
  return (
    <div className={`sidebar pe-4 pb-3 ${isOpen ? "open" : ""}`}>
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css"
      />
      <nav className="navbar pt-0">
        <Link className="navbar-brand mx-4 mb-3">
          <img
            src={"/Assets/images/logo.png"}
            alt="Logo"
            className="img-fluid"
          />
        </Link>
        <div className="navbar-nav w-100">
          {(userType === "Admin" || userType === "Employee") && (
            <>
              <Link
                to="/admin"
                className={`nav-item nav-link ${
                  isLinkActive("/admin") ? "active" : ""
                }`}
              >
                <i className="bi bi-person" />
                Users{" "}
              </Link>
              <Link
                to="/clients"
                className={`nav-item nav-link ${
                  isLinkActive("/clients") ||
                  isLinkActive("/projectList") ||
                  isLinkActive("/ProjectDetails") ||
                  isLinkActive("/ProjectReport")
                    ? "active"
                    : ""
                }`}
              >
                <i className="bi bi-people"></i>
                Clients
              </Link>
              <Link
                to="/mastersApproval"
                className={`nav-item nav-link ${
                  isLinkActive("/mastersApproval") ? "active" : ""
                }`}
              >
                <i className="bi bi-check2-circle"></i>
                Approvals
              </Link>
              <Link
                to="/changeRequestApproval"
                className={`nav-item nav-link ${
                  isLinkActive("/changeRequestApproval") ||
                  isLinkActive("/changeRequestProjectReports")
                    ? "active"
                    : ""
                }`}
              >
                <i className="bi bi-check2-circle"></i>
                Request Approvals
              </Link>
              <Link
                to="/barcode"
                className={`nav-item d-block d-sm-none nav-link ${
                  isLinkActive("/barcode") ? "active" : ""
                }`}
              >
                <i className="bi bi-upc-scan"></i>
                Barcode
              </Link>
            </>
          )}
          {userType === "Client" && clientId && (
            <>
              <Link
                to="/dashboard"
                className={`nav-item nav-link ${
                  isLinkActive("/dashboard") ? "active" : ""
                }`}
              >
                {" "}
                <i className="bi bi-speedometer" />
                Dashboard{" "}
              </Link>
              <Link
                to="/users"
                className={`nav-item nav-link ${
                  isLinkActive("/users") ? "active" : ""
                }`}
              >
                <i className="bi bi-people" />
                Users{" "}
              </Link>
              <Link
                to={`/projectList/${clientId}`}
                className={`nav-item nav-link ${
                  isLinkActive(`/projectList/${clientId}`) ||
                  isLinkActive("/ProjectReport")
                    ? "active"
                    : ""
                }`}
              >
                {" "}
                <i className="bi bi-person" />
                Projects{" "}
              </Link>
              {/* <Link
                to={`/qrpCode`}
                className={`nav-item nav-link ${
                  isLinkActive(`/qrCode`) ? "active" : ""
                }`}
              >
                {" "}
                <i className="bi bi-person" />
                Scan QR{" "}
              </Link> */}
            </>
          )}
          {userType === "ClientUser" && clientId && (
            <>
              <Link
                to="/dashboard"
                className={`nav-item nav-link ${
                  isLinkActive("/dashboard") ? "active" : ""
                }`}
              >
                {" "}
                <i className="bi bi-speedometer" />
                Dashboard{" "}
              </Link>
              <Link
                to={`/projectList/${clientParentId}`}
                className={`nav-item nav-link ${
                  isLinkActive(`/projectList/${clientParentId}`) ||
                  isLinkActive("/ProjectReport")
                    ? "active"
                    : ""
                }`}
              >
                {" "}
                <i className="bi bi-person" />
                Projects{" "}
              </Link>
            </>
          )}
          {/* <a href="index.html" className="nav-item nav-link active"><i className="bi bi-speedometer" /> Dashboard */}
          {/* </a> */}
          {/* <div className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-device-analytics" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M3 4m0 1a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1z">
                            </path>
                            <path d="M7 20l10 0" />
                            <path d="M9 16l0 4" />
                            <path d="M15 16l0 4" />
                            <path d="M8 12l3 -3l2 2l3 -3" />
                        </svg>Admin</a>
                        <div className="dropdown-menu bg-transparent border-0">
                            <a href="button.html" className="dropdown-item" >Account management</a>
                            <a href="typography.html" className="dropdown-item">Account Creation </a>
                        </div>
                        <div className="dropdown-menu bg-transparent border-0">
                            <a href="button.html" className="dropdown-item">Account management</a>
                            <a href="typography.html" className="dropdown-item">Account Creation </a>
                        </div>
                    </div> */}
          {/* <a href="#" className="nav-item nav-link"><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chalkboard" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M8 19h-3a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v11a1 1 0 0 1 -1 1" />
                        <path d="M11 16m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
                    </svg>Forms
                    </a>
                    <a href="#" className="nav-item nav-link"><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-border-all" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z">
                        </path>
                        <path d="M4 12l16 0" />
                        <path d="M12 4l0 16" />
                    </svg>Table
                    </a> */}
        </div>
      </nav>
    </div>
  );
}
