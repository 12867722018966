import config from "../config";

const API_BASE_URL = config.API_URL;

export const API_LIST_ALL_USERS = `${API_BASE_URL}/Users/AllUsersList`;

export const API_ADD_OR_EDIT = `${API_BASE_URL}/Users/AddOrEdit`;
export const API_LIST_USERS = `${API_BASE_URL}/Users/List`;
export const API_DELETE_USER = `${API_BASE_URL}/Users/Delete?ID=`;

export const API_LIST_CLIENTS = `${API_BASE_URL}/Users/ClientsList`;
export const API_ADD_OR_EDIT_CLIENT = `${API_BASE_URL}/Users/AddOrEditClient`;
export const API_DELETE_CLIENT = `${API_BASE_URL}/Users/DeleteClient?ID=`;

export const API_MAIL_ADMIN_CREDENTIAL = `${API_BASE_URL}/Email/AdminCredentialEmailSend`;
export const API_MAIL_CLIENT_CREDENTIAL = `${API_BASE_URL}/Email/ClientCredentialEmailSend`;
export const API_MAIL_CLIENT_REGISTER = `${API_BASE_URL}/Email/CLientRegisterClientEmail`;
export const API_MAIL_CLIENT_REGISTER_ADMIN = `${API_BASE_URL}/Email/CLientRegisterAdminEmail`;

// export const API_DECRYPT_USER_ID = `${API_BASE_URL}/Users/DecrypteUserID?id=`;
export const API_DECRYPT_USER_ID = `${API_BASE_URL}/Users/DecrypteUserID`;

export const API_LIST_PROJECTS = `${API_BASE_URL}/Projects/ProjectsList`;
export const API_ADD_OR_EDIT_PROJECT = `${API_BASE_URL}/Projects/AddOrEditProjects`;
export const API_LIST_PROJECT_DETAILS = `${API_BASE_URL}/Projects/ProjectsDetailsList`;
export const API_LIST_ALL_PROJECT_DETAILS = `${API_BASE_URL}/Projects/GetAllProjectsDetailsList`;
export const API_ADD_PROJECT_DETAILS = `${API_BASE_URL}/Projects/InsertProjectDetails`;
export const API_ADD_PROJECT_REPORT_DETAILS = `${API_BASE_URL}/Projects/InsertProjectReportDetails`;
export const API_UPDATE_PROJECT_REPORT = `${API_BASE_URL}/Projects/UpdateProjectReport`;
export const API_SAVE_CHANGE_REQUEST_FOR_REPORT = `${API_BASE_URL}/Projects/SaveChangeRequestForReport`;
export const API_DELETE_PROJECT_DETAILS = `${API_BASE_URL}/Projects/DeleteProjectDetailsById`;
export const API_DELETE_PROJECT = `${API_BASE_URL}/Projects/DeleteProject/?Id=`;
export const API_GET_LOOKUP_COLUMNS_VALUE = `${API_BASE_URL}/Projects/GetLookUpColumnsValue`;
export const API_PUBLISH_PROJECT = `${API_BASE_URL}/Projects/PublishProject`;
export const API_LIST_PROJECT_DETAILS_HISTORY = `${API_BASE_URL}/Projects/ProjectsDetailsHistoryList`;
export const API_PROJECT_DETAILS_BY_QRCODE = `${API_BASE_URL}/Projects/ProjectsDetailsByQRCode`;

export const API_LIST_PROJECT_REPORT = `${API_BASE_URL}/Projects/ProjectsReport`;
export const API_PROJECT_REPORT_DASHBOARD = `${API_BASE_URL}/Projects/ProjectsReportDashBoard`;
export const API_PROJECT_REPORT_TABLE = `${API_BASE_URL}/Projects/ProjectsReportTable`;
export const API_PROJECT_REPORT_FILTER_OPTIONS = `${API_BASE_URL}/Projects/ProjectsReportFilterOptions`;
export const API_LIST_PROJECT_REPORT_DETAILED_LEGACY_REPORT = `${API_BASE_URL}/Projects/ProjectsReportDetailedLegacySurveyTable`;
export const API_LIST_ALL_PROJECT_REPORT = `${API_BASE_URL}/Projects/AllProjectsReportList`;
export const API_PROJECT_COLOUR = `${API_BASE_URL}/Projects/ProjectColour`;

export const API_LIST_CLIENT_PROJECT_REPORT = `${API_BASE_URL}/Projects/ClientProjectsReport`;

export const API_IMAGES_UPLOAD = `${API_BASE_URL}/Projects/uploadPhotos/`;
export const API_MULTIPLE_IMAGES_UPLOAD = `${API_BASE_URL}/Projects/upload`;

export const USER_LOGIN = `${API_BASE_URL}/Login/UserLogin`;
export const CHANGE_PASSWORD = `${API_BASE_URL}/Login/ChangePassword`;
export const FORGOT_PASSWORD = `${API_BASE_URL}/Login/ForgotPasswordEmailSend`;
export const RESET_PASSWORD = `${API_BASE_URL}/Login/SetNewPassword`;

// Masters Approval list Apis
export const API_DESCRIPTION_MASTER_LIST = `${API_BASE_URL}/ApprovalMaster/DescriptionList`;
export const API_ITEM_TYPE_MASTER_LIST = `${API_BASE_URL}/ApprovalMaster/ItemTypeList`;
export const API_ESFA_CODES_MASTER_LIST = `${API_BASE_URL}/ApprovalMaster/ESFACodesList`;
export const API_COLOURS_MASTER_LIST = `${API_BASE_URL}/ApprovalMaster/ColoursList`;
export const API_COSTING_MASTER_LIST = `${API_BASE_URL}/ApprovalMaster/CostingList`;
export const API_CARBON_SAVING_KG_MASTER_LIST = `${API_BASE_URL}/ApprovalMaster/CarbonSavingKGList`;

export const API_LIST_ALL_CHANGE_REQUEST_PPROJECTS = `${API_BASE_URL}/ApprovalMaster/ProjectListForRemarksRequest`;
export const API_LIST_PROJECT_REPORT_FOR_CHANGE_REQUESTED = `${API_BASE_URL}/Projects/ProjectsReportListForChangeRequested`;
export const API_LIST_ALL_PROJECT_REPORT_FOR_CHANGE_REQUESTED = `${API_BASE_URL}/Projects/AllProjectsReportListForChangeRequested`;
export const API_SAVE_APPROVE_REJECT_CHANGE_REQUEST_FOR_REPORT = `${API_BASE_URL}/Projects/SaveApproveRejectChangeRequestForReport`;
export const API_UPDATE_PROJECT_REPORT_FOR_CHANGE_REQUEST = `${API_BASE_URL}/Projects/UpdateProjectReportForChangeRequest`;
export const API_LIST_PROJECT_REPORT_HISTORY = `${API_BASE_URL}/Projects/ProjectsReportHistoryList`;

// approve masters apis
export const API_APPROVE_DESCRIPTION = `${API_BASE_URL}/ApprovalMaster/ApproveDescription`;
export const API_APPROVE_ITEM_TYPE = `${API_BASE_URL}/ApprovalMaster/ApproveItemTypes`;
export const API_APPROVE_ESFA_CODE = `${API_BASE_URL}/ApprovalMaster/ApproveEsfaCodes`;
export const API_APPROVE_COLOURS = `${API_BASE_URL}/ApprovalMaster/ApproveColours`;
export const API_APPROVE_COST = `${API_BASE_URL}/ApprovalMaster/ApproveCost`;
export const API_APPROVE_CARBON_SAVING_KG = `${API_BASE_URL}/ApprovalMaster/ApproveCarbonSavingKG`;
