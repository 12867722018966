import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../Common/Loader/loader";
import { projectDetailsByQRCode } from "../../Redux/Actions/projectsDetailsByQRCodeActions";
import config from "../../config";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { saveRequestForChange } from "../../Redux/Actions/projectsActions";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import * as CryptoJS from "crypto-js";
import { secret_key } from "../../APIProxy/secret";
import Carousel from "react-bootstrap/Carousel";

const ProjectDetailsByQRCode = () => {
  const userRole = Cookies.get("userRole");
  const encryptedUserId = Cookies.get("userId");
  const decryptedBytes =
    !!encryptedUserId && CryptoJS.AES.decrypt(encryptedUserId, secret_key);
  const userId = decryptedBytes.toString(CryptoJS.enc.Utf8);
  const { QRCode } = useParams();
  const [columnData, setColumnData] = useState();
  const [clientId, setClientId] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [isRemarksCommentedBy, setIsRemarksCommentedBy] = useState(null);
  const [projectData, setProjectDetails] = useState([]);
  const [id, setId] = useState(0);
  const [isReqForChange, setIsReqForChange] = useState(false);
  const [modalIsOpen, setModelIsOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const projectDetails = useSelector(
    (state) => state.projectDetailsByCode.projectDetailsByQRCode
  );
  const columnJSON = useSelector(
    (state) => state.projectDetailsByCode.columnJSON
  );
  const dispatch = useDispatch();
  const dataLoading = useSelector(
    (state) => state.projectDetailsByCode.loading
  );

  useEffect(() => {
    if (!!QRCode) {
      //dispatch(projectDetailsByQRCode(`${config.WEB_URL}/viewitem/${QRCode}`));
      dispatch(
        projectDetailsByQRCode(
          `https://www.portal.arpenter.co.uk/viewitem/${QRCode}`
        )
      );//stage
      //dispatch(projectDetailsByQRCode(`${QRCode}`)); //local
    }
  }, [QRCode]);

  useEffect(() => {
    const projectcolumnJSON = !!columnJSON && JSON.parse(columnJSON);
    let convertedData = [];
    if (projectcolumnJSON && typeof projectcolumnJSON === "object") {
      convertedData = Object.keys(projectcolumnJSON).map((key) => ({
        columnName: key,
        alternateColumnName: projectcolumnJSON[key].ALternateColumnName,
        visible: projectcolumnJSON[key].Visible,
      }));
    }
    setColumnData(convertedData);
  }, [columnJSON]);

  useEffect(() => {
    if (!!projectDetails) {
      setProjectDetails(projectDetails);
    }
  }, [projectDetails]);

  useEffect(() => {
    if (!!projectData) {
      setClientId(!!projectData[0] && projectData[0].clientId);
      setProjectId(!!projectData[0] && projectData[0].projectId);

      const isCommented =
        !!projectData[0] &&
        projectData[0].remarksCommentedBy &&
        !!projectData[0] &&
        projectData[0].remarksCommentedBy !== null;
      //&& !!userId && userId === projectData[0].remarksCommentedBy;
      setIsRemarksCommentedBy(isCommented);
    }
  }, [projectData]);

  function closeModal() {
    setLoader(false);
    formik.resetForm();
    setIsReqForChange(false);
    setId(0);
    setModelIsOpen(false);
  }

  function openModal() {
    setModelIsOpen(true);
  }

  const handleReqForChange = () => {
    formik.handleSubmit();
  };

  const validationSchema = Yup.object().shape({
    remarks: Yup.string().required("Remarks is required"),
  });

  const saveRequestForChangeDetails = async (requestDetails) => {
    return new Promise(async (resolve, reject) => {
      const res = await dispatch(
        saveRequestForChange({
          requestParam: {
            ProjectId: requestDetails.projectId,
            ProjectReportRowId: requestDetails.id,
            Remarks: requestDetails.remarks,
            RemarksCommentedBy: requestDetails.userId,
          },
          isUpdated: true,
        })
      );
      resolve(res);
    });
  };

  const formik = useFormik({
    initialValues: {
      remarks: "",
    },
    validationSchema,
    onSubmit: async () => {
      setLoader(true);
      openModal(true);

      if (formik.isValid) {
        if (isReqForChange) {
          const projectId =
            !!projectData && !!projectData[0] && projectData[0].projectId;
          const res = await saveRequestForChangeDetails({
            projectId,
            id,
            ...formik.values,
            userId,
          });
          closeModal();
          if (res.payload.result > 0) {
            Swal.fire({
              title: res?.payload?.message,
              confirmButtonColor: "#000000",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "confirm-button-swal",
              },
            });
            setIsRemarksCommentedBy(true);
          }
        } else {
          setIsReqForChange(false);
          setIsRemarksCommentedBy(null);
        }
      }
    },
  });

  function findAlternateName(name) {
    const lifeExpectancyColumn =
      !!columnData && columnData.find((column) => column.columnName === name);
    return lifeExpectancyColumn
      ? lifeExpectancyColumn.alternateColumnName
      : null;
  }
  function checkVisibility(name) {
    const lifeExpectancyColumn =
      !!columnData && columnData.find((column) => column.columnName === name);
    return lifeExpectancyColumn ? lifeExpectancyColumn.visible : true;
  }

  const formatFieldName = (fieldName) => {
    let alternateFieldName = fieldName;
    let visible = false;
    if (fieldName === "name") {
      return "Project Name";
    } else if (fieldName === "ffeSurveyors") {
      return "FF&E Surveyors";
    } else if (fieldName === "roomNumber") {
      alternateFieldName = findAlternateName("RoomNumber")
        ? findAlternateName("RoomNumber")
        : "Room Number";
      visible = checkVisibility("RoomNumber");
      return visible ? alternateFieldName : null;
    } else if (fieldName === "roomName") {
      alternateFieldName = findAlternateName("RoomName")
        ? findAlternateName("RoomName")
        : "Room Name";
      visible = checkVisibility("RoomName");
      return visible ? alternateFieldName : null;
    } else if (fieldName === "description") {
      alternateFieldName = findAlternateName("Description")
        ? findAlternateName("Description")
        : "Description";
      visible = checkVisibility("Description");
      return visible ? alternateFieldName : null;
    } else if (fieldName === "quantity") {
      alternateFieldName = findAlternateName("Quantity")
        ? findAlternateName("Quantity")
        : "Quantity";
      visible = checkVisibility("Quantity");
      return visible ? alternateFieldName : null;
    } else if (fieldName === "condition") {
      alternateFieldName = findAlternateName("Condition")
        ? findAlternateName("Condition")
        : "Condition";
      visible = checkVisibility("Condition");
      return visible ? alternateFieldName : null;
    } else if (fieldName === "lifeExpectancy") {
      alternateFieldName = findAlternateName("LifeExpectancy")
        ? findAlternateName("LifeExpectancy")
        : "Life Expectancy";
      visible = checkVisibility("LifeExpectancy");
      return visible ? alternateFieldName : null;
    } else if (fieldName === "makeModel") {
      alternateFieldName = findAlternateName("MakeModel")
        ? findAlternateName("MakeModel")
        : "Make Model";
      visible = checkVisibility("MakeModel");
      return visible ? alternateFieldName : null;
    } else if (fieldName === "length") {
      alternateFieldName = findAlternateName("Length")
        ? findAlternateName("Length")
        : "Length";
      visible = checkVisibility("Length");
      return visible ? alternateFieldName : null;
    } else if (fieldName === "depth") {
      alternateFieldName = findAlternateName("Depth")
        ? findAlternateName("Depth")
        : "Depth";
      visible = checkVisibility("Depth");
      return visible ? alternateFieldName : null;
    } else if (fieldName === "height") {
      alternateFieldName = findAlternateName("Height")
        ? findAlternateName("Height")
        : "Height";
      visible = checkVisibility("Height");
      return visible ? alternateFieldName : null;
    } else if (fieldName === "primaryColour") {
      alternateFieldName = findAlternateName("PrimaryColour")
        ? findAlternateName("PrimaryColour")
        : "Primary Colour";
      visible = checkVisibility("PrimaryColour");
      return visible ? alternateFieldName : null;
    } else if (fieldName === "secondaryColour") {
      alternateFieldName = findAlternateName("SecondaryColour")
        ? findAlternateName("SecondaryColour")
        : "Secondary Colour";
      visible = checkVisibility("SecondaryColour");
      return visible ? alternateFieldName : null;
    } else if (fieldName === "abnormal") {
      alternateFieldName = findAlternateName("DFEStandard")
        ? findAlternateName("DFEStandard")
        : "DFE Standard";
      visible = checkVisibility("DFEStandard");
      return visible ? alternateFieldName : null;
    } else if (fieldName === "comments") {
      alternateFieldName = findAlternateName("Comments")
        ? findAlternateName("Comments")
        : "Comments";
      visible = checkVisibility("Comments");
      return visible ? alternateFieldName : null;
    } else if (fieldName === "cost") {
      alternateFieldName = findAlternateName("Cost")
        ? findAlternateName("Cost")
        : "Cost";
      visible = checkVisibility("Cost");
      return visible ? alternateFieldName : null;
    } else if (fieldName === "carbonSavingKG") {
      alternateFieldName = findAlternateName("CarbonSavingKG")
        ? findAlternateName("CarbonSavingKG")
        : "Carbon Saving KG";
      visible = checkVisibility("CarbonSavingKG");
      return visible ? alternateFieldName : null;
    } else if (fieldName === "level") {
      alternateFieldName = findAlternateName("Level")
        ? findAlternateName("Level")
        : "Level";
      visible = checkVisibility("Level");
      return visible ? alternateFieldName : null;
    } else if (fieldName === "newRoomName") {
      alternateFieldName = findAlternateName("NewRoomName")
        ? findAlternateName("NewRoomName")
        : "New Room Name";
      visible = checkVisibility("NewRoomName");
      return visible ? alternateFieldName : null;
    } else if (fieldName === "newRoomNumber") {
      alternateFieldName = findAlternateName("NewRoomNumber")
        ? findAlternateName("NewRoomNumber")
        : "New Room Number";
      visible = checkVisibility("NewRoomNumber");
      return visible ? alternateFieldName : null;
    } else if (fieldName === "dfeStandard") {
      alternateFieldName = findAlternateName("DFEStandard")
        ? findAlternateName("DFEStandard")
        : "DFE Standard";
      visible = checkVisibility("DFEStandard");
      return visible ? alternateFieldName : null;
    } else if (fieldName === "dfeCode") {
      alternateFieldName = findAlternateName("DFECode")
        ? findAlternateName("DFECode")
        : "DFE Code";
      visible = checkVisibility("DFECode");
      return visible ? alternateFieldName : null;
    } else if (fieldName === "itemType") {
      alternateFieldName = findAlternateName("ItemType")
        ? findAlternateName("ItemType")
        : "Item Type";
      visible = checkVisibility("ItemType");
      return visible ? alternateFieldName : null;
    } else
      return alternateFieldName
        .split(/(?=[A-Z])/)
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
  };

  const fieldsToShow = [
    "name",
    "ffeSurveyors",
    "roomNumber",
    "roomName",
    "description",
    "quantity",
    "itemType",
    "dfeCode",
    "condition",
    "lifeExpectancy",
    "makeModel",
    "length",
    "depth",
    "height",
    "primaryColour",
    "secondaryColour",
    "abnormal",
    "comments",
    "cost",
    "level",
    "carbonSavingKG",
    "newRoomName",
    "newRoomNumber",
    "dfeStandard",
  ];

  // const renderPhotograph = () => {
  //   const photographUrl = projectDetails[0]?.photographName;
  //   const projectId = projectDetails[0]?.projectId;
  //   if (!!photographUrl) {
  //     const photographArray = photographUrl.split(",");

  //     return (
  //       <Carousel interval={null}>
  //         {photographArray.map((photo, index) => (
  //           <Carousel.Item key={index}>
  //             <img
  //               className="d-block lightBoxImg"
  //               src={`${config.IMAGE_URL}/${projectId}/${photo}`}
  //               alt={`${photo}`}
  //             />
  //             {/* <Carousel.Caption>
  //               <h3>Slide {index + 1}</h3>
  //               <p>This is slide number {index + 1}</p>
  //             </Carousel.Caption> */}
  //           </Carousel.Item>
  //         ))}
  //       </Carousel>
  //     );

  //     // return (
  //     //   // <div className="row">
  //     //   //   <div className="col-md-12 mb-3 d-flex justify-content-center align-items-center">
  //     //   //     <div className="edit-icon-logo">
  //     //   //       <img
  //     //   //         src={`${config.IMAGE_URL}/${projectId}/${photographUrl}.jpg`}
  //     //   //         alt="Photograph"
  //     //   //         className="projectDetailsQRImage photograph"
  //     //   //         // style={{ maxWidth: "500px", maxHeight: "600px" }} // Adjust size as needed
  //     //   //       />
  //     //   //     </div>
  //     //   //   </div>
  //     //   // </div>

  //     //   // <>
  //     //   //   <div class="d-flex align-items-center flex-wrap">
  //     //   //     <a href="https://localhost:44344/projectImages/722518f0-ad54-4ea6-804e-33d753e814f4/5579e0e7-cec5-4c35-aec2-a39997acb969.jpg">
  //     //   //       <img className="lightBoxImg" src="https://localhost:44344/projectImages/722518f0-ad54-4ea6-804e-33d753e814f4/5579e0e7-cec5-4c35-aec2-a39997acb969.jpg"></img>
  //     //   //     </a>
  //     //   //     <a href="https://localhost:44344/projectImages/722518f0-ad54-4ea6-804e-33d753e814f4/5579e0e7-cec5-4c35-aec2-a39997acb969.jpg">
  //     //   //     <img className="lightBoxImg" src="https://localhost:44344/projectImages/722518f0-ad54-4ea6-804e-33d753e814f4/5579e0e7-cec5-4c35-aec2-a39997acb969.jpg"></img>
  //     //   //     </a>
  //     //   //     <a href="https://localhost:44344/projectImages/722518f0-ad54-4ea6-804e-33d753e814f4/5579e0e7-cec5-4c35-aec2-a39997acb969.jpg">
  //     //   //     <img className="lightBoxImg" src="https://localhost:44344/projectImages/722518f0-ad54-4ea6-804e-33d753e814f4/5579e0e7-cec5-4c35-aec2-a39997acb969.jpg"></img>
  //     //   //     </a>
  //     //   //     <a href="https://localhost:44344/projectImages/722518f0-ad54-4ea6-804e-33d753e814f4/5579e0e7-cec5-4c35-aec2-a39997acb969.jpg">
  //     //   //     <img className="lightBoxImg" src="https://localhost:44344/projectImages/722518f0-ad54-4ea6-804e-33d753e814f4/5579e0e7-cec5-4c35-aec2-a39997acb969.jpg"></img>
  //     //   //     </a>
  //     //   //   </div>
  //     //   // </>
  //     // );
  //   } else {
  //     return null;
  //   }
  // };
  const renderPhotograph = () => {
    const photographUrl = projectDetails[0]?.photographName;
    const projectId = projectDetails[0]?.projectId;
    if (!!photographUrl) {
      const photographArray = photographUrl.split(",");

      // Group photographs in sets of three
      const groupedPhotographs = [];
      for (let i = 0; i < photographArray.length; i += 3) {
        groupedPhotographs.push(photographArray.slice(i, i + 3));
      }
      return (
        <>
          {groupedPhotographs.length > 1 ? (
            <Carousel interval={2000}>
              {groupedPhotographs.map((group, index) => (
                <Carousel.Item key={index}>
                  <div className="row">
                    {group.map((photo, idx) => (
                      <div className="col-md-4 image-slide" key={idx}>
                        <img
                          className="d-block w-100 lightBoxImg"
                          src={`${config.IMAGE_URL}/${projectId}/${photo}`}
                          alt={`${photo}`}
                        />
                      </div>
                    ))}
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          ) : (
            <div className="row">
              {groupedPhotographs.flatMap((group, index) => (
                <div className="col-md-12 noCaroselMainDIv" key={index}>
                  {group.map((photo, idx) => (
                    <img
                    className="noCaroselImage"
                      key={idx}
                      src={`${config.IMAGE_URL}/${projectId}/${photo}`}
                      alt={`${photo}`}
                    />
                  ))}
                </div>
              ))}
            </div>
          )}
        </>
      );
    } else {
      return null;
    }
  };

  const renderData = () => {
    if (dataLoading) {
      return <Loader />;
    } else if (projectDetails.length === 0) {
      return <div>No details found.</div>;
    } else {
      const details = projectDetails[0];
      return (
        <div className="row">
          {Object.keys(details)
            .filter((key) => fieldsToShow.includes(key))
            .map(
              (key) =>
                !!formatFieldName(key) && (
                  <div key={key} className="field-container">
                    <div className="field">
                      <span className="field-name h6">
                        {formatFieldName(key)}:
                      </span>
                      <span className="field-value">{details[key]}</span>
                    </div>
                  </div>
                )
            )}
        </div>
      );
    }
  };

  const handleBackClick = () => {
    localStorage.setItem("isReturningFromQRLayOut", true);
    const targetNavigateLayout = localStorage.getItem("targetLayoutToQRView");
    if (!!targetNavigateLayout && targetNavigateLayout != "") {
      localStorage.removeItem("targetLayoutToQRView");
      navigate(`/${targetNavigateLayout}/${projectId}/${clientId}`);
    } else {
      navigate(`/ProjectReport/${projectId}/${clientId}`);
    }
  };
  return (
    <>
      <div className="mainContent px-4 pt-3">
        <div className="page-header mb-3">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <div className="page-sub-header mb-3">
                <div>
                  <h3 className="page-title">Details</h3>
                </div>
                <div
                  className="col-md-10 mb-3"
                  style={{ textAlign: "right", marginTop: "15px" }}
                >
                  <div
                    className="page-title-right"
                    style={{
                      cursor: isRemarksCommentedBy ? "no-drop" : "pointer",
                    }}
                  >
                    {/* <div className="symbol-project bg-light"></div> */}
                    {(userRole === "Client" || userRole === "ClientUser") &&
                      !!clientId &&
                      !!projectId && (
                        <>
                          <Link
                            className={`btn btn-outline-dark ms-2 ${
                              isRemarksCommentedBy ? "disabled" : ""
                            }`}
                            style={{
                              color: "#fff",
                              backgroundColor: "#212529",
                            }}
                            onClick={() => {
                              !!projectData &&
                                !!projectData[0] &&
                                setId(projectData[0].id);
                              setIsReqForChange(true);
                              openModal(true);
                            }}
                          >
                            <i className="bi bi-pencil-square me-2"></i> Request
                            For Change
                          </Link>
                        </>
                      )}
                  </div>
                </div>
                <div className="page-title-right d-flex">
                  <div className="symbol-project bg-light"></div>
                  {!!clientId && !!projectId && (
                    // <Link
                    //   className="btn btn-outline-dark ms-2 d-flex"
                    //   //to = {`/`}
                    //   to={`/ProjectReport/${projectId}/${clientId}`}
                    // >
                    //   <i className="bi bi-arrow-left me-2"></i> Back
                    // </Link>
                    <button
                      className="btn btn-outline-dark ms-2 d-flex"
                      onClick={handleBackClick}
                    >
                      <i className="bi bi-arrow-left me-2"></i> Back
                    </button>
                  )}
                </div>
              </div>
              <div className="card">
                <div className="card-body QRCodeWrapper">
                  <div className="">
                    <div
                      className="mb-3"
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      {/* <div className="text-center changeLogo-wrapper">
                        <div className="edit-icon-logo"> */}
                      {renderPhotograph()}
                      {/* </div>
                      </div> */}
                    </div>
                  </div>
                  {renderData()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modalIsOpen}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => closeModal()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mb-3">
              <label htmlFor="remarks" className="form-label">
                Remarks<span className="text-danger"> * </span>
              </label>
              <textarea
                name="remarks"
                className={`form-control ${
                  formik.touched.remarks && formik.errors.remarks
                    ? "is-invalid"
                    : ""
                }`}
                id="remarks"
                placeholder="Enter Remarks for the Change Request"
                value={!!formik.values.remarks ? formik.values.remarks : ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                rows={5}
              />
              {formik.touched.remarks && formik.errors.remarks && (
                <div className="invalid-feedback">{formik.errors.remarks}</div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={closeModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleReqForChange}
            disabled={loader}
          >
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProjectDetailsByQRCode;
