/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  allProjectsReportList,
  projectsReport,
  projectsReportDashboard,
  projectsReportDetailedLegacySurveyTable,
  projectsReportFilterOptions,
  projectsReportTable,
} from "../../Redux/Actions/projectsReportActions";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import config from "../../config";
import Swal from "sweetalert2";
import Loader from "../Common/Loader/loader";
import {
  addProjectDetails,
  getLookupColumnValue,
  projectReportHistoryList,
  saveRequestForChange,
  updateProjectReport,
  updateProjectReportForChangeRequest,
} from "../../Redux/Actions/projectsActions";
import { DataTable as DT } from "primereact/datatable";
import DataTable from "react-data-table-component";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { Paginator } from "primereact/paginator";
import { Dropdown } from "primereact/dropdown";
import Cookies from "js-cookie";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import * as CryptoJS from "crypto-js";
import { secret_key } from "../../APIProxy/secret";
import history from "../history";
import { Button as Btn } from "primereact/button";
//-------------

import { clientProjectsReport } from "../../Redux/Actions/clientProjectsReportActions";
// import { useDispatch, useSelector } from "react-redux";
import "react-awesome-lightbox/build/style.css";
// import Loader from "../Common/Loader/loader";
import { listProjects } from "../../Redux/Actions/projectsActions";
// import * as CryptoJS from "crypto-js";
// import Cookies from "js-cookie";
import Multiselect from "multiselect-react-dropdown";
// import { secret_key } from "../../APIProxy/secret";
import { Bar, Doughnut } from "react-chartjs-2";
import { Chart, registerables } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
// import { Button } from "bootstrap";
// import moment from "moment";
// import DataTable from "react-data-table-component";
import { useFormik } from "formik";
import * as Yup from "yup";
Chart.register(...registerables);
//-------------

const customStylesDataTable = {
  headCells: {
    style: {
      fontSize: "16px",
      fontWeight: "Bold",
      backgroundColor: "black",
      color: "white",
    },
  },
};
const customStylesDataTableDash = {
  headCells: {
    style: {
      fontSize: "16px",
      fontWeight: "Bold",
      backgroundColor: "black",
      color: "white",
    },
  },
};
const conditionalRowStyles = [
  {
    when: (row) => row.description === "Total Result",
    style: {
      fontWeight: "bold",
    },
  },
];
const ProjectReports = () => {
  const userRole = Cookies.get("userRole");
  const { projectId, clientId } = useParams();
  const [projectIdDash, setProjectIdDash] = useState(null);
  const [filters, setFilters] = useState({
    level: { value: null, matchMode: FilterMatchMode.IN },
    roomNumber: { value: null, matchMode: FilterMatchMode.IN },
    roomName: { value: null, matchMode: FilterMatchMode.IN },
    description: { value: null, matchMode: FilterMatchMode.IN },
    quantity: { value: null, matchMode: FilterMatchMode.IN },
    itemType: { value: null, matchMode: FilterMatchMode.IN },
    esfaCode: { value: null, matchMode: FilterMatchMode.IN },
    condition: { value: null, matchMode: FilterMatchMode.IN },
    lifeExpectancy: { value: null, matchMode: FilterMatchMode.IN },
    colour: { value: null, matchMode: FilterMatchMode.IN },
    makeModel: { value: null, matchMode: FilterMatchMode.IN },
    length: { value: null, matchMode: FilterMatchMode.IN },
    depth: { value: null, matchMode: FilterMatchMode.IN },
    height: { value: null, matchMode: FilterMatchMode.IN },
    primaryColour: { value: null, matchMode: FilterMatchMode.IN },
    secondaryColour: { value: null, matchMode: FilterMatchMode.IN },
    abnormal: { value: null, matchMode: FilterMatchMode.IN },
    comments: { value: null, matchMode: FilterMatchMode.IN },
    // photograph: { value: null, matchMode: FilterMatchMode.IN },
    cost: { value: null, matchMode: FilterMatchMode.IN },
    carbonSavingKG: { value: null, matchMode: FilterMatchMode.IN },
    qrCode: { value: null, matchMode: FilterMatchMode.IN },
    legacyCode: { value: null, matchMode: FilterMatchMode.IN },
    newRoomName: { value: null, matchMode: FilterMatchMode.IN },
    newRoomNumber: { value: null, matchMode: FilterMatchMode.IN },
  });

  const cookieUserId = Cookies.get("userId");
  const loginUserId = !!cookieUserId
    ? CryptoJS.AES.decrypt(cookieUserId, secret_key).toString(CryptoJS.enc.Utf8)
    : null;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const IMAGE_BASE_URL = config.IMAGE_URL;
  const IMAGE_LOGO_BASE_URL = config.LOGO_URL;
  //----------
  const encryptedUserId = Cookies.get("userId");
  const decryptedBytes =
    !!encryptedUserId && CryptoJS.AES.decrypt(encryptedUserId, secret_key);
  const userId = decryptedBytes.toString(CryptoJS.enc.Utf8);
  //----------

  // const isReturningFromQRLayOut = localStorage.getItem(
  //   "isReturningFromQRLayOut"
  // );

  const [isOpenModalImage, setIsOpenModalImage] = useState(false);
  const [modalImageName, setModalImageName] = useState(null);
  const [modalImage, setModalImage] = useState(null);
  const [page, setPage] = useState(1);
  const [first, setFirst] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortColumnDir, setSortColumnDir] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [isLabelShow, setIsLabelShow] = useState(false);
  const [projectReportArr, setProjectReportArr] = useState([]);
  const [descriptionFilter, setDescriptionFilter] = useState(null);
  const [levelFilter, setLevelFilter] = useState(null);
  const [roomNumberFilter, setRoomNumberFilter] = useState(null);
  const [roomNameFilter, setRoomNameFilter] = useState(null);
  const [quantityFilter, setQuantityFilter] = useState(null);
  const [itemTypeFilter, setItemTypeFilter] = useState(null);
  const [esfaCodeFilter, setEsfaCodeFilter] = useState(null);
  const [conditionFilter, setConditionFilter] = useState(null);
  const [lifeExpectancyFilter, setLifeExpectancyFilter] = useState(null);
  const [colourFilter, setColourFilter] = useState(null);
  const [makeModelFilter, setMakeModelFilter] = useState(null);
  const [lengthFilter, setLengthFilter] = useState(null);
  const [depthFilter, setDepthFilter] = useState(null);
  const [heightFilter, setHeightFilter] = useState(null);
  const [primaryColourFilter, setPrimaryColourFilter] = useState(null);
  const [secondaryColourFilter, setSecondaryColourFilter] = useState(null);
  const [abnormalFilter, setAbnormalFilter] = useState(null);
  const [commentsFilter, setCommentsFilter] = useState(null);
  // const [photographFilter, setPhotographFilter] = useState(null);
  const [costFilter, setCostFilter] = useState(null);
  const [carbonSavingKGFilter, setCarbonSavingKGFilter] = useState(null);
  const [newRoomNameFilter, setNewRoomNameFilter] = useState(null);
  const [newRoomNumberFilter, setNewRoomNumberFilter] = useState(null);
  const [qrCodeFilter, setQRCodeFilter] = useState(null);
  const [legacyCodeFilter, setLegacyCodeFilter] = useState(null);
  const [columnData, setColumnData] = useState();
  const [triggerExit, setTriggerExit] = useState({
    onOk: false,
    path: "",
  });
  const [id, setId] = useState(0);
  const [isReqForChange, setIsReqForChange] = useState(false);
  const [modalIsOpen, setModelIsOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isVisibleDialog, setVisibleDialog] = useState(false);
  const [enableRowEdit, setEnableRowEdit] = useState(false);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState(null);
  const [showBulkEditableGrid, setShowBulkEditableGrid] = useState(false);
  const [isChangesSaved, setIsChangesSaved] = useState(true);
  const [selectedLink, setSelectedLink] = useState("dashboard"); //projectId,
  const [enableRequestRowEdit, setEnableRequestRowEdit] = useState(false);
  const [projectReportId, setProjectReportId] = useState(null);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [searchHistoryText, setSearchHistoryText] = useState(null);
  const [historyPage, setHistoryPage] = useState(1);
  const [historyPerPage, setHistoryPerPage] = useState(10);
  const [historySortColumn, setHistorySortColumn] = useState(null);
  const [historySortColumnDir, setHistorySortColumnDir] = useState(null);
  const [projectReportHistoryData, setProjectReportHistoryData] = useState([]);
  const [imageError, setImageError] = useState(false);
  //--------------

  const projectReportDash = useSelector(
    (state) => state.clientProjectsReport.projectReport
  ); //projectReport
  const distinctRoomNames = useSelector(
    (state) => state.clientProjectsReport.distinctRoomNames
  );
  const distinctRoomNumbers = useSelector(
    (state) => state.clientProjectsReport.distinctRoomNumbers
  );
  const distinctDescription = useSelector(
    (state) => state.clientProjectsReport.distinctDescription
  );
  const distinctItemType = useSelector(
    (state) => state.clientProjectsReport.distinctItemType
  );
  const distinctCondition = useSelector(
    (state) => state.clientProjectsReport.distinctCondition
  );
  const distinctDFEStandard = useSelector(
    (state) => state.clientProjectsReport.distinctDFEStandard
  );
  const distinctLifeExpectancy = useSelector(
    (state) => state.clientProjectsReport.distinctLifeExpectancy
  );
  const conditionDescriptionWithCounts = useSelector(
    (state) => state.clientProjectsReport.conditionDescriptionWithCounts
  );
  const suitablePer = useSelector(
    (state) => state.clientProjectsReport.suitablePer
  );
  const conditionBreakdownListDash = useSelector(
    (state) => state.clientProjectsReport.conditionBreakdownList
  );
  const reportRecordsCountsDash = useSelector(
    (state) => state.clientProjectsReport.reportRecordsCounts
  ); //reportRecordsCounts
  const loadingDash = useSelector(
    (state) => state.clientProjectsReport.loading
  );
  const loadingProjectReportDash = useSelector(
    (state) => state.projects.loading
  );
  const projects = useSelector((state) => state.projects);
  const projectReportsHistory = useSelector(
    (state) => state.projectsReport.projectReportsHistory
  );
  const noOfRecordsHistory = useSelector(
    (state) => state.projectsReport.noOfRecords
  );
  const projectReport = useSelector(
    (state) => state.projectsReport.projectReport
  );
  const projectReportDDList = useSelector(
    (state) => state.projectsReport.projectReportDDList
  );
  const noOfRecords = useSelector((state) => state.projectsReport.noOfRecords);
  const projectName = useSelector((state) => state.projectsReport.projectName);
  const author = useSelector((state) => state.projectsReport.author);
  const ffeSurveyors = useSelector(
    (state) => state.projectsReport.ffeSurveyors
  );
  const columnJSON = useSelector((state) => state.projectsReport.columnJSON);
  const conditionBreakdownList = useSelector(
    (state) => state.projectsReport.conditionBreakdownList
  );
  const filteredconditionBreakdownList = conditionBreakdownList.filter(
    (item) => item.condition !== "A - Suitable"
  );
  const reportRecordsCounts = useSelector(
    (state) => state.projectsReport.reportRecordsCounts
  );
  const loading = useSelector((state) => state.projectsReport.loading);
  const loadingProjectReport = useSelector((state) => state.projects.loading);

  const suitablePerDashboard = useSelector(
    (state) => state.projectsReport.suitablePer
  );
  const conditionBreakdownListDashboard = useSelector(
    (state) => state.projectsReport.conditionBreakdownList
  );
  const conditionBreakdownListTotalDashboard = useSelector(
    (state) => state.projectsReport.conditionBreakdownListTotal
  );
  const reportRecordsCountsDashboard = useSelector(
    (state) => state.projectsReport.reportRecordsCounts
  );
  const conditionDescriptionWithCountsDashboard = useSelector(
    (state) => state.projectsReport.conditionDescriptionWithCounts
  );
  const distinctRoomNamesDashboard = useSelector(
    (state) => state.projectsReport.distinctRoomNames
  );
  const distinctRoomNumbersDashboard = useSelector(
    (state) => state.projectsReport.distinctRoomNumbers
  );
  const distinctDescriptionDashboard = useSelector(
    (state) => state.projectsReport.distinctDescription
  );
  const distinctItemTypeDashboard = useSelector(
    (state) => state.projectsReport.distinctItemType
  );
  const distinctConditionDashboard = useSelector(
    (state) => state.projectsReport.distinctCondition
  );
  const distinctDFEStandardDashboard = useSelector(
    (state) => state.projectsReport.distinctDFEStandard
  );
  const distinctLifeExpectancyDashboard = useSelector(
    (state) => state.projectsReport.distinctLifeExpectancy
  );

  const filteredconditionBreakdownListNEW = conditionBreakdownListTotalDashboard.filter(
    (item) => item.condition !== "A - Suitable"
  );

  const [selectedProjectName, setSelectedProjectName] = useState([]);
  const [selectedRoomName, setSelectedRoomName] = useState([]);
  const [selectedRoomNumber, setSelectedRoomNumber] = useState([]);
  const [selectedDescription, setSelectedDescription] = useState([]);
  const [selectedItemType, setSelectedItemType] = useState([]);
  const [selectedCondition, setSelectedCondition] = useState([]);
  const [selectedDFEStandard, setSelectedDFEStandard] = useState([]);
  const [selectedLifeExpectancy, setSelectedLifeExpectancy] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const totals = {
    description: "Total Result",
    aSuitable: 0,
    bMinorDamage: 0,
    cMediumDamage: 0,
    dHeavyDamage: 0,
    eUnsuitable: 0,
    fSpecialRequired: 0,
    // gElectrical: 0,
    totalResult: 0,
  };

  !!conditionDescriptionWithCountsDashboard &&
    conditionDescriptionWithCountsDashboard.forEach((item) => {
      totals.aSuitable += item.aSuitable;
      totals.bMinorDamage += item.bMinorDamage;
      totals.cMediumDamage += item.cMediumDamage;
      totals.dHeavyDamage += item.dHeavyDamage;
      totals.eUnsuitable += item.eUnsuitable;
      totals.fSpecialRequired += item.fSpecialRequired;
      // totals.gElectrical += item.gElectrical;
      totals.totalResult += item.totalResult;
    });

  const tableData = Array.isArray(conditionDescriptionWithCountsDashboard)
    ? [...conditionDescriptionWithCountsDashboard, totals].map(
        (item, index) => ({
          description: item.description,
          aSuitable: item.aSuitable,
          bMinorDamage: item.bMinorDamage,
          cMediumDamage: item.cMediumDamage,
          dHeavyDamage: item.dHeavyDamage,
          eUnsuitable: item.eUnsuitable,
          fSpecialRequired: item.fSpecialRequired,
          // gElectrical: item.gElectrical,
          totalResult: item.totalResult,
        })
      )
    : [];


  const trimprojectReportArr =
    !!projectReportArr &&
    projectReportArr.map((item) => {
      const trimmedItem = {};
      for (const key in item) {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          trimmedItem[key] =
            typeof item[key] === "string" ? item[key].trim() : item[key];
        }
      }
      return trimmedItem;
    });

  const roomNameRef = useRef();
  const roomNumberRef = useRef();
  const descriptionRef = useRef();
  const itemTypeRef = useRef();
  const conditionRef = useRef();
  const dfeStandardRef = useRef();
  const lifeExpectancyRef = useRef();

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const tableColumns = [
    {
      name: "Description",
      selector: (row) => row.description,
    },
    {
      name: "A - Excellent", //Suitable",
      selector: (row) => row.aSuitable,
    },
    {
      name: "B - Minor Damage",
      selector: (row) => row.bMinorDamage,
    },
    {
      name: "C - Medium Damage ",
      selector: (row) => row.cMediumDamage,
    },
    {
      name: "D - Heavy Damage",
      selector: (row) => row.dHeavyDamage,
    },
    {
      name: "E - Unsuitable ",
      selector: (row) => row.eUnsuitable,
    },
    {
      name: "F - Special Required ",
      selector: (row) => row.fSpecialRequired,
    },
    // {
    //   name: "G - Electrical",
    //   selector: (row) => row.gElectrical
    // },
    {
      name: "Total Result ",
      selector: (row) => row.totalResult,
      style: {
        fontWeight: "bold",
      },
    },
  ];


  const handleResetFilter = () => {
    roomNameRef.current.resetSelectedValues();
    roomNumberRef.current.resetSelectedValues();
    descriptionRef.current.resetSelectedValues();
    itemTypeRef.current.resetSelectedValues();
    conditionRef.current.resetSelectedValues();
    dfeStandardRef.current.resetSelectedValues();
    lifeExpectancyRef.current.resetSelectedValues();
    setSelectedRoomName([]);
    setSelectedRoomNumber([]);
    setSelectedDescription([]);
    setSelectedItemType([]);
    setSelectedCondition([]);
    setSelectedDFEStandard([]);
    setSelectedLifeExpectancy([]);
    getProjectReportDataListDash(projectIdDash, 1, 25, "", "asc", "");
  };

  // const handleProjectNameSelect = (selectedList, selectedItem) => {
  //   if (selectedList.length > 1) {
  //     setSelectedProjectName(selectedList[0].name);
  //     setProjectIdDash(selectedList[0].id);
  //     selectedList.shift();
  //   }
  //   if (projects && projects.length > 0) {
  //     setSelectedProjectName(projects.projects.listData[0]?.name);
  //     setProjectIdDash(projects.projects.listData[0]?.id);
  //   }

  //   // setSelectedProjectName(selectedList);
  //   setSelectedProjectName(selectedList[0].name);
  //   setProjectIdDash(selectedList[0].id);
  //   getProjectReportDataListDash(!!selectedList && selectedList[0].id,1,10,"", "asc","")

  // }
  const handleProjectNameSelect = (selectedProjectId) => {
    const selectedProject = projects.projects.listData.find(
      (project) => project.id === selectedProjectId
    );
    if (selectedProject)
      // setSelectedProjectName(selectedProject.name);
      setProjectIdDash(selectedProjectId);
    getProjectReportDataListDash(selectedProjectId, 1, 25, "", "asc", "");
  };
  const handleRoomNameSelect = (selectedList, selectedItem) => {
    setSelectedRoomName([...selectedList]);
  };

  const handleRoomNumberSelect = (selectedList, selectedItem) => {
    setSelectedRoomNumber([...selectedList]);
  };

  const handleDescriptionSelect = (selectedList, selectedItem) => {
    setSelectedDescription([...selectedList]);
  };

  const handleItemTypeSelect = (selectedList, selectedItem) => {
    setSelectedItemType([...selectedList]);
  };

  const handleConditionSelect = (selectedList, selectedItem) => {
    setSelectedCondition([...selectedList]);
  };

  const handleDFEStandardSelect = (selectedList, selectedItem) => {
    setSelectedDFEStandard([...selectedList]);
  };

  const handleLifeExpectancySelect = (selectedList, selectedItem) => {
    setSelectedLifeExpectancy([...selectedList]);
  };

  const getProjectReportDashBoard = async (
    // project_id,
    // page_number,
    // page_size,
    // sort_column_name,
    // sort_column_dir,
    // search_text,
    roomNumbers,
    roomNames,
    descriptions,
    itemTypes,
    conditions,
    dfeStandards,
    lifeExpectancies
  ) => {
    const data = {
      projectId: projectId,
      roomNumbers: roomNumbers || null,
      roomNames: roomNames || null,
      descriptions: descriptions || null,
      itemTypes: itemTypes || null,
      conditions: conditions || null,
      dfeStandards: dfeStandards || null,
      lifeExpectancies: lifeExpectancies || null,
    };
    const dashboardRes = await dispatch(projectsReportDashboard(data));
    if (dashboardRes?.payload) {
      const tableRes = await dispatch(projectsReportTable(data));
      if (tableRes?.payload) {
        const filterParam = {
          projectId: projectId,
        };
        dispatch(projectsReportFilterOptions(filterParam));
      }
    }
  };

  useEffect(() => {
    if (!!projectIdDash) {
      getProjectReportDashBoard(
        //projectIdDash,
        // page,
        // perPage,
        // sortColumn,
        // sortColumnDir,
        // searchText,
        selectedRoomNumber.map((item) => item.name).join(","),
        selectedRoomName.map((item) => item.name).join(","),
        selectedDescription.map((item) => item.name).join(","),
        selectedItemType.map((item) => item.name).join(","),
        selectedCondition.map((item) => item.name).join(","),
        selectedDFEStandard.map((item) => item.name).join(","),
        selectedLifeExpectancy.map((item) => item.name).join(",")
      );
    }
  }, [
    projectIdDash,
    searchText,
    selectedRoomNumber,
    selectedRoomName,
    selectedDescription,
    selectedItemType,
    selectedCondition,
    selectedDFEStandard,
    selectedLifeExpectancy,
  ]);

  const getProjectReportDataListDash = (
    project_id,
    page_number,
    page_size,
    sort_column_name,
    sort_column_dir,
    search_text,
    roomNumbers,
    roomNames,
    descriptions,
    itemTypes,
    conditions,
    dfeStandards,
    lifeExpectancies
  ) => {
    const data = {
      page_number: page_number,
      page_size: page_size,
      projectId: projectId, //project_id, //projectIdDash
      sort_column_name: !!sort_column_name ? sort_column_name : null,
      sort_column_dir: !!sort_column_dir ? sort_column_dir : null,
      search_text: !!search_text ? search_text : null,
      roomNumbers: roomNumbers || null,
      roomNames: roomNames || null,
      descriptions: descriptions || null,
      itemTypes: itemTypes || null,
      conditions: conditions || null,
      dfeStandards: dfeStandards || null,
      lifeExpectancies: lifeExpectancies || null,
    };
    //dispatch(clientProjectsReport(data));
  };
  // useEffect(() => {
  //   if (projects.projects.listData && projects.projects.listData.length > 0 && noOfRecords > 0) {
  //     const firstProjectId = projects.projects.listData[0].id;
  //     getProjectReportDataListDash(firstProjectId, page, perPage, sortColumn, sortColumnDir, searchText);
  //     // setSelectedProjectName([{ id: firstProjectId, name: projects.projects.listData[0].name }]);
  //   }
  // }, [projects, selectedLink==='dashboard', noOfRecords > 0]);

  const GetAllProjectList = (
    sort_column_name,
    sort_column_dir,
    page,
    page_size
  ) => {
    const sortcolumn = !!sort_column_name ? sort_column_name : null;
    const sortdir = !!sort_column_dir ? sort_column_dir : null;
    dispatch(
      listProjects({
        clientId: userRole === "Client" ? userId : clientId,
        loginUserId: userRole === "Client" ? userId : clientId,
        searchTerm: !!searchText ? searchText : null,
        sortBy: sortcolumn,
        sortDirection: sortdir,
        page: page,
        pageSize: page_size,
      })
    );
  };

  useEffect(() => {
    const isReturningFromQRLayOut = localStorage.getItem(
      "isReturningFromQRLayOut"
    );

    if (!!isReturningFromQRLayOut && isReturningFromQRLayOut) {
      handleLinkClick("projectReport");
      localStorage.removeItem("isReturningFromQRLayOut");
    }
  }, []);

  // useEffect(() => {
  //   GetAllProjectList("p.name", "asc", 1, 25); //NEEL 01-10-2024
  // }, [userId, searchText]);

  // const standardCount = reportRecordsCountsDash?.standardCount || 0;
  // const abnormalCount = reportRecordsCountsDash?.abnormalCount || 0;
  const standardCount = reportRecordsCountsDashboard?.standardCount || 0;
  const abnormalCount = reportRecordsCountsDashboard?.abnormalCount || 0;

  const donutChartData = {
    labels: ["Standard Count", "Abnormal Count"],
    datasets: [
      {
        data: [standardCount, abnormalCount],
        backgroundColor: [
          !!standardCount && !!abnormalCount && standardCount > abnormalCount
            ? "#305d92"
            : "#585857",
          !!standardCount && !!abnormalCount && standardCount > abnormalCount
            ? "#585857"
            : "#305d92",
        ],
      },
    ],
  };

  // useEffect(() => {
  //   setProjectReportArr(projectReportDash);
  // }, [projectReportDash]);

  const qrCodeIdTemplate = (rowData) => {
    const qrCodeId = !!rowData.qrCode ? rowData.qrCode.split("/").pop() : "";
    return qrCodeId;
  };

  const legacyCodeIdTemplate = (rowData) => {
    const legacyCodeId = !!rowData.legacyCode
      ? rowData.legacyCode.split("/").pop()
      : "";
    return legacyCodeId;
  };

  // useEffect(() => {
  //   if (!!projectIdDash) {
  //     getProjectReportDataListDash(
  //       projectIdDash,
  //       page,
  //       perPage,
  //       sortColumn,
  //       sortColumnDir,
  //       searchText,
  //       selectedRoomNumber.map((item) => item.name).join(","),
  //       selectedRoomName.map((item) => item.name).join(","),
  //       selectedDescription.map((item) => item.name).join(","),
  //       selectedItemType.map((item) => item.name).join(","),
  //       selectedCondition.map((item) => item.name).join(","),
  //       selectedDFEStandard.map((item) => item.name).join(","),
  //       selectedLifeExpectancy.map((item) => item.name).join(",")
  //     );
  //   }
  // }, [
  //   projectIdDash,
  //   searchText,
  //   selectedRoomNumber,
  //   selectedRoomName,
  //   selectedDescription,
  //   selectedItemType,
  //   selectedCondition,
  //   selectedDFEStandard,
  //   selectedLifeExpectancy,
  // ]);

  // useEffect(() => {
  //   if (!!projects.projects.listData) {
  //     // && noOfRecords > 0) {
  //     setProjectIdDash(projects.projects.listData[0].id);
  //     // setSelectedProjectName(projects.projects.listData[0].name)
  //   }
  // }, [projects]); // NEEL 01-10-2024

  useEffect(() => {
    setProjectIdDash(projectId);
  }, [projectId]);

  // const naturalSort = (a, b) => {
  //   const chunk = /(\d+)|(\D+)/g;
  //   const chunkR = /\d+/;
  //   const aChunk = a.match(chunk);
  //   const bChunk = b.match(chunk);

  //   while (!!aChunk && !!bChunk && aChunk.length && bChunk.length) {
  //     const aChunkPart = aChunk.shift();
  //     const bChunkPart = bChunk.shift();
  //     if (chunkR.test(aChunkPart) !== chunkR.test(bChunkPart)) {
  //       return aChunkPart.localeCompare(bChunkPart);
  //     }
  //     if (chunkR.test(aChunkPart)) {
  //       if (parseInt(aChunkPart, 10) !== parseInt(bChunkPart, 10)) {
  //         return parseInt(aChunkPart, 10) - parseInt(bChunkPart, 10);
  //       }
  //     } else {
  //       const compareResult = aChunkPart.localeCompare(bChunkPart);
  //       if (compareResult !== 0) {
  //         return compareResult;
  //       }
  //     }
  //   }

  //   return (!!aChunk && !!bChunk) ? aChunk.length - bChunk.length : null;
  // };
  const naturalSort = (a, b) => {
    const chunk = /(\d+)|(\D+)/g;
    const chunkR = /\d+/;
    const aChunk = a.match(chunk) || []; // Ensure aChunk is an array
    const bChunk = b.match(chunk) || []; // Ensure bChunk is an array

    while (aChunk.length && bChunk.length) {
      const aChunkPart = aChunk.shift();
      const bChunkPart = bChunk.shift();
      if (chunkR.test(aChunkPart) !== chunkR.test(bChunkPart)) {
        return aChunkPart.localeCompare(bChunkPart);
      }
      if (chunkR.test(aChunkPart)) {
        if (parseInt(aChunkPart, 10) !== parseInt(bChunkPart, 10)) {
          return parseInt(aChunkPart, 10) - parseInt(bChunkPart, 10);
        }
      } else {
        const compareResult = aChunkPart.localeCompare(bChunkPart);
        if (compareResult !== 0) {
          return compareResult;
        }
      }
    }

    return aChunk.length - bChunk.length;
  };

  // const roomNumber = {
  //   options: !!distinctRoomNumbers && [...distinctRoomNumbers]
  //     .sort(!!naturalSort && naturalSort)
  //     .map((name, index) => ({ name: name, id: index + 1 }))
  // };

  const roomNumber = {
    options:
      !!distinctRoomNumbersDashboard &&
      [...distinctRoomNumbersDashboard]
        .filter((name) => name) // Filter out null or empty values
        .sort(naturalSort)
        .map((name, index) => ({ name: name, id: index + 1 })),
  };

  const roomName = {
    options:
      !!distinctRoomNamesDashboard &&
      [...distinctRoomNamesDashboard]
        .filter((name) => name)
        .map((name) => name.trim()) // Remove leading and trailing spaces
        .sort((a, b) => a.localeCompare(b)) // Sort the room names alphabetically
        .map((name, index) => ({ name: name, id: index + 1 })),
  };

  // const roomNumber = {
  //   options: !!distinctRoomNumbers && [...distinctRoomNumbers]
  //     .sort((a, b) => a - b) // Sort the room numbers numerically
  //     .map((name, index) => ({ name: name, id: index + 1 }))
  // };

  const description = {
    options:
      !!distinctDescriptionDashboard &&
      [...distinctDescriptionDashboard]
        .filter((name) => name)
        .sort((a, b) => a.trim().localeCompare(b.trim())) // Sort the descriptions alphabetically after trimming
        .map((name, index) => ({ name: name.trim(), id: index + 1 })), // Trim the names again
  };

  const itemType = {
    options:
      !!distinctItemTypeDashboard &&
      [...distinctItemTypeDashboard]
        .filter((name) => name)
        .map((name) => name.trim()) // Remove leading and trailing spaces
        .sort((a, b) => a.localeCompare(b)) // Sort the item types alphabetically
        .map((name, index) => ({ name: name, id: index + 1 })),
  };

  const condition = {
    options:
      !!distinctConditionDashboard &&
      [...distinctConditionDashboard]
        .filter((name) => name)
        .map((name) => name.trim()) // Remove leading and trailing spaces
        .sort((a, b) => a.localeCompare(b)) // Sort the conditions alphabetically
        .map((name, index) => ({ name: name, id: index + 1 })),
  };

  const dfeStandard = {
    options:
      !!distinctDFEStandardDashboard &&
      [...distinctDFEStandardDashboard]
        .filter((name) => name)
        .map((name) => name.trim()) // Remove leading and trailing spaces
        .sort((a, b) => a.localeCompare(b)) // Sort the DFE standards alphabetically
        .map((name, index) => ({ name: name, id: index + 1 })),
  };

  const lifeExpectancy = {
    options:
      !!distinctLifeExpectancyDashboard &&
      [...distinctLifeExpectancyDashboard]
        .filter((name) => name)
        .map((name) => name.trim()) // Remove leading and trailing spaces
        .sort((a, b) => a.localeCompare(b)) // Sort the life expectancies alphabetically
        .map((name, index) => ({ name: name, id: index + 1 })),
  };

  const conditionForChart = {
    options:
      !!conditionBreakdownListDashboard &&
      conditionBreakdownListDashboard
        .filter((item) => item.condition !== null) // Filter out items with null condition
        .map((name, index) => ({
          name: name.condition,
          id: index + 1,
          count: name.count,
        })),
  };
  
  const newConditionForChart =
    !!conditionForChart.options &&
    conditionForChart.options
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((item) => item);
  const quantities = newConditionForChart.map((item) => item.count);
  const adjustedQuantities = quantities;
  const barChartData = {
    labels: newConditionForChart.map((item) => item.name),
    datasets: [
      {
        label: "Condition Summary",
        data: adjustedQuantities,
        backgroundColor: "#305d92", //'rgba(54, 162, 235, 0.5)',
        // borderColor: 'rgba(54, 162, 235, 1)',
        // borderWidth: 1,
      },
    ],
  };

  const maxBarValue = Math.max(...adjustedQuantities); // Get the highest bar value
  const yAxisMax = maxBarValue + maxBarValue * 0.5;

  const barCharOptions = {
    indexAxis: "x",
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false, // Remove x-axis gridlines
        },
        title: {
          color: "#305d92",
          display: true,
          text: "Condition",
          font: {
            weight: "bold",
            family: "Arial",
            size: 14,
          },
        },
      },
      y: {
        grid: {
          display: false, // Remove x-axis gridlines
        },
        title: {
          color: "#305d92",
          display: true,
          text: "Total Quantity",
          font: {
            weight: "bold",
            family: "Arial",
            size: 14,
          },
        },
        ticks: {
          beginAtZero: true,
          // stepSize: Math.ceil(Math.max(...quantities) / 10),
          stepSize: Math.ceil(yAxisMax / 10),
        },
      },
    },
    plugins: {
      title: {
        color: "#305d92",
        display: true,
        text: "Condition Summary",
        font: {
          weight: "bold",
          family: "Arial",
          size: 14,
        },
      },
      legend: {
        display: false, // Hide legend
      },
      // tooltip: false,
      datalabels: {
        display: true,
        color: "black", // Set the color of the labels
        anchor: "end", // Position of the labels, you can use 'end', 'start', 'center'
        align: "end", // Alignment of the labels, you can use 'start', 'center', 'end'
        formatter: (value, context) => value, // Function to format the label text
      },
    },
  };

  const donutChartOptions = {
    plugins: {
      title: {
        font: {
          weight: "bold",
          family: "Arial",
          size: 14,
        },
        color: "#305d92",
        display: true,
        text: "DFE Standard",
      },
      legend: {
        display: false,
      },
      // tooltip: false,
      datalabels: {
        display: true,
        color: "white",
        formatter: (value, context) => {
          const index = context.dataIndex;
          if (index === 0) {
            return `Standard\n${value}, ${(
              (value / (standardCount + abnormalCount)) *
              100
            ).toFixed(2)}%`;
          } else if (index === 1) {
            return `Abnormal\n${value}, ${(
              (value / (standardCount + abnormalCount)) *
              100
            ).toFixed(2)}%`;
          }
        },
      },
    },
    cutout: "40%",
    elements: {
      arc: {
        borderWidth: 0, // Set border width to 0
      },
    },
  };

  // Find the second maximum count
  const sortedCounts = [...quantities].sort((a, b) => b - a);
  const secondMaxCount = sortedCounts[1];

  const filteredConditionBreakdownList = conditionBreakdownListDashboard.filter(
    (item) => item.lifeExpectancy !== "Total Result"
  );

  const lifeExpectancyForChart = {
    options:
      !!conditionBreakdownListDashboard &&
      conditionBreakdownListDashboard
        .filter((item) => item.lifeExpectancy !== "Total Result") // Filter out items with null condition
        .map((name, index) => ({
          name: name.lifeExpectancy === null ? "" : name.lifeExpectancy,
          id: index + 1,
          count: name.count,
          condition: name.condition,
        })),
  };

  const newLifeExpectancyForChart =
    !!lifeExpectancyForChart.options &&
    lifeExpectancyForChart.options
      .sort((a, b) => a.condition.localeCompare(b.condition))
      .map((item) => item);
  const lifeExpectancyQuantities = newLifeExpectancyForChart.map(
    (item) => item.count
  );
  const lifeExpectancyAdjustedQuantities = lifeExpectancyQuantities;
  const horizontalBarChartData = {
    labels: newLifeExpectancyForChart.map((item) => item.name).reverse(),
    datasets: [
      {
        label: "Life Expectancy",
        data: filteredConditionBreakdownList
          .map((item) => item.count)
          .reverse(),
        backgroundColor: "#305d92", //'rgba(54, 162, 235, 0.5)',
        // borderColor: 'rgba(54, 162, 235, 1)',
        // borderWidth: 1,
      },
    ],
  };

  const horizontalBarCharOptions = {
    indexAxis: "y",
    responsive: true,
    scales: {
      x: {
        title: {
          color: "#305d92",
          display: true,
          text: "Total Quantity",
          font: {
            weight: "bold",
            family: "Arial",
            size: 14,
          },
        },

        ticks: {
          beginAtZero: true,
          max: secondMaxCount,
          // stepSize: Math.ceil(secondMaxCount / 10),
          stepSize: Math.ceil(yAxisMax / 10),
        },
      },
      y: {
        grid: {
          display: false, // Remove x-axis gridlines
        },
        title: {
          color: "#305d92",
          display: true,
          text: "Life Expectancy",
          font: {
            weight: "bold",
            family: "Arial",
            size: 14,
          },
        },
      },
    },
    plugins: {
      title: {
        color: "#305d92",
        font: {
          weight: "bold",
          family: "Arial",
          size: 14,
        },
        display: true,
        text: "Life Expectancy",
      },
      legend: {
        display: false, // Hide legend
      },
      // tooltip: false,
      datalabels: {
        display: true,
        color: "black", // Set the color of the labels
        anchor: "end", // Position of the labels, you can use 'end', 'start', 'center'
        align: "end", // Alignment of the labels, you can use 'start', 'center', 'end'
        formatter: (value, context) => value, // Function to format the label text
      },
    },
  };
  //---------------

  const handleLinkClick = (link) => {
    setSelectedLink(link);
  };

  const validationSchema = Yup.object().shape({
    remarks: Yup.string().required("Remarks is required"),
  });

  useEffect(() => {
    const projectcolumnJSON = !!columnJSON && JSON.parse(columnJSON);
    let convertedData = [];
    if (projectcolumnJSON && typeof projectcolumnJSON === "object") {
      convertedData = Object.keys(projectcolumnJSON).map((key) => ({
        columnName: key,
        alternateColumnName: projectcolumnJSON[key].ALternateColumnName,
        visible: projectcolumnJSON[key].Visible,
      }));
    }
    setColumnData(convertedData);
  }, [columnJSON]);

  function findAlternateName(name) {
    const lifeExpectancyColumn =
      !!columnData && columnData.find((column) => column.columnName === name);
    return lifeExpectancyColumn
      ? lifeExpectancyColumn.alternateColumnName
      : null;
  }
  function checkVisibility(name) {
    const lifeExpectancyColumn =
      !!columnData && columnData.find((column) => column.columnName === name);
    return lifeExpectancyColumn ? lifeExpectancyColumn.visible : true;
  }

  function closeModal() {
    setLoader(false);
    formik.resetForm();
    setIsReqForChange(false);
    setId(0);
    setModelIsOpen(false);
  }

  function openModal() {
    setModelIsOpen(true);
  }

  const formik = useFormik({
    initialValues: {
      remarks: "",
    },
    validationSchema,
    onSubmit: async () => {
      setLoader(true);
      openModal(true);

      if (formik.isValid) {
        if (isReqForChange) {
          const res = await saveRequestForChangeDetails({
            projectId,
            id,
            ...formik.values,
            userId,
          });
          closeModal();
          if (res.payload.result > 0) {
            Swal.fire({
              title: res?.payload?.message,
              confirmButtonColor: "#000000",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "confirm-button-swal",
              },
            });
            getProjectReportDataList(
              projectId,
              loginUserId,
              enableRequestRowEdit,
              page,
              perPage,
              sortColumn,
              sortColumnDir,
              searchText,
              filters
            );
          }
        } else {
          setIsReqForChange(false);
        }
      }
    },
  });

  const handleReqForChange = () => {
    formik.handleSubmit();
  };

  // useEffect(() => {
  //   if (
  //     projects.projects.listData &&
  //     projects.projects.listData.length > 0 &&
  //     noOfRecords > 0
  //   ) {
  //     const firstProjectId = projects.projects.listData[0].id;
  //     getProjectReportDataListDash(
  //       firstProjectId,
  //       page,
  //       perPage,
  //       sortColumn,
  //       sortColumnDir,
  //       searchText
  //     );
  //     // setSelectedProjectName([{ id: firstProjectId, name: projects.projects.listData[0].name }]);
  //   }
  // }, [projects]); //, selectedLink==='dashboard']); //, noOfRecords > 0]); //NEEL 01-10-2024

  const ReqForChangeTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Request For Change
    </Tooltip>
  );
  const ViewImageTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View Image
    </Tooltip>
  );

  const ViewHistoryTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View History
    </Tooltip>
  );

  const DeleteProjectDetailsTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Delete
    </Tooltip>
  );
  const ViewQRTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View QR
    </Tooltip>
  );
  // const updateProjectReports = async (projectReportList) => {
  //   return new Promise(async (resolve, reject) => {
  //     const transformedData = projectReportList.map((data, index) => {
  //       return {
  //         id: data.id,
  //         level: data.level,
  //         roomNumber: data.roomNumber,
  //         roomName: data.roomName,
  //         description: data.description,
  //         quantity: data.quantity,
  //         itemType: data.itemType,
  //         dfeCode: data.esfaCode,
  //         condition: data.condition,
  //         lifeExpectancy: data.lifeExpectancy,
  //         makeModel: data.makeModel,
  //         length: data.length,
  //         depth: data.depth,
  //         height: data.height,
  //         primaryColour: data.primaryColour,
  //         secondaryColour: data.secondaryColour,
  //         dfeStandard: data.abnormal,
  //         comments: data.comments,
  //         photograph: data.photograph,
  //         cost: data.cost,
  //         carbonSavingKG: data.carbonSavingKG,
  //         qrCode: data.qrCode,
  //       };
  //     });
  //     const res = await dispatch(updateProjectReport(transformedData));
  //     resolve(res);
  //   });
  // };

  // const inputComponent = (value, name, index) => {
  //   return (
  //     <input
  //       name={name}
  //       className="form-control fnt-12"
  //       defaultValue={value}
  //       placeholder="Enter value"
  //       onChange={(e) => {
  //         setProjectReportArr((prevArr) => {
  //           const newArray = [...prevArr];
  //           newArray[index] = {
  //             ...newArray[index],
  //             [e.target.name]: e.target.value,
  //           };
  //           return newArray;
  //         });
  //       }}
  //     />
  //   );
  // };
  function extractIdFromUrl(url) {
    const parts = url.split("/");
    return parts[parts.length - 1];
  }

  const viewQR = (row) => {
    if (!row.qrCode) return null;
    return (
      <div className={`actions`}>
        <OverlayTrigger placement="top" overlay={ViewQRTooltip}>
          <button
            onClick={() => {
              if (!!row.qrCode) {
                localStorage.setItem("targetLayoutToQRView", "ProjectReport");
                const itemId = extractIdFromUrl(row.qrCode);
                // const newItemUrl = `${window.location.origin}/viewitem/${itemId}`;
                const newItemUrl = `${config.WEB_URL}/viewitem/${itemId}`;
                //window.location.href = newItemUrl;
                window.open(newItemUrl, "_blank");
              }
              // else {
              //   Swal.fire({
              //     title: "Not found!",
              //     confirmButtonText: "OK",
              //   });
              // }
            }}
            className="btn btn-sm bg-warning-subtle me-2"
          >
            <i className="bi bi-qr-code-scan" />
          </button>
        </OverlayTrigger>
      </div>
    );
  };

  const onCellEditComplete = async (data) => {
    const index = data.rowIndex;
    const projectDetails = [...projectReportArr];
    const columnData = projectDetails[index];
    // const res = await getLookUpColumnValue(data.field, data.newRowData);
    // switch (data.field) {
    //   case "rawDescription":
    //     setProjectReportArr((prevArr) => {
    //       const newArray = [...prevArr];
    //       newArray[index] = {
    //         ...newArray[index],
    //         itemType: res.payload.itemType,
    //         description: res.payload.description,
    //         esfaCode: res.payload.esfaCode,
    //         abnormal: res.payload.abnormal,
    //         cost: res.payload.cost,
    //         carbonSavingKG: res.payload.carbonSavingKG,
    //       };
    //       return newArray;
    //     });
    //     break;
    //   case "description":
    //     setProjectReportArr((prevArr) => {
    //       const newArray = [...prevArr];
    //       newArray[index] = {
    //         ...newArray[index],
    //         itemType: res.payload.itemType,
    //         esfaCode: res.payload.esfaCode,
    //         abnormal: res.payload.abnormal,
    //         cost: res.payload.cost,
    //         carbonSavingKG: res.payload.carbonSavingKG,
    //       };
    //       return newArray;
    //     });
    //     break;
    //   case "esfaCode":
    //     setProjectReportArr((prevArr) => {
    //       const newArray = [...prevArr];
    //       newArray[index] = {
    //         ...newArray[index],
    //         esfaCode: columnData.esfaCode,
    //         abnormal: res.payload.abnormal,
    //       };
    //       return newArray;
    //     });
    //     break;
    //   case "height":
    //     setProjectReportArr((prevArr) => {
    //       const newArray = [...prevArr];
    //       newArray[index] = {
    //         ...newArray[index],
    //         esfaCode: res.payload.esfaCode,
    //         abnormal: res.payload.abnormal,
    //         cost: res.payload.cost,
    //       };
    //       return newArray;
    //     });
    //     break;
    //   case "depth":
    //     setProjectReportArr((prevArr) => {
    //       const newArray = [...prevArr];
    //       newArray[index] = {
    //         ...newArray[index],
    //         esfaCode: res.payload.esfaCode,
    //         abnormal: res.payload.abnormal,
    //         cost: res.payload.cost,
    //       };
    //       return newArray;
    //     });
    //     break;
    //   case "length":
    //     setProjectReportArr((prevArr) => {
    //       const newArray = [...prevArr];
    //       newArray[index] = {
    //         ...newArray[index],
    //         esfaCode: res.payload.esfaCode,
    //         abnormal: res.payload.abnormal,
    //         cost: res.payload.cost,
    //       };
    //       return newArray;
    //     });
    //     break;
    //   case "rawPrimaryColour":
    //     setProjectReportArr((prevArr) => {
    //       const newArray = [...prevArr];
    //       newArray[index] = {
    //         ...newArray[index],
    //         primaryColour: res.payload.primaryColour,
    //       };
    //       return newArray;
    //     });
    //     break;
    //   case "rawSecondaryColour":
    //     setProjectReportArr((prevArr) => {
    //       const newArray = [...prevArr];
    //       newArray[index] = {
    //         ...newArray[index],
    //         secondaryColour: res.payload.secondaryColour,
    //       };
    //       return newArray;
    //     });
    //     break;
    //   default:
    //     break;
    // }
  };
  const onEditableRowCellEditComplete = async (data) => {
    const index = data.rowIndex;
    const projectDetails = [...selectedProjectDetails];
    const columnData = projectDetails[index];
    // const res = await getLookupColumnValue(
    //   data.field,
    //   !!data.newRowData ? data.newRowData : data.rowData
    // );
    // switch (data.field) {
    //   case "rawDescription":
    //     // setSelectedProjectDetails((prevArr) => {
    //     //   const newArray = [...prevArr];
    //     //   newArray[index] = {
    //     //     ...newArray[index],
    //     //     itemType: res.payload.itemType,
    //     //     description: res.payload.description,
    //     //     esfaCode: res.payload.esfaCode,
    //     //     abnormal: res.payload.abnormal,
    //     //     cost: res.payload.cost,
    //     //     carbonSavingKG: res.payload.carbonSavingKG,
    //     //   };
    //     //   return newArray;
    //     // });
    //     // const newValue = e.target.value;
    //     setSelectedProjectDetails((prevArr) => {
    //       return prevArr.map((item, index) => {
    //         return {
    //           ...item,
    //           itemType: res.payload.itemType,
    //           description: res.payload.description,
    //           esfaCode: res.payload.esfaCode,
    //           abnormal: res.payload.abnormal,
    //           cost: res.payload.cost,
    //           carbonSavingKG: res.payload.carbonSavingKG,
    //         };
    //       });
    //     });
    //     break;
    //   case "description":
    //     // setSelectedProjectDetails((prevArr) => {
    //     //   const newArray = [...prevArr];
    //     //   newArray[index] = {
    //     //     ...newArray[index],
    //     //     itemType: res.payload.itemType,
    //     //     esfaCode: res.payload.esfaCode,
    //     //     abnormal: res.payload.abnormal,
    //     //     cost: res.payload.cost,
    //     //     carbonSavingKG: res.payload.carbonSavingKG,
    //     //   };
    //     //   return newArray;
    //     // });
    //     setSelectedProjectDetails((prevArr) => {
    //       return prevArr.map((item, index) => {
    //         return {
    //           ...item,
    //           itemType: res.payload.itemType,
    //           esfaCode: res.payload.esfaCode,
    //           abnormal: res.payload.abnormal,
    //           cost: res.payload.cost,
    //           carbonSavingKG: res.payload.carbonSavingKG,
    //         };
    //       });
    //     });
    //     break;
    //   case "esfaCode":
    //     // setSelectedProjectDetails((prevArr) => {
    //     //   const newArray = [...prevArr];
    //     //   newArray[index] = {
    //     //     ...newArray[index],
    //     //     esfaCode: columnData.esfaCode,
    //     //     abnormal: res.payload.abnormal,
    //     //   };
    //     //   return newArray;
    //     // });
    //     setSelectedProjectDetails((prevArr) => {
    //       return prevArr.map((item, index) => {
    //         return {
    //           ...item,
    //           esfaCode: columnData.esfaCode,
    //           abnormal: res.payload.abnormal,
    //         };
    //       });
    //     });
    //     break;
    //   case "height":
    //     // setSelectedProjectDetails((prevArr) => {
    //     //   const newArray = [...prevArr];
    //     //   newArray[index] = {
    //     //     ...newArray[index],
    //     //     esfaCode: res.payload.esfaCode,
    //     //     abnormal: res.payload.abnormal,
    //     //     cost: res.payload.cost,
    //     //   };
    //     //   return newArray;
    //     // });
    //     setSelectedProjectDetails((prevArr) => {
    //       return prevArr.map((item, index) => {
    //         return {
    //           ...item,
    //           esfaCode: res.payload.esfaCode,
    //           abnormal: res.payload.abnormal,
    //           cost: res.payload.cost,
    //         };
    //       });
    //     });
    //     break;
    //   case "depth":
    //     // setSelectedProjectDetails((prevArr) => {
    //     //   const newArray = [...prevArr];
    //     //   newArray[index] = {
    //     //     ...newArray[index],
    //     //     esfaCode: res.payload.esfaCode,
    //     //     abnormal: res.payload.abnormal,
    //     //     cost: res.payload.cost,
    //     //   };
    //     //   return newArray;
    //     // });
    //     setSelectedProjectDetails((prevArr) => {
    //       return prevArr.map((item, index) => {
    //         return {
    //           ...item,
    //           esfaCode: res.payload.esfaCode,
    //           abnormal: res.payload.abnormal,
    //           cost: res.payload.cost,
    //         };
    //       });
    //     });
    //     break;
    //   case "length":
    //     // setSelectedProjectDetails((prevArr) => {
    //     //   const newArray = [...prevArr];
    //     //   newArray[index] = {
    //     //     ...newArray[index],
    //     //     esfaCode: res.payload.esfaCode,
    //     //     abnormal: res.payload.abnormal,
    //     //     cost: res.payload.cost,
    //     //   };
    //     //   return newArray;
    //     // });
    //     setSelectedProjectDetails((prevArr) => {
    //       return prevArr.map((item, index) => {
    //         return {
    //           ...item,
    //           esfaCode: res.payload.esfaCode,
    //           abnormal: res.payload.abnormal,
    //           cost: res.payload.cost,
    //         };
    //       });
    //     });
    //     break;
    //   case "rawPrimaryColour":
    //     // setSelectedProjectDetails((prevArr) => {
    //     //   const newArray = [...prevArr];
    //     //   newArray[index] = {
    //     //     ...newArray[index],
    //     //     primaryColour: res.payload.primaryColour,
    //     //   };
    //     //   return newArray;
    //     // });
    //     setSelectedProjectDetails((prevArr) => {
    //       return prevArr.map((item, index) => {
    //         return {
    //           ...item,
    //           primaryColour: res.payload.primaryColour,
    //         };
    //       });
    //     });
    //     break;
    //   case "rawSecondaryColour":
    //     // setSelectedProjectDetails((prevArr) => {
    //     //   const newArray = [...prevArr];
    //     //   newArray[index] = {
    //     //     ...newArray[index],
    //     //     secondaryColour: res.payload.secondaryColour,
    //     //   };
    //     //   return newArray;
    //     // });
    //     setSelectedProjectDetails((prevArr) => {
    //       return prevArr.map((item, index) => {
    //         return {
    //           ...item,
    //           secondaryColour: res.payload.secondaryColour,
    //         };
    //       });
    //     });
    //     break;
    //   default:
    //     break;
    // }
  };
  const inputComponent = (options) => {
    const { rowIndex } = options;
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => {
          options.editorCallback(e.target.value);
          setProjectReportArr((prevArr) => {
            const newArray = [...prevArr];
            newArray[rowIndex] = {
              ...newArray[rowIndex],
              [options.field]: e.target.value,
            };
            return newArray;
          });
          setIsLabelShow(true);
          //setIsChangesSaved(false);
        }}
      />
    );
  };

  const getEditorComponent = (options) => {
    const { rowData, field } = options;

    // if (
    //   (userRole === "Client" || userRole === "ClientUser") &&
    //   enableRequestRowEdit
    // ) {
    //   if (rowData.isApproved) {
    //     return inputComponent(options);
    //   }
    // } else
    // if (userRole === "Admin" && !enableRequestRowEdit) {
    //   return inputComponent(options);
    // }
    if (userRole === "Admin") {
      return inputComponent(options);
    }

    return rowData[field]; // Return the existing cell content if conditions are not met
  };

  const getProjectReportAllDataList = async (
    project_id,
    client_id,
    isShowChangeRequest,
    page_number,
    page_size,
    sort_column_name,
    sort_column_dir,
    search_text,
    filters
  ) => {
    const data = {
      page_number: page_number,
      page_size: page_size,
      projectId: project_id,
      clientId: client_id,
      isShowChangeRequest: isShowChangeRequest,
      sort_column_name: !!sort_column_name ? sort_column_name : null,
      sort_column_dir: !!sort_column_dir ? sort_column_dir : null,
      search_text: !!search_text ? search_text : null,
      level: !!filters["level"].value
        ? filters["level"].value.toString()
        : filters["level"].value,
      room_number: !!filters["roomNumber"].value
        ? filters["roomNumber"].value.toString()
        : filters["roomNumber"].value,
      room_name: !!filters["roomName"].value
        ? filters["roomName"].value.toString()
        : filters["roomName"].value,
      description: !!filters["description"].value
        ? filters["description"].value.toString()
        : filters["description"].value,
      quantity: !!filters["quantity"].value
        ? filters["quantity"].value.toString()
        : filters["quantity"].value,
      item_type: !!filters["itemType"].value
        ? filters["itemType"].value.toString()
        : filters["itemType"].value,
      esfa_code: !!filters["esfaCode"].value
        ? filters["esfaCode"].value.toString()
        : filters["esfaCode"].value,
      condition: !!filters["condition"].value
        ? filters["condition"].value.toString()
        : filters["condition"].value,
      life_expectancy: !!filters["lifeExpectancy"].value
        ? filters["lifeExpectancy"].value.toString()
        : filters["lifeExpectancy"].value,
      colour: !!filters["colour"].value
        ? filters["colour"].value.toString()
        : filters["colour"].value,
      make_model: !!filters["makeModel"].value
        ? filters["makeModel"].value.toString()
        : filters["makeModel"].value,
      length: !!filters["length"].value
        ? filters["length"].value.toString()
        : filters["length"].value,
      depth: !!filters["depth"].value
        ? filters["depth"].value.toString()
        : filters["depth"].value,
      height: !!filters["height"].value
        ? filters["height"].value.toString()
        : filters["height"].value,
      primary_colour: !!filters["primaryColour"].value
        ? filters["primaryColour"].value.toString()
        : filters["primaryColour"].value,
      secondary_colour: !!filters["secondaryColour"].value
        ? filters["secondaryColour"].value.toString()
        : filters["secondaryColour"].value,
      abmormal: !!filters["abnormal"].value
        ? filters["abnormal"].value.toString()
        : filters["abnormal"].value,
      comments: !!filters["comments"].value
        ? filters["comments"].value.toString()
        : filters["comments"].value,
      // photograph: !!filters["photograph"].value
      //   ? filters["photograph"].value.toString()
      //   : filters["photograph"].value,
      cost: !!filters["cost"].value
        ? filters["cost"].value.toString()
        : filters["cost"].value,
      carbon_saving_kg: !!filters["carbonSavingKG"].value
        ? filters["carbonSavingKG"].value.toString()
        : filters["carbonSavingKG"].value,
      qr_code: !!filters["qrCode"].value
        ? filters["qrCode"].value.toString()
        : filters["qrCode"].value,
      legacy_code: !!filters["legacyCode"].value
        ? filters["legacyCode"].value.toString()
        : filters["legacyCode"].value,
      newRoomName: !!filters["newRoomName"].value
        ? filters["newRoomName"].value.toString()
        : filters["newRoomName"].value,
      newRoomNumber: !!filters["newRoomNumber"].value
        ? filters["newRoomNumber"].value.toString()
        : filters["newRoomNumber"].value,
    };
    //dispatch(projectsReport(data));
    var reportData = await dispatch(
      projectsReportDetailedLegacySurveyTable(data)
    );
    if (reportData.payload) {
      const filterDataParam = {
        projectId: projectId,
        isShowChangeRequest: enableRequestRowEdit,
      };
      dispatch(allProjectsReportList(filterDataParam));
    }
  };

  const getProjectReportDataList = async (
    project_id,
    client_id,
    isShowChangeRequest,
    page_number,
    page_size,
    sort_column_name,
    sort_column_dir,
    search_text,
    filters
  ) => {
    const data = {
      page_number: page_number,
      page_size: page_size,
      projectId: project_id,
      clientId: client_id,
      isShowChangeRequest: isShowChangeRequest,
      sort_column_name: !!sort_column_name ? sort_column_name : null,
      sort_column_dir: !!sort_column_dir ? sort_column_dir : null,
      search_text: !!search_text ? search_text : null,
      level: !!filters["level"].value
        ? filters["level"].value.toString()
        : filters["level"].value,
      room_number: !!filters["roomNumber"].value
        ? filters["roomNumber"].value.toString()
        : filters["roomNumber"].value,
      room_name: !!filters["roomName"].value
        ? filters["roomName"].value.toString()
        : filters["roomName"].value,
      description: !!filters["description"].value
        ? filters["description"].value.toString()
        : filters["description"].value,
      quantity: !!filters["quantity"].value
        ? filters["quantity"].value.toString()
        : filters["quantity"].value,
      item_type: !!filters["itemType"].value
        ? filters["itemType"].value.toString()
        : filters["itemType"].value,
      esfa_code: !!filters["esfaCode"].value
        ? filters["esfaCode"].value.toString()
        : filters["esfaCode"].value,
      condition: !!filters["condition"].value
        ? filters["condition"].value.toString()
        : filters["condition"].value,
      life_expectancy: !!filters["lifeExpectancy"].value
        ? filters["lifeExpectancy"].value.toString()
        : filters["lifeExpectancy"].value,
      colour: !!filters["colour"].value
        ? filters["colour"].value.toString()
        : filters["colour"].value,
      make_model: !!filters["makeModel"].value
        ? filters["makeModel"].value.toString()
        : filters["makeModel"].value,
      length: !!filters["length"].value
        ? filters["length"].value.toString()
        : filters["length"].value,
      depth: !!filters["depth"].value
        ? filters["depth"].value.toString()
        : filters["depth"].value,
      height: !!filters["height"].value
        ? filters["height"].value.toString()
        : filters["height"].value,
      primary_colour: !!filters["primaryColour"].value
        ? filters["primaryColour"].value.toString()
        : filters["primaryColour"].value,
      secondary_colour: !!filters["secondaryColour"].value
        ? filters["secondaryColour"].value.toString()
        : filters["secondaryColour"].value,
      abmormal: !!filters["abnormal"].value
        ? filters["abnormal"].value.toString()
        : filters["abnormal"].value,
      comments: !!filters["comments"].value
        ? filters["comments"].value.toString()
        : filters["comments"].value,
      // photograph: !!filters["photograph"].value
      //   ? filters["photograph"].value.toString()
      //   : filters["photograph"].value,
      cost: !!filters["cost"].value
        ? filters["cost"].value.toString()
        : filters["cost"].value,
      carbon_saving_kg: !!filters["carbonSavingKG"].value
        ? filters["carbonSavingKG"].value.toString()
        : filters["carbonSavingKG"].value,
      qr_code: !!filters["qrCode"].value
        ? filters["qrCode"].value.toString()
        : filters["qrCode"].value,
      legacy_code: !!filters["legacyCode"].value
        ? filters["legacyCode"].value.toString()
        : filters["legacyCode"].value,
      newRoomName: !!filters["newRoomName"].value
        ? filters["newRoomName"].value.toString()
        : filters["newRoomName"].value,
      newRoomNumber: !!filters["newRoomNumber"].value
        ? filters["newRoomNumber"].value.toString()
        : filters["newRoomNumber"].value,
    };
    //dispatch(projectsReport(data));
    var reportData = await dispatch(
      projectsReportDetailedLegacySurveyTable(data)
    );
  };

  const handleSort = (column, sortDirection) => {
    // if (Object.keys(column).length !== 0 && !!sortDirection) {
    setSortColumn(column);
    setSortColumnDir(sortDirection);
    getProjectReportDataList(
      projectId,
      loginUserId,
      enableRequestRowEdit,
      page,
      perPage,
      column,
      sortDirection,
      searchText,
      filters
    );
    // }
  };

  const template2 = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 25, value: 25 },
        { label: 50, value: 50 },
        { label: 100, value: 100 },
        { label: 200, value: 200 },
        { label: 500, value: 500 },
        { label: 1000, value: 1000 },
        // { label: 'View All', value: options.totalRecords }
        // { label: 'View all', value: noOfRecords}
      ];

      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Items per page:{" "}
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={(e) => {
              setPerPage(e.value);
              setPage(1);
              setFirst(0);
              getProjectReportDataList(
                projectId,
                loginUserId,
                enableRequestRowEdit,
                page,
                e.value,
                sortColumn,
                sortColumnDir,
                searchText,
                filters
              );
            }}
          />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  const handlePageChange = (page) => {
    setPage(page);
    getProjectReportDataList(
      projectId,
      loginUserId,
      enableRequestRowEdit,
      page,
      perPage,
      sortColumn,
      sortColumnDir,
      searchText,
      filters
    );
  };

  const onPageChange = (e, index) => {
    setFirst(e.first);
    setPage(e.page + 1);
    setPerPage(e.rows);
    getProjectReportDataList(
      projectId,
      loginUserId,
      enableRequestRowEdit,
      e.page + 1,
      e.rows,
      sortColumn,
      sortColumnDir,
      searchText,
      filters
    );
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setPage(page);
    getProjectReportDataList(
      projectId,
      loginUserId,
      enableRequestRowEdit,
      page,
      newPerPage,
      sortColumn,
      sortColumnDir,
      searchText,
      filters
    );
  };

  const rowClass = (data) => {
    return data.colour;
  };

  const rowItemTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{option}</span>
      </div>
    );
  };

  const rowFilterTemplate = (options, data, alternateColumnName) => {
    return (
      <MultiSelect
        value={options.value}
        options={data}
        filter
        itemTemplate={rowItemTemplate}
        onChange={(e) => {
          options.filterCallback(e.value);
        }}
        placeholder={`Select ${alternateColumnName}`}
        className="p-column-filter"
        maxSelectedLabels={1}
      />
    );
  };

  // const getFieldUniqueList = (reportData, fieldName) => {
  //   const setData = new Set();
  //   const uniqueData = reportData.reduce((acc, x) => {
  //     if (x[fieldName] && !setData.has(x[fieldName])) {
  //       setData.add(x[fieldName]);
  //       acc.push(x[fieldName]);
  //     }
  //     return acc;
  //   }, []);
  //   return uniqueData;
  // };
  const getFieldUniqueList = (reportData, fieldName) => {
    const setData = new Set();
    reportData.forEach((x) => {
      if (x[fieldName]) {
        const value = x[fieldName].toString().trim();
        setData.add(value);
      }
    });
    const uniqueData = Array.from(setData);
    return uniqueData.sort();
  };

  // const getFieldUniqueList = (reportData, fieldName) => {
  //   const setData = new Set();
  //   const uniqueData = reportData.reduce((acc, x) => {
  //     if (!setData.has(`${x[fieldName]}` && !!x[fieldName])) {
  //       setData.add(`${x[fieldName]}`);
  //       acc.push(x[fieldName]);
  //     }
  //     return acc;
  //   }, []);
  //   return uniqueData;
  // };
  const inputRowEditComponent = (options) => {
    const { rowIndex } = options;
    // const value = selectedProjectDetails[rowIndex][options.field]
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => {
          options.editorCallback(e.target.value);
          const newValue = e.target.value;
          setSelectedProjectDetails((prevArr) => {
            return prevArr.map((item, index) => {
              return {
                ...item,
                [options.field]: newValue,
              };
            });
          });
          setIsChangesSaved(false);
        }}
      />
    );
  };
  const initFilters = () => {
    const clearFilter = {
      level: { value: null, matchMode: FilterMatchMode.IN },
      roomNumber: { value: null, matchMode: FilterMatchMode.IN },
      roomName: { value: null, matchMode: FilterMatchMode.IN },
      description: { value: null, matchMode: FilterMatchMode.IN },
      quantity: { value: null, matchMode: FilterMatchMode.IN },
      itemType: { value: null, matchMode: FilterMatchMode.IN },
      esfaCode: { value: null, matchMode: FilterMatchMode.IN },
      condition: { value: null, matchMode: FilterMatchMode.IN },
      lifeExpectancy: { value: null, matchMode: FilterMatchMode.IN },
      colour: { value: null, matchMode: FilterMatchMode.IN },
      makeModel: { value: null, matchMode: FilterMatchMode.IN },
      length: { value: null, matchMode: FilterMatchMode.IN },
      depth: { value: null, matchMode: FilterMatchMode.IN },
      height: { value: null, matchMode: FilterMatchMode.IN },
      primaryColour: { value: null, matchMode: FilterMatchMode.IN },
      secondaryColour: { value: null, matchMode: FilterMatchMode.IN },
      abnormal: { value: null, matchMode: FilterMatchMode.IN },
      comments: { value: null, matchMode: FilterMatchMode.IN },
      // photograph: { value: null, matchMode: FilterMatchMode.IN },
      cost: { value: null, matchMode: FilterMatchMode.IN },
      carbonSavingKG: { value: null, matchMode: FilterMatchMode.IN },
      qrCode: { value: null, matchMode: FilterMatchMode.IN },
      legacyCode: { value: null, matchMode: FilterMatchMode.IN },
      newRoomName: { value: null, matchMode: FilterMatchMode.IN },
      newRoomNumber: { value: null, matchMode: FilterMatchMode.IN },
    };
    setFilters(clearFilter);
    getProjectReportDataList(
      projectId,
      loginUserId,
      enableRequestRowEdit,
      page,
      perPage,
      sortColumn,
      sortColumnDir,
      searchText,
      clearFilter
    );
  };

  // useEffect(() => {
  //   setProjectDetailsArr(projectReport);
  // }, [projectReport]);

  useEffect(() => {
    setProjectReportArr(projectReport);
  }, [projectReport]);

  useEffect(() => {
    const uniqueLevel = getFieldUniqueList(projectReportDDList, "level");
    const uniqueRoomNumber = getFieldUniqueList(
      projectReportDDList,
      "roomNumber"
    );
    const uniqueRoomName = getFieldUniqueList(projectReportDDList, "roomName");
    const uniqueDescriptions = getFieldUniqueList(
      projectReportDDList,
      "description"
    );
    const uniqueQuantity = getFieldUniqueList(projectReportDDList, "quantity");
    const uniqueItemType = getFieldUniqueList(projectReportDDList, "itemType");
    const uniqueEsfaCode = getFieldUniqueList(projectReportDDList, "esfaCode");
    const uniqueCondition = getFieldUniqueList(
      projectReportDDList,
      "condition"
    );
    const uniqueLifeExpectancy = getFieldUniqueList(
      projectReportDDList,
      "lifeExpectancy"
    );
    const uniqueColour = getFieldUniqueList(projectReportDDList, "colour");
    const uniqueMakeModel = getFieldUniqueList(
      projectReportDDList,
      "makeModel"
    );
    const uniqueLength = getFieldUniqueList(projectReportDDList, "length");
    const uniqueDepth = getFieldUniqueList(projectReportDDList, "depth");
    const uniqueHeight = getFieldUniqueList(projectReportDDList, "height");
    const uniquePrimaryColour = getFieldUniqueList(
      projectReportDDList,
      "primaryColour"
    );
    const uniqueSecondaryColour = getFieldUniqueList(
      projectReportDDList,
      "secondaryColour"
    );
    const uniqueAbnormal = getFieldUniqueList(projectReportDDList, "abnormal");
    const uniqueComments = getFieldUniqueList(projectReportDDList, "comments");
    // const uniquePhotograph = getFieldUniqueList(
    //   projectReportDDList,
    //   "photograph"
    // );
    const uniqueCost = getFieldUniqueList(projectReportDDList, "cost");
    const uniqueCarbonSavingKG = getFieldUniqueList(
      projectReportDDList,
      "carbonSavingKG"
    );
    const uniqueQRCode = getFieldUniqueList(projectReportDDList, "qrCode");
    const uniqueLegacyCode = getFieldUniqueList(
      projectReportDDList,
      "legacyCode"
    );
    const uniqueNewRoomName = getFieldUniqueList(
      projectReportDDList,
      "newRoomName"
    );
    const uniqueNewRoomNumber = getFieldUniqueList(
      projectReportDDList,
      "newRoomNumber"
    );

    setLevelFilter(uniqueLevel);
    setRoomNumberFilter(uniqueRoomNumber);
    setRoomNameFilter(uniqueRoomName);
    setDescriptionFilter(uniqueDescriptions);
    setQuantityFilter(uniqueQuantity);
    setItemTypeFilter(uniqueItemType);
    setEsfaCodeFilter(uniqueEsfaCode);
    setConditionFilter(uniqueCondition);
    setLifeExpectancyFilter(uniqueLifeExpectancy);
    setColourFilter(uniqueColour);
    setMakeModelFilter(uniqueMakeModel);
    setLengthFilter(uniqueLength);
    setDepthFilter(uniqueDepth);
    setHeightFilter(uniqueHeight);
    setPrimaryColourFilter(uniquePrimaryColour);
    setSecondaryColourFilter(uniqueSecondaryColour);
    setAbnormalFilter(uniqueAbnormal);
    setCommentsFilter(uniqueComments);
    // setPhotographFilter(uniquePhotograph);
    setCostFilter(uniqueCost);
    setCarbonSavingKGFilter(uniqueCarbonSavingKG);
    setQRCodeFilter(uniqueQRCode);
    setLegacyCodeFilter(uniqueLegacyCode);
    setNewRoomNameFilter(uniqueNewRoomName);
    setNewRoomNumberFilter(uniqueNewRoomNumber);
  }, [projectReportDDList]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!isChangesSaved) {
        event.preventDefault();
        event.returnValue = ""; // For older browsers
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isChangesSaved]);

  const handleGoToIntendedPage = useCallback(
    (location) => history.push(location),
    [history]
  );

  useEffect(() => {
    if (triggerExit.onOk) {
      handleGoToIntendedPage(triggerExit.path);
    }
    const unblock = history.block((location) => {
      if (
        !location.location.pathname.includes("ProjectReport") &&
        !isChangesSaved
      ) {
        setVisibleDialog(true);
      } else {
        setTriggerExit((obj) => ({
          ...obj,
          onOk: true,
        }));
      }
      setTriggerExit((obj) => ({ ...obj, path: location.location.pathname }));
      if (triggerExit.onOk) {
        return true;
      }
      return false;
    });

    return () => {
      unblock();
    };
  }, [
    handleGoToIntendedPage,
    history,
    triggerExit.onOk,
    triggerExit.path,
    isChangesSaved,
  ]);

  // useEffect(() => {
  //   if (selectedLink === "projectReport") {
  //     if (!!projectId) {
  //       const data = {
  //         projectId: projectId,
  //         isShowChangeRequest: enableRequestRowEdit,
  //       };
  //       dispatch(allProjectsReportList(data));
  //     }
  //   }
  // }, [
  //   //projects, // NEEL 01-10-2024
  //   isChangesSaved,
  //   enableRequestRowEdit,
  //   selectedLink,
  // ]);

  // useEffect(() => {
  //   if (!!projectId) {
  //     getProjectReportDataList(
  //       projectId,
  //       loginUserId,
  //       enableRequestRowEdit,
  //       page,
  //       perPage,
  //       sortColumn,
  //       sortColumnDir,
  //       searchText,
  //       filters
  //     );
  //   }
  // }, [enableRequestRowEdit, searchText]); //, isChangesSaved]);//projectId//NEEL 01-10-2024

  useEffect(() => {
    if (selectedLink === "projectReport") {
      getProjectReportAllDataList(
        projectId,
        loginUserId,
        enableRequestRowEdit,
        page,
        perPage,
        sortColumn,
        sortColumnDir,
        searchText,
        filters
      );
    }
  }, [selectedLink]);

  useEffect(() => {
    if (selectedLink === "projectReport") {
      getProjectReportDataList(
        projectId,
        loginUserId,
        enableRequestRowEdit,
        page,
        perPage,
        sortColumn,
        sortColumnDir,
        searchText,
        filters
      );
    }
  }, [enableRequestRowEdit, searchText]);

  // useEffect(() => {
  //   getProjectReportDataList(
  //     projectId,
  //     loginUserId,
  //     enableRequestRowEdit,
  //     page,
  //     perPage,
  //     sortColumn,
  //     sortColumnDir,
  //     searchText,
  //     filters
  //   );
  // }, [enableRequestRowEdit]);//NEEL 01-10-2024

  const header = (
    <div className="d-flex justify-content-between">
      <Btn
        type="button"
        icon="bi bi-funnel"
        label="Clear"
        className="btn btn-outline-dark d-flex align-items-center"
        outlined
        onClick={() => initFilters()}
      />
      {!!userRole && userRole === "Admin" && (
        <>
          <div className="d-flex align-items-center">
            <div className="me-2">Enable Row Edit</div>
            <InputSwitch
              id="value"
              name="value"
              checked={enableRowEdit}
              onChange={(e) => {
                setEnableRowEdit(e.value);
              }}
            />
          </div>
          {enableRowEdit ? (
            <Btn
              type="button"
              icon="bi bi-pencil-square"
              label="Edit"
              className="btn btn-outline-dark"
              outlined
              disabled={
                !!selectedProjectDetails && selectedProjectDetails.length > 0
                  ? false
                  : true
              }
              onClick={() => {
                setShowBulkEditableGrid(true);
              }}
            />
          ) : (
            // !isProjectPublished &&
            <Btn
              type="button"
              icon="bi bi-save2"
              label="Save"
              className="btn btn-outline-dark"
              outlined
              // disabled={isLabelShow && isChangesSaved}
              onClick={async () => {
                const res = await updateProjectDetails(projectReportArr);
                if (res.payload.result > 0) {
                  setIsLabelShow(true);
                  //setEnableRowEdit(false);
                  Swal.fire({
                    title: res?.payload?.message,
                    confirmButtonColor: "#000000",
                    confirmButtonText: "OK",
                    customClass: {
                      confirmButton: "confirm-button-swal",
                    },
                  });
                  setIsChangesSaved(true);
                  //setShowBulkEditableGrid(false);
                }
              }}
            />
          )}
          {/* <Button
          type="button"
          icon="bi bi-save2"
          label="Save"
          className="btn btn-outline-dark"
          outlined
          disabled={!isLabelShow}
          onClick={async () => {
            const res = await updateProjectReports(projectReportArr);
            if (res.payload.result > 0) {
              setIsLabelShow(false);
              Swal.fire(res?.payload?.message);
            }
          }}
        /> */}
        </>
      )}
      {!!userRole && (userRole === "Client" || userRole === "ClientUser") && (
        <>
          <div className="d-flex align-items-center">
            <div className="me-2">Show Change Request</div>
            <InputSwitch
              id="value"
              name="value"
              checked={enableRequestRowEdit}
              onChange={(e) => {
                setEnableRequestRowEdit(e.value);
              }}
            />
          </div>
          {/* {!!enableRequestRowEdit &&
          trimprojectReportArr.length > 0 &&
          trimprojectReportArr.some((item) => item.isApproved) ? (
            <>
              <Btn
                type="button"
                icon="bi bi-save2"
                label="Save"
                className="btn btn-outline-dark"
                outlined
                onClick={async () => {
                  const res = await updateProjectDetailsForChangeRequest(
                    projectReportArr
                  );
                  if (res.payload.result > 0) {
                    setEnableRequestRowEdit(false);
                    Swal.fire({
                      title: res?.payload?.message,
                      confirmButtonColor: "#000000",
                      confirmButtonText: "OK",
                      customClass: {
                        confirmButton: "confirm-button-swal",
                      },
                    });
                  }
                }}
              />
            </>
          ) : (
            <>
              <div></div>
            </>
          )} */}
        </>
      )}
    </div>
  );
  const headerRowEditProjectDetails = (
    <div className="d-flex justify-content-end">
      <Btn
        type="button"
        icon="bi bi-save2"
        label="Save"
        className="btn btn-outline-dark"
        outlined
        onClick={async () => {
          const res = await updateProjectDetails(selectedProjectDetails);
          if (res.payload.result > 0) {
            setIsLabelShow(true);
            Swal.fire({
              title: res?.payload?.message,
              confirmButtonColor: "#000000",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "confirm-button-swal",
              },
            });
            setIsChangesSaved(true);
            setEnableRowEdit(false);
            setShowBulkEditableGrid(false);
            setSelectedProjectDetails(null);
            getProjectReportDataList(
              projectId,
              loginUserId,
              enableRequestRowEdit,
              page,
              perPage,
              sortColumn,
              sortColumnDir,
              searchText,
              filters
            );
          }
        }}
      />
    </div>
  );

  const ActionRow = (row) => {
    return (
      <>
        <div className={`actions`}>
          <OverlayTrigger placement="top" overlay={ViewHistoryTooltip}>
            <div
              className="actions"
              onClick={() => {
                setProjectDetailsId(row.projectDetailsId);
                setShowHistoryModal(true);
                setSearchHistoryText(null);
              }}
            >
              <Link className="btn btn-sm bg-info-subtle">
                <i className="bi bi-clock-history"></i>
              </Link>
            </div>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={DeleteProjectDetailsTooltip}>
            <button
              className="btn btn-sm bg-danger-subtle ms-2"
              onClick={() =>
                deleteProjectDetailsData(row.projectDetailsId, loginUserId)
              }
            >
              <i className="bi bi-trash"></i>
            </button>
          </OverlayTrigger>
        </div>
      </>
    );
  };

  // const getLookUpColumnValue = async (e, columnData) => {
  //   return new Promise(async (resolve, reject) => {
  //     const response = await dispatch(
  //       getLookupColumnValue({
  //         rawDescription:
  //           e === "rawDescription" ? columnData.rawDescription : null,
  //         description: e === "rawDescription" ? null : columnData.description,
  //         length: columnData.length,
  //         depth: columnData.depth,
  //         height: columnData.height,
  //         primaryColour: columnData.rawPrimaryColour,
  //         secondaryColour: columnData.rawSecondaryColour,
  //         condition: columnData.condition,
  //         makeModel: columnData.makeModel,
  //         qrCode: columnData.qrCode
  //       })
  //     );
  //     resolve(response);
  //   });
  // };

  const updateProjectDetails = async (projectDetailsList) => {
    return new Promise(async (resolve, reject) => {
      const transformedData = projectDetailsList.map((data, index) => {
        return {
          ...data,
          modifiedBy: loginUserId,
          modifiedDateTime: new Date(),
        };
      });
      // const res = await dispatch(
      //   addProjectDetails({
      //     projectDetailsList: transformedData,
      //     isUpdated: true,
      //   })
      // );
      const res = await dispatch(
        updateProjectReport({
          projectDetailsList: transformedData,
          isUpdated: true,
        })
      );
      resolve(res);
    });
  };

  const updateProjectDetailsForChangeRequest = async (projectDetailsList) => {
    return new Promise(async (resolve, reject) => {
      const transformedData = projectDetailsList.map((data, index) => {
        return {
          ...data,
          modifiedBy: loginUserId,
          modifiedDateTime: new Date(),
        };
      });
      const res = await dispatch(
        updateProjectReportForChangeRequest({
          requestParam: {
            projectReportModels: transformedData,
            isChangeRequest: true,
          },
        })
      );
      resolve(res);
    });
  };

  const saveRequestForChangeDetails = async (requestDetails) => {
    return new Promise(async (resolve, reject) => {
      const res = await dispatch(
        saveRequestForChange({
          requestParam: {
            ProjectId: requestDetails.projectId,
            ProjectReportRowId: requestDetails.id,
            Remarks: requestDetails.remarks,
            RemarksCommentedBy: requestDetails.userId,
          },
          isUpdated: true,
        })
      );
      resolve(res);
    });
  };

  const viewImage = (row) => {
    return (
      <>
        {!!row.photographName && (
          <div className={`actions`}>
            <OverlayTrigger placement="top" overlay={ViewImageTooltip}>
              <button
                onClick={() => {
                  if (!!row.photographName) {
                    // Split photographName into an array and map it to full URLs
                    const imagesArray = row.photographName
                      .split(",")
                      .map((name, index) => ({
                        url: `${IMAGE_BASE_URL}/${row.projectId}/${name}`,
                        title: `${name}`, // Set the title to the photograph name
                      }));
                    setModalImage(imagesArray);
                    // setModalImage(
                    //   `${IMAGE_BASE_URL}/${row.projectId}/${row.photographName}`
                    // );
                    setModalImageName(row.photographName);
                    setIsOpenModalImage(true);
                  } else {
                    Swal.fire({
                      title: "No image found!",
                      confirmButtonColor: "#000000",
                      confirmButtonText: "OK",
                      customClass: {
                        confirmButton: "confirm-button-swal",
                      },
                    });
                  }
                }}
                className="btn btn-sm bg-warning-subtle me-2"
              >
                <i className="bi bi-eye" />
              </button>
            </OverlayTrigger>
          </div>
        )}
      </>
    );
  };

  const handleLightBoxClosed = () => {
    setModalImage(null);
    setIsOpenModalImage(false);
  };

  const requestForChange = (row) => {
    const isCommented =
      !!row.remarksCommentedBy && row.remarksCommentedBy !== null;
    //&& userId === row.remarksCommentedBy;
    return (
      <>
        <div
          className="actions"
          style={{ cursor: isCommented ? "no-drop" : "pointer" }}
        >
          <OverlayTrigger placement="top" overlay={ReqForChangeTooltip}>
            <a
              className={`btn btn-sm bg-warning-subtle me-2 ${
                isCommented ? "disabled" : ""
              }`}
            >
              <i
                className="bi bi-pencil-square"
                onClick={() => {
                  setId(row.id);
                  setIsReqForChange(true);
                  openModal(true);
                }}
              />
            </a>
          </OverlayTrigger>
        </div>
      </>
    );
  };

  const ViewHistoryActionRow = (row) => {
    return (
      <>
        <div className={`actions`}>
          <OverlayTrigger placement="top" overlay={ViewHistoryTooltip}>
            <div
              className="actions"
              onClick={() => {
                setProjectReportId(row.id);
                setShowHistoryModal(true);
                setSearchHistoryText(null);
              }}
            >
              <Link className="btn btn-sm bg-info-subtle">
                <i className="bi bi-clock-history"></i>
              </Link>
            </div>
          </OverlayTrigger>
        </div>
      </>
    );
  };

  const handleProjectDetailsHistorySort = (column, sortDirection) => {
    if (Object.keys(column).length !== 0 && !!sortDirection) {
      setHistorySortColumn(column.sortField);
      setHistorySortColumnDir(sortDirection);
      if (showHistoryModal) {
        getAllProjectReportHistoryList(
          1,
          historyPerPage,
          projectReportId,
          column.sortField,
          sortDirection,
          searchHistoryText
        );
      }
    }
  };

  const handleProjectDetailsHistoryPageChange = (page) => {
    setHistoryPage(page);
    if (showHistoryModal) {
      getAllProjectReportHistoryList(
        page,
        historyPerPage,
        projectReportId,
        historySortColumn,
        historySortColumnDir,
        searchHistoryText
      );
    }
  };

  const handleProjectDetailsHistoryPerRowsChange = (newPerPage, page) => {
    setHistoryPerPage(newPerPage);
    setHistoryPage(page);
    if (showHistoryModal) {
      getAllProjectReportHistoryList(
        page,
        newPerPage,
        projectReportId,
        historySortColumn,
        historySortColumnDir,
        searchHistoryText
      );
    }
  };

  useEffect(() => {
    if (showHistoryModal) {
      setHistorySortColumn(null);
      setHistorySortColumnDir(null);
      getAllProjectReportHistoryList(
        historyPage,
        historyPerPage,
        projectReportId,
        null,
        null,
        searchHistoryText
      );
    }
  }, [showHistoryModal, searchHistoryText]);

  const projectReportsHistoryColumns = [
    {
      name: "Modified By",
      sortable: true,
      sortField: "CreatedBy",
      selector: (row) => row.createdBy,
    },
    {
      name: "Date Time",
      sortable: true,
      sortField: "CreatedDateTime",
      selector: (row) =>
        moment(row.createdDateTime).format("DD MMM YYYY hh:mm A"),
    },
  ];

  const ExpandedComponent = (data) => {
    return (
      <>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Column Name</th>
              <th scope="col">Updated Value</th>
            </tr>
          </thead>
          <tbody>
            {!!data.data.level && (
              <tr>
                <td>Level</td>
                <td>{data.data.level}</td>
              </tr>
            )}
            {!!data.data.roomNumber && (
              <tr>
                <td>Room Number</td>
                <td>{data.data.roomNumber}</td>
              </tr>
            )}
            {!!data.data.roomName && (
              <tr>
                <td>Room Name</td>
                <td>{data.data.roomName}</td>
              </tr>
            )}
            {!!data.data.description && (
              <tr>
                <td>Description</td>
                <td>{data.data.description}</td>
              </tr>
            )}
            {!!data.data.quantity && (
              <tr>
                <td>Quantity</td>
                <td>{data.data.quantity}</td>
              </tr>
            )}
            {!!data.data.itemType && (
              <tr>
                <td>ItemType</td>
                <td>{data.data.itemType}</td>
              </tr>
            )}
            {!!data.data.dfeCode && (
              <tr>
                <td>DFE Code</td>
                <td>{data.data.dfeCode}</td>
              </tr>
            )}
            {!!data.data.condition && (
              <tr>
                <td>Condition</td>
                <td>{data.data.condition}</td>
              </tr>
            )}
            {!!data.data.lifeExpectancy && (
              <tr>
                <td>Life Expectancy</td>
                <td>{data.data.lifeExpectancy}</td>
              </tr>
            )}
            {!!data.data.makeModel && (
              <tr>
                <td>Make Model</td>
                <td>{data.data.makeModel}</td>
              </tr>
            )}
            {!!data.data.length && (
              <tr>
                <td>Length</td>
                <td>{data.data.length}</td>
              </tr>
            )}
            {!!data.data.depth && (
              <tr>
                <td>Depth</td>
                <td>{data.data.depth}</td>
              </tr>
            )}
            {!!data.data.height && (
              <tr>
                <td>Height</td>
                <td>{data.data.height}</td>
              </tr>
            )}
            {!!data.data.primaryColour && (
              <tr>
                <td>Primary Colour</td>
                <td>{data.data.primaryColour}</td>
              </tr>
            )}
            {!!data.data.secondaryColour && (
              <tr>
                <td>Secondary Colour</td>
                <td>{data.data.secondaryColour}</td>
              </tr>
            )}
            {!!data.data.dfeStandard && (
              <tr>
                <td>DFE Standard</td>
                <td>{data.data.dfeStandard}</td>
              </tr>
            )}
            {!!data.data.comments && (
              <tr>
                <td>Comments</td>
                <td>{data.data.comments}</td>
              </tr>
            )}
            {!!data.data.rawColour && (
              <tr>
                <td>Raw Colour</td>
                <td>{data.data.rawColour}</td>
              </tr>
            )}
            {!!data.data.cost && (
              <tr>
                <td>Cost</td>
                <td>{data.data.cost}</td>
              </tr>
            )}
            {!!data.data.carbonSavingKG && (
              <tr>
                <td>CarbonSavingKG</td>
                <td>{data.data.carbonSavingKG}</td>
              </tr>
            )}
            {!!data.data.newRoomName && (
              <tr>
                <td>New Room Name</td>
                <td>{data.data.descriptionH}</td>
              </tr>
            )}
            {!!data.data.newRoomNumber && (
              <tr>
                <td>New Room Number</td>
                <td>{data.data.newRoomNumber}</td>
              </tr>
            )}
            {!!data.data.remarksComment && (
              <tr>
                <td>Remarks</td>
                <td>{data.data.remarksComment}</td>
              </tr>
            )}
            {!!data.data.remarksCommentedBy && (
              <tr>
                <td>Remarks CommentedBy</td>
                <td>{data.data.remarksCommentedBy}</td>
              </tr>
            )}
            {!!data.data.remarksDateTime && (
              <tr>
                <td>Remarks DateTime</td>
                <td>{data.data.remarksDateTime}</td>
              </tr>
            )}
            {!!data.data.approvalsComment && (
              <tr>
                <td>Approval Comment</td>
                <td>{data.data.approvalsComment}</td>
              </tr>
            )}
            {!!data.data.approvalCommentedBy && (
              <tr>
                <td>Approval CommentedBy</td>
                <td>{data.data.approvalCommentedBy}</td>
              </tr>
            )}
          </tbody>
        </table>
      </>
    );
  };

  const getAllProjectReportHistoryList = (
    page_number,
    page_size,
    project_report_id,
    sort_column_name,
    sort_column_dir,
    search_text
  ) => {
    const data = {
      page_number: page_number,
      page_size: page_size,
      projectReportId: project_report_id,
      sort_column_name: !!sort_column_name ? sort_column_name : null,
      sort_column_dir: !!sort_column_dir ? sort_column_dir : null,
      search_text: !!search_text ? search_text : null,
    };
    dispatch(projectReportHistoryList(data));
  };

  return (
    <>
      {/* {(loading || loadingProjectReport) && <Loader />} */}
      {/* {(loadingDash || loadingProjectReportDash) && <Loader />} */}
      <div className="mainContent px-4 pt-3">
        <div className="page-header mb-3">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <div className="page-sub-header">
                <div>
                  <div
                    className={`dashboard-tab ${
                      selectedLink === "dashboard"
                        ? "page-title-selected"
                        : "page-title-deselected"
                    }`}
                    onClick={() => handleLinkClick("dashboard")}
                  >
                    Dashboard
                  </div>
                  <div
                    className={`${
                      selectedLink === "projectReport"
                        ? "page-title-selected"
                        : "page-title-deselected"
                    }`}
                    onClick={() => handleLinkClick("projectReport")}
                  >
                    Detailed Legacy Survey
                  </div>
                </div>
                {/* <h3 className={`${selectedLink === 'projectReport' ? 'page-title-selected' : 'page-title-deselected'}`} onClick={() => handleLinkClick('projectReport')}>
                  Detailed Legacy Survey
                </h3>
                <h3 className={`dashboard-tab ${selectedLink === 'dashboard' ? 'page-title-selected' : 'page-title-deselected'}`} onClick={() => handleLinkClick('dashboard')}>
                  Dashboard
                </h3> */}
                <div className="page-title-right d-flex">
                  <div className="symbol-project bg-light"></div>
                  <Link
                    className="btn btn-outline-dark ms-2 d-flex"
                    to={`/projectList/${clientId}`}
                  >
                    <i className="bi bi-arrow-left me-2"></i> Back
                  </Link>

                  {/* {!isLabelShow && !!userRole && userRole === "Admin" && (
                    <a
                      className="btn btn-outline-dark ms-2 d-flex"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      onClick={() => {
                        setIsLabelShow(!isLabelShow);
                      }}
                    >
                      <i className="bi bi-pencil me-2"></i> <span>Edit</span>
                    </a>
                  )}

                  {isLabelShow && (
                    <a
                      className="btn btn-outline-dark ms-2 d-flex"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      onClick={async () => {
                        const res = await updateProjectReports(
                          projectReportArr
                        );
                        if (res.payload.result > 0) {
                          setIsLabelShow(false);
                          Swal.fire(res?.payload?.message);
                        }
                      }}
                    >
                      <i className="bi bi-save me-2"></i> <span>Save</span>
                    </a>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {selectedLink === "projectReport" ? (
          <div className="row mt-4">
            <div className="col-md-12">
              {/* <div>
            <div onClick={toggleAccordion}>
            {isOpen ? (
                <div><i className="bi bi-caret-right-fill" style={{ fontSize: '1.2em' }}></i></div>
              ) : (
                <div><i className="bi bi-caret-down-fill" style={{ fontSize: '1.2em' }}></i></div>
              )}
            </div>
          </div> */}
              {/* <div className="position-relative">
            <div onClick={toggleAccordion} className="icon-top-right">
              {isOpen ? (
                <i className="bi bi-caret-up-fill" style={{ fontSize: '1.2em' }}></i>
              ) : (
                <i className="bi bi-caret-down-fill" style={{ fontSize: '1.2em' }}></i>
              )}
            </div>
          </div> */}
              {/* {isOpen && ( */}
              <div className="card shadow-sm border-0">
                <div className="card-body p-9">
                  {/* {projectReportArr?.length > 0 && (
                  <div className="d-flex gap-3 p-3"></div>
                )} */}
                  {/* Three tables start */}
                  {/* <div className="d-flex justify-content-between align-items-center mb-3"> */}
                  {/* table 1 Detailed Legacy Survey*/}
                  <div className="row">
                    <div className="col-lg-4 projectDetailReportWrap">
                      <div className="project-title-company-name pt-0 fs-5 mb-2">
                        {projectName}
                        {/* Project Reports Detail */}
                      </div>
                      {/* <div className="reportInfoList">
                      Project Name:{" "}
                      <span className="fw-bold">{projectName}</span>
                    </div> */}
                      {isOpen && (
                        <>
                          <div className="reportInfoList">
                            Date:{" "}
                            <span className="fw-bold">
                              {!!reportRecordsCounts &&
                                moment(
                                  reportRecordsCounts.projectCreationDate
                                ).format("DD/MM/YYYY")}
                            </span>
                          </div>
                          <div className="reportInfoList">
                            FF&E Surveyors:{" "}
                            <span className="fw-bold">{ffeSurveyors}</span>
                          </div>
                          <div className="reportInfoList">
                            Author: <span className="fw-bold">{author}</span>
                          </div>
                          <div className="reportInfoList">
                            Suitable to re-purpose:{" "}
                            <span className="fw-bold">
                              {suitablePerDashboard} %
                            </span>
                          </div>
                          {checkVisibility("CarbonSavingKG") && (
                            <div className="reportInfoList">
                              Potential Carbon Reduction:{" "}
                              <span
                                className="fw-bold"
                                style={{ color: "#40c540" }}
                              >
                                {!!reportRecordsCounts
                                  ? reportRecordsCounts.carbonSavingKG
                                  : 0}
                                kg of Co2
                              </span>
                            </div>
                          )}
                          {checkVisibility("DFEStandard") && (
                            <>
                              <div className="reportInfoList">
                                DfE Standard Size Count:{" "}
                                <span className="fw-bold">
                                  <span className="badge text-bg-secondary">
                                    {!!reportRecordsCounts
                                      ? reportRecordsCounts.standardCount
                                      : 0}
                                  </span>
                                  <span
                                    className="ms-1"
                                    style={{
                                      color:
                                        !!reportRecordsCounts &&
                                        reportRecordsCounts.standardCount >
                                          reportRecordsCounts.abnormalCount
                                          ? "#40c540"
                                          : "#e31d1d",
                                    }}
                                  >
                                    {!!reportRecordsCounts
                                      ? reportRecordsCounts.standardPer
                                      : 0}
                                    %
                                  </span>
                                </span>
                              </div>
                              <div className="reportInfoList">
                                DfE Abnormal Size Count:{" "}
                                <span className="fw-bold">
                                  <span className="badge text-bg-secondary">
                                    {!!reportRecordsCounts
                                      ? reportRecordsCounts.abnormalCount
                                      : 0}
                                  </span>
                                  <span
                                    className="ms-1"
                                    style={{
                                      color:
                                        !!reportRecordsCounts &&
                                        reportRecordsCounts.abnormalCount >
                                          reportRecordsCounts.standardCount
                                          ? "#40c540"
                                          : "#e31d1d",
                                    }}
                                  >
                                    {!!reportRecordsCounts
                                      ? reportRecordsCounts.abnormalPer
                                      : 0}
                                    %
                                  </span>
                                </span>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                    <div className="col-lg-7">
                      {checkVisibility("Condition") && (
                        <div className="project-title-company-name pt-0 fs-5 ps-2">
                          Condition Breakdown
                        </div>
                      )}
                      <div onClick={toggleAccordion} className="icon-top-right">
                        {isOpen ? (
                          <i
                            className="bi bi-caret-up-fill"
                            style={{ fontSize: "1.2em" }}
                          ></i>
                        ) : (
                          <i
                            className="bi bi-caret-down-fill"
                            style={{ fontSize: "1.2em" }}
                          ></i>
                        )}
                      </div>
                      {checkVisibility("Condition") && isOpen && (
                        <div className="table-font-12">
                          <DT
                            value={filteredconditionBreakdownListNEW}
                            rowClassName={rowClass}
                            size="small"
                            className="no-header-datatable custom-dt-class"

                            // tableStyle={{ minWidth: "50rem" }}
                          >
                            <Column field="condition"></Column>
                            {checkVisibility("LifeExpectancy") && (
                              <Column field="lifeExpectancy"></Column>
                            )}
                            <Column field="count"></Column>
                            <Column
                              body={(row) =>
                                !!row.condition ? `${row.percentage}%` : null
                              }
                            ></Column>
                          </DT>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* )} */}
            </div>
            <div className="col-md-12 mt-3">
              <div className="card shadow-sm border-0 reportTable">
                <div className="card-body p-9 table-font-12">
                  {showBulkEditableGrid ? (
                    <DT
                      value={selectedProjectDetails}
                      size="small"
                      rowClassName={rowClass}
                      dataKey="projectDetailsId"
                      header={headerRowEditProjectDetails}
                      editMode={"cell"}
                      // editMode={!isProjectPublished && "cell"}
                      row
                      // emptyMessage={loading ? 'Loading' : 'No data found.'}
                    >
                      {/* <Column header="Action" body={ActionRow} /> */}
                      {/* <Column
                      header="Legacy Code"
                      field="legacyCode"
                      onCellEditComplete={(data) =>
                        onEditableRowCellEditComplete(data)
                      }
                      editor={(options) => inputRowEditComponent(options)}
                      
                    /> */}
                      {/* <Column
                        header="QR Code"
                        field="qrCode"
                        body={qrCodeIdTemplate}
                        // onCellEditComplete={(data) =>
                        //   onEditableRowCellEditComplete(data)
                        // }
                        // editor={(options) => inputRowEditComponent(options)}
                        bodyClassName="text-center"
                      /> */}
                      <Column
                        header={
                          findAlternateName("QrCode")
                            ? findAlternateName("QrCode")
                            : "QrCode"
                        }
                        hidden={!checkVisibility("qrCode")}
                        field="qrCode"
                        body={qrCodeIdTemplate}
                        onCellEditComplete={(data) =>
                          onEditableRowCellEditComplete(data)
                        }
                        editor={(options) => inputRowEditComponent(options)}
                        bodyClassName="text-center"
                      />
                      {/* <Column
                      header="QR Code"
                      field="qrCode"
                      body={qrCodeIdTemplate}
                      sortable
                      sortField="pd.QRCode"
                      style={{ backgroundColor: "#a6a6a6" }}
                      
                    /> */}
                      {/* cell: (row, index) => {
                    const qrCodeId = row.qrCode.split('/').pop();
                    return qrCodeId;
                  }, */}
                      <Column
                        header={
                          findAlternateName("LegacyCode")
                            ? findAlternateName("LegacyCode")
                            : "legacyCode"
                        }
                        hidden={!checkVisibility("LegacyCode")}
                        field="legacyCode"
                        onCellEditComplete={(data) =>
                          onEditableRowCellEditComplete(data)
                        }
                        editor={(options) => inputRowEditComponent(options)}
                        bodyClassName="text-center"
                      />
                      <Column
                        header={
                          findAlternateName("Level")
                            ? findAlternateName("Level")
                            : "Level"
                        }
                        hidden={!checkVisibility("Level")}
                        field="level"
                        onCellEditComplete={(data) =>
                          onEditableRowCellEditComplete(data)
                        }
                        editor={(options) => inputRowEditComponent(options)}
                        bodyClassName="text-center"
                      />
                      <Column
                        header={
                          findAlternateName("RoomNumber")
                            ? findAlternateName("RoomNumber")
                            : "Room Number"
                        }
                        hidden={!checkVisibility("RoomNumber")}
                        editor={(options) => inputRowEditComponent(options)}
                        onCellEditComplete={(data) =>
                          onEditableRowCellEditComplete(data)
                        }
                        bodyClassName="text-center"
                      />
                      <Column
                        header={
                          findAlternateName("RoomName")
                            ? findAlternateName("RoomName")
                            : "Room Name"
                        }
                        hidden={!checkVisibility("RoomName")}
                        onCellEditComplete={(data) =>
                          onEditableRowCellEditComplete(data)
                        }
                        editor={(options) => inputRowEditComponent(options)}
                        field="roomName"
                        bodyClassName="text-center"
                      />
                      <Column
                        header={
                          findAlternateName("Description")
                            ? findAlternateName("Description")
                            : "Description"
                        }
                        hidden={!checkVisibility("Description")}
                        onCellEditComplete={(data) =>
                          onEditableRowCellEditComplete(data)
                        }
                        editor={(options) => inputRowEditComponent(options)}
                        field="description"
                        style={{ minWidth: "14rem" }}
                        bodyClassName="text-center"
                      />
                      <Column
                        header={
                          findAlternateName("Quantity")
                            ? findAlternateName("Quantity")
                            : "Quantity"
                        }
                        hidden={!checkVisibility("Quantity")}
                        onCellEditComplete={(data) =>
                          onEditableRowCellEditComplete(data)
                        }
                        editor={(options) => inputRowEditComponent(options)}
                        field="quantity"
                        bodyClassName="text-center"
                      />

                      <Column
                        header={
                          findAlternateName("Condition")
                            ? findAlternateName("Condition")
                            : "Condition"
                        }
                        hidden={!checkVisibility("Condition")}
                        onCellEditComplete={(data) =>
                          onEditableRowCellEditComplete(data)
                        }
                        editor={(options) => inputRowEditComponent(options)}
                        field="condition"
                        style={{ minWidth: "14rem" }}
                        bodyClassName="text-center"
                      />
                      <Column
                        header={
                          findAlternateName("LifeExpectancy")
                            ? findAlternateName("LifeExpectancy")
                            : "Life Expectancy"
                        }
                        hidden={!checkVisibility("LifeExpectancy")}
                        onCellEditComplete={(data) =>
                          onEditableRowCellEditComplete(data)
                        }
                        editor={(options) => inputRowEditComponent(options)}
                        field="lifeExpectancy"
                        bodyClassName="text-center"
                      />
                      <Column
                        header={
                          findAlternateName("MakeModel")
                            ? findAlternateName("MakeModel")
                            : "Make/Model"
                        }
                        hidden={!checkVisibility("MakeModel")}
                        onCellEditComplete={(data) =>
                          onEditableRowCellEditComplete(data)
                        }
                        editor={(options) => inputRowEditComponent(options)}
                        field="makeModel"
                        bodyClassName="text-center"
                      />
                      <Column
                        header={
                          findAlternateName("Length")
                            ? findAlternateName("Length")
                            : "Length"
                        }
                        hidden={!checkVisibility("Length")}
                        onCellEditComplete={(data) =>
                          onEditableRowCellEditComplete(data)
                        }
                        editor={(options) => inputRowEditComponent(options)}
                        field="length"
                        bodyClassName="text-center"
                      />
                      <Column
                        header={
                          findAlternateName("Depth")
                            ? findAlternateName("Depth")
                            : "Depth"
                        }
                        hidden={!checkVisibility("Depth")}
                        onCellEditComplete={(data) =>
                          onEditableRowCellEditComplete(data)
                        }
                        editor={(options) => inputRowEditComponent(options)}
                        field="depth"
                        bodyClassName="text-center"
                      />
                      <Column
                        header={
                          findAlternateName("Height")
                            ? findAlternateName("Height")
                            : "Height"
                        }
                        hidden={!checkVisibility("Height")}
                        onCellEditComplete={(data) =>
                          onEditableRowCellEditComplete(data)
                        }
                        editor={(options) => inputRowEditComponent(options)}
                        field="height"
                        bodyClassName="text-center"
                      />
                      <Column
                        header={
                          findAlternateName("PrimaryColour")
                            ? findAlternateName("PrimaryColour")
                            : "Primary Colour"
                        }
                        hidden={!checkVisibility("PrimaryColour")}
                        onCellEditComplete={(data) =>
                          onEditableRowCellEditComplete(data)
                        }
                        editor={(options) => inputRowEditComponent(options)}
                        field="primaryColour"
                        bodyClassName="text-center"
                      />
                      <Column
                        header={
                          findAlternateName("SecondaryColour")
                            ? findAlternateName("SecondaryColour")
                            : "Secondary Colour"
                        }
                        hidden={!checkVisibility("SecondaryColour")}
                        onCellEditComplete={(data) =>
                          onEditableRowCellEditComplete(data)
                        }
                        editor={(options) => inputRowEditComponent(options)}
                        field="secondaryColour"
                        bodyClassName="text-center"
                      />
                      <Column
                        header={
                          findAlternateName("DFEStandard")
                            ? findAlternateName("DFEStandard")
                            : "DFE Standard"
                        }
                        hidden={!checkVisibility("DFEStandard")}
                        onCellEditComplete={(data) =>
                          onEditableRowCellEditComplete(data)
                        }
                        editor={(options) => inputRowEditComponent(options)}
                        field="abnormal"
                        bodyClassName="text-center"
                      />
                      <Column
                        header={
                          findAlternateName("Comments")
                            ? findAlternateName("Comments")
                            : "Comments"
                        }
                        hidden={!checkVisibility("Comments")}
                        onCellEditComplete={(data) =>
                          onEditableRowCellEditComplete(data)
                        }
                        editor={(options) => inputRowEditComponent(options)}
                        field="comments"
                        style={{ minWidth: "14rem" }}
                        bodyClassName="text-center"
                      />
                      {/* <Column
                      header="Photograph"
                      onCellEditComplete={(data) =>
                        onEditableRowCellEditComplete(data)
                      }
                      editor={(options) => inputRowEditComponent(options)}
                      field="photograph"
                    /> */}
                      <Column
                        header={
                          findAlternateName("Cost")
                            ? findAlternateName("Cost")
                            : "Cost"
                        }
                        hidden={!checkVisibility("Cost")}
                        onCellEditComplete={(data) =>
                          onEditableRowCellEditComplete(data)
                        }
                        editor={(options) => inputRowEditComponent(options)}
                        field="cost"
                        bodyClassName="text-center"
                      />
                      <Column
                        header={
                          findAlternateName("CarbonSavingKG")
                            ? findAlternateName("CarbonSavingKG")
                            : "Carbon Saving KG"
                        }
                        hidden={!checkVisibility("CarbonSavingKG")}
                        onCellEditComplete={(data) =>
                          onEditableRowCellEditComplete(data)
                        }
                        editor={(options) => inputRowEditComponent(options)}
                        field="carbonSavingKG"
                        bodyClassName="text-center"
                      />
                      {/* <Column
                      header="QRCode"
                      onCellEditComplete={(data) =>
                        onEditableRowCellEditComplete(data)
                      }
                      editor={(options) => inputRowEditComponent(options)}
                      field="qrCode"
                    /> */}
                      <Column
                        header={
                          findAlternateName("NewRoomName")
                            ? findAlternateName("NewRoomName")
                            : "New Room Name"
                        }
                        hidden={!checkVisibility("NewRoomName")}
                        onCellEditComplete={(data) =>
                          onEditableRowCellEditComplete(data)
                        }
                        editor={(options) => inputRowEditComponent(options)}
                        field="newRoomName"
                        bodyClassName="text-center"
                      />
                      <Column
                        header={
                          findAlternateName("NewRoomNumber")
                            ? findAlternateName("NewRoomNumber")
                            : "New Room Number"
                        }
                        hidden={!checkVisibility("NewRoomNumber")}
                        onCellEditComplete={(data) =>
                          onEditableRowCellEditComplete(data)
                        }
                        editor={(options) => inputRowEditComponent(options)}
                        field="newRoomNumber"
                        bodyClassName="text-center"
                      />
                    </DT>
                  ) : (
                    <>
                      <DT
                        value={trimprojectReportArr}
                        // value={projectReportArr.map(item => {
                        //   const trimmedItem = {};
                        //   for (const key in item) {
                        //     if (Object.prototype.hasOwnProperty.call(item, key)) {
                        //       trimmedItem[key] = typeof item[key] === 'string' ? item[key].trim() : item[key];
                        //     }
                        //   }
                        //   return trimmedItem;
                        // })}
                        rows={25}
                        size="small"
                        // dataKey="qrCode"
                        // dataKey="legacyCode"
                        dataKey="id"
                        rowClassName={rowClass}
                        header={header}
                        filters={filters}
                        selection={selectedProjectDetails}
                        selectionMode={enableRowEdit ? "multiple" : null}
                        onSelectionChange={(e) => {
                          setSelectedProjectDetails(e.value);
                        }}
                        className="text-center"
                        loading={loading}
                        globalFilterFields={["description"]}
                        sortField={sortColumn}
                        editMode={
                          !!userRole &&
                          (userRole === "Admin" || enableRequestRowEdit)
                            ? "cell"
                            : "row"
                        }
                        sortOrder={sortColumnDir === "asc" ? 1 : -1}
                        onSort={(data) => {
                          setSortColumn(data.sortField);
                          setSortColumnDir(
                            data.sortOrder === 1 ? "asc" : "desc"
                          );
                          handleSort(
                            data.sortField,
                            data.sortOrder === 1 ? "asc" : "desc"
                          );
                        }}
                        // emptyMessage={loading ? 'Loading' : 'No data found.'}
                        onFilter={(e) => {
                          setFilters(e.filters);
                          getProjectReportDataList(
                            projectId,
                            loginUserId,
                            enableRequestRowEdit,
                            page,
                            perPage,
                            sortColumn,
                            sortColumnDir,
                            searchText,
                            e.filters
                          );
                        }}
                      >
                        {enableRowEdit && (
                          <Column
                            selectionMode="multiple"
                            headerStyle={{ width: "3rem" }}
                          />
                        )}
                        {/* <Column
                    header="No"
                    body={(data, column) => column.rowIndex + 1}
                  /> */}

                        {/* <Column
                      header="Legacy Code"
                      filterField="legacyCode"
                      sortable
                      field="legacyCode"
                      bodyClassName="text-center"

                      // header="Surveyor Name"
                      // filterField="surveyorName"
                      // // style={{ width: '600px' }}
                      // sortable
                      // headerStyle={{ width: "5rem" }}
                      // filterMenuStyle={{ width: "14rem" }}
                      // field="surveyorName"
                      // onCellEditComplete={(data) => onCellEditComplete(data)}
                      // editor={(options) => inputComponent(options)}
                      // filter
                      // showFilterMatchModes={false}
                      // filterElement={(option) =>
                      //   rowFilterTemplate(option, surveyorNameFilter)
                      // }
                    /> */}
                        {/* {columns.map((column, index) => (
                      <Column
                        key={index}
                        field={column.field}
                        header={column.header}
                        style={{ display: column.visible ? 'table-cell' : 'none' }}
                      />
                    ))} */}
                        <Column
                          hidden={
                            userRole === "Client" || userRole === "ClientUser"
                          }
                          header="Action"
                          body={ViewHistoryActionRow}
                          bodyClassName="text-center"
                        />
                        <Column
                          header={
                            findAlternateName("QRCode")
                              ? findAlternateName("QRCode")
                              : "QR Code"
                          }
                          hidden={!checkVisibility("QRCode")}
                          filterField="qrCode"
                          sortable
                          field="qrCode"
                          // body={qrCodeIdTemplate}
                          // bodyClassName="text-center"
                          // headerStyle={{ width: "5rem", textAlign: "center" }}
                          filter
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          //editor={(options) => inputComponent(options)}
                          showFilterMatchModes={false}
                          filterElement={(option) =>
                            rowFilterTemplate(
                              option,
                              qrCodeFilter,
                              findAlternateName("QRCode")
                                ? findAlternateName("QRCode")
                                : "QR Code"
                            )
                          }
                        />
                        <Column
                          header={
                            findAlternateName("LegacyCode")
                              ? findAlternateName("LegacyCode")
                              : "LegacyCode"
                          }
                          hidden={!checkVisibility("LegacyCode")}
                          filterField="legacyCode"
                          sortable
                          filterMenuStyle={{ width: "14rem" }}
                          field="legacyCode"
                          filter
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          // editor={(options) => inputComponent(options)}
                          showFilterMatchModes={false}
                          filterElement={(option) =>
                            rowFilterTemplate(
                              option,
                              legacyCodeFilter,
                              findAlternateName("LegacyCode")
                                ? findAlternateName("LegacyCode")
                                : "Legacy Code"
                            )
                          }
                        />
                        <Column
                          header={
                            findAlternateName("Level")
                              ? findAlternateName("Level")
                              : "Level"
                          }
                          hidden={!checkVisibility("Level")}
                          filterField="level"
                          sortable
                          filterMenuStyle={{ width: "14rem" }}
                          field="level"
                          filter
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          //editor={(options) => inputComponent(options)}
                          editor={getEditorComponent}
                          showFilterMatchModes={false}
                          filterElement={(option) =>
                            rowFilterTemplate(
                              option,
                              levelFilter,
                              findAlternateName("Level")
                                ? findAlternateName("Level")
                                : "Level"
                            )
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header={
                            findAlternateName("RoomNumber")
                              ? findAlternateName("RoomNumber")
                              : "Room Number"
                          }
                          hidden={!checkVisibility("RoomNumber")}
                          filterField="roomNumber"
                          sortable
                          filterMenuStyle={{ width: "14rem" }}
                          field="roomNumber"
                          filter
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          //editor={(options) => inputComponent(options)}
                          editor={getEditorComponent}
                          showFilterMatchModes={false}
                          filterElement={(option) =>
                            rowFilterTemplate(
                              option,
                              roomNumberFilter,
                              findAlternateName("RoomNumber")
                                ? findAlternateName("RoomNumber")
                                : "Room Number"
                            )
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header={
                            findAlternateName("RoomName")
                              ? findAlternateName("RoomName")
                              : "Room Name"
                          }
                          hidden={!checkVisibility("RoomName")}
                          filterField="roomName"
                          sortable
                          filterMenuStyle={{ width: "14rem" }}
                          field="roomName"
                          filter
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          //editor={(options) => inputComponent(options)}
                          editor={getEditorComponent}
                          showFilterMatchModes={false}
                          filterElement={(option) =>
                            rowFilterTemplate(
                              option,
                              roomNameFilter,
                              findAlternateName("RoomName")
                                ? findAlternateName("RoomName")
                                : "Room Name"
                            )
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header={
                            findAlternateName("Description")
                              ? findAlternateName("Description")
                              : "Description"
                          }
                          hidden={!checkVisibility("Description")}
                          filterField="description"
                          sortable
                          filterMenuStyle={{ width: "14rem" }}
                          field="description"
                          showFilterMatchModes={false}
                          filter
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          //editor={(options) => inputComponent(options)}
                          editor={getEditorComponent}
                          filterElement={(option) =>
                            rowFilterTemplate(
                              option,
                              descriptionFilter,
                              findAlternateName("Description")
                                ? findAlternateName("Description")
                                : "Description"
                            )
                          }
                          style={{ minWidth: "14rem" }}
                          bodyClassName="text-center"
                        />
                        <Column
                          header={
                            findAlternateName("Quantity")
                              ? findAlternateName("Quantity")
                              : "Quantity"
                          }
                          hidden={!checkVisibility("Quantity")}
                          filterField="quantity"
                          sortable
                          filterMenuStyle={{ width: "14rem" }}
                          field="quantity"
                          showFilterMatchModes={false}
                          filter
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          //editor={(options) => inputComponent(options)}
                          editor={getEditorComponent}
                          filterElement={(option) =>
                            rowFilterTemplate(
                              option,
                              quantityFilter,
                              findAlternateName("Quantity")
                                ? findAlternateName("Quantity")
                                : "Quantity"
                            )
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header={
                            findAlternateName("ItemType")
                              ? findAlternateName("ItemType")
                              : "Item Type"
                          }
                          hidden={!checkVisibility("ItemType")}
                          filterField="itemType"
                          sortable
                          filterMenuStyle={{ width: "14rem" }}
                          field="itemType"
                          showFilterMatchModes={false}
                          filter
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          //editor={(options) => inputComponent(options)}
                          editor={getEditorComponent}
                          filterElement={(option) =>
                            rowFilterTemplate(
                              option,
                              itemTypeFilter,
                              findAlternateName("ItemType")
                                ? findAlternateName("ItemType")
                                : "Item Type"
                            )
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header={
                            findAlternateName("DFECode")
                              ? findAlternateName("DFECode")
                              : "DFE Code"
                          }
                          hidden={!checkVisibility("DFECode")}
                          filterField="esfaCode"
                          sortField="esfaCode"
                          sortable
                          filterMenuStyle={{ width: "14rem" }}
                          field="esfaCode"
                          showFilterMatchModes={false}
                          filter
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          //editor={(options) => inputComponent(options)}
                          editor={getEditorComponent}
                          filterElement={(option) =>
                            rowFilterTemplate(
                              option,
                              esfaCodeFilter,
                              findAlternateName("DFECode")
                                ? findAlternateName("DFECode")
                                : "DFE Code"
                            )
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header={
                            findAlternateName("Condition")
                              ? findAlternateName("Condition")
                              : "Condition"
                          }
                          hidden={!checkVisibility("Condition")}
                          filterField="condition"
                          sortable
                          filterMenuStyle={{ width: "14rem" }}
                          field="condition"
                          showFilterMatchModes={false}
                          filter
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          //editor={(options) => inputComponent(options)}
                          editor={getEditorComponent}
                          filterElement={(option) =>
                            rowFilterTemplate(
                              option,
                              conditionFilter,
                              findAlternateName("Condition")
                                ? findAlternateName("Condition")
                                : "Condition"
                            )
                          }
                          style={{ minWidth: "14rem" }}
                          bodyClassName="text-center"
                          // filterElement={(option) =>{
                          //   const cf = [...new Set(conditionFilter)]
                          //   rowFilterTemplate(option, cf)
                          // }}
                        />
                        <Column
                          header={
                            findAlternateName("LifeExpectancy")
                              ? findAlternateName("LifeExpectancy")
                              : "Life Expectancy"
                          }
                          hidden={!checkVisibility("LifeExpectancy")}
                          filterField="lifeExpectancy"
                          sortable
                          filterMenuStyle={{ width: "14rem" }}
                          field="lifeExpectancy"
                          showFilterMatchModes={false}
                          filter
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          //editor={(options) => inputComponent(options)}
                          editor={getEditorComponent}
                          filterElement={(option) =>
                            rowFilterTemplate(
                              option,
                              lifeExpectancyFilter,
                              findAlternateName("LifeExpectancy")
                                ? findAlternateName("LifeExpectancy")
                                : "Life Expectancy"
                            )
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header={
                            findAlternateName("MakeModel")
                              ? findAlternateName("MakeModel")
                              : "Make/Model"
                          }
                          hidden={!checkVisibility("MakeModel")}
                          filterField="makeModel"
                          sortable
                          filterMenuStyle={{ width: "14rem" }}
                          field="makeModel"
                          showFilterMatchModes={false}
                          filter
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          //editor={(options) => inputComponent(options)}
                          editor={getEditorComponent}
                          filterElement={(option) =>
                            rowFilterTemplate(
                              option,
                              makeModelFilter,
                              findAlternateName("MakeModel")
                                ? findAlternateName("MakeModel")
                                : "Make/Model"
                            )
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header={
                            findAlternateName("Length")
                              ? findAlternateName("Length")
                              : "Length"
                          }
                          hidden={!checkVisibility("Length")}
                          filterField="length"
                          sortable
                          filterMenuStyle={{ width: "14rem" }}
                          field="length"
                          showFilterMatchModes={false}
                          filter
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          //editor={(options) => inputComponent(options)}
                          editor={getEditorComponent}
                          filterElement={(option) =>
                            rowFilterTemplate(
                              option,
                              lengthFilter,
                              findAlternateName("Length")
                                ? findAlternateName("Length")
                                : "Length"
                            )
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header={
                            findAlternateName("Depth")
                              ? findAlternateName("Depth")
                              : "Depth"
                          }
                          hidden={!checkVisibility("Depth")}
                          filterField="depth"
                          sortable
                          filterMenuStyle={{ width: "14rem" }}
                          field="depth"
                          showFilterMatchModes={false}
                          filter
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          //editor={(options) => inputComponent(options)}
                          editor={getEditorComponent}
                          filterElement={(option) =>
                            rowFilterTemplate(
                              option,
                              depthFilter,
                              findAlternateName("Depth")
                                ? findAlternateName("Depth")
                                : "Depth"
                            )
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header={
                            findAlternateName("Height")
                              ? findAlternateName("Height")
                              : "Height"
                          }
                          hidden={!checkVisibility("Height")}
                          filterField="height"
                          sortable
                          filterMenuStyle={{ width: "14rem" }}
                          field="height"
                          showFilterMatchModes={false}
                          filter
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          //editor={(options) => inputComponent(options)}
                          editor={getEditorComponent}
                          filterElement={(option) =>
                            rowFilterTemplate(
                              option,
                              heightFilter,
                              findAlternateName("Height")
                                ? findAlternateName("Height")
                                : "Height"
                            )
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header={
                            findAlternateName("PrimaryColour")
                              ? findAlternateName("PrimaryColour")
                              : "Primary Colour"
                          }
                          hidden={!checkVisibility("PrimaryColour")}
                          filterField="primaryColour"
                          sortable
                          filterMenuStyle={{ width: "14rem" }}
                          field="primaryColour"
                          showFilterMatchModes={false}
                          filter
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          //editor={(options) => inputComponent(options)}
                          editor={getEditorComponent}
                          filterElement={(option) =>
                            rowFilterTemplate(
                              option,
                              primaryColourFilter,
                              findAlternateName("PrimaryColour")
                                ? findAlternateName("PrimaryColour")
                                : "Primary Colour"
                            )
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header={
                            findAlternateName("SecondaryColour")
                              ? findAlternateName("SecondaryColour")
                              : "Secondary Colour"
                          }
                          hidden={!checkVisibility("SecondaryColour")}
                          filterField="secondaryColour"
                          sortable
                          filterMenuStyle={{ width: "14rem" }}
                          field="secondaryColour"
                          showFilterMatchModes={false}
                          filter
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          //editor={(options) => inputComponent(options)}
                          editor={getEditorComponent}
                          filterElement={(option) =>
                            rowFilterTemplate(
                              option,
                              secondaryColourFilter,
                              findAlternateName("SecondaryColour")
                                ? findAlternateName("SecondaryColour")
                                : "Secondary Colour"
                            )
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header={
                            findAlternateName("DFEStandard")
                              ? findAlternateName("DFEStandard")
                              : "DFE Standard"
                          }
                          hidden={!checkVisibility("DFEStandard")}
                          filterField="abnormal"
                          sortable
                          filterMenuStyle={{ width: "14rem" }}
                          field="abnormal"
                          showFilterMatchModes={false}
                          filter
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          //editor={(options) => inputComponent(options)}
                          editor={getEditorComponent}
                          filterElement={(option) =>
                            rowFilterTemplate(
                              option,
                              abnormalFilter,
                              findAlternateName("DFEStandard")
                                ? findAlternateName("DFEStandard")
                                : "DFE Standard"
                            )
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header={
                            findAlternateName("Comments")
                              ? findAlternateName("Comments")
                              : "Comments"
                          }
                          hidden={!checkVisibility("Comments")}
                          filterField="comments"
                          sortable
                          filterMenuStyle={{ width: "14rem" }}
                          field="comments"
                          showFilterMatchModes={false}
                          filter
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          //editor={(options) => inputComponent(options)}
                          editor={getEditorComponent}
                          filterElement={(option) =>
                            rowFilterTemplate(
                              option,
                              commentsFilter,
                              findAlternateName("Comments")
                                ? findAlternateName("Comments")
                                : "Comments"
                            )
                          }
                          style={{ minWidth: "14rem" }}
                          bodyClassName="text-center"
                        />
                        {/* <Column
                      header="Photograph"
                      filterField="photograph"
                      sortable
                      filterMenuStyle={{ width: "14rem" }}
                      field="photograph"
                      showFilterMatchModes={false}
                      filter
                      onCellEditComplete={(data) => onCellEditComplete(data)}
                      editor={(options) => inputComponent(options)}
                      filterElement={(option) =>
                        rowFilterTemplate(option, photographFilter)
                      }
                    /> */}
                        <Column
                          header={
                            findAlternateName("Cost")
                              ? findAlternateName("Cost")
                              : "Cost"
                          }
                          hidden={!checkVisibility("Cost")}
                          filterField="cost"
                          sortable
                          filterMenuStyle={{ width: "14rem" }}
                          field="cost"
                          showFilterMatchModes={false}
                          filter
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          //editor={(options) => inputComponent(options)}
                          editor={getEditorComponent}
                          filterElement={(option) =>
                            rowFilterTemplate(
                              option,
                              costFilter,
                              findAlternateName("Cost")
                                ? findAlternateName("Cost")
                                : "Cost"
                            )
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header={
                            findAlternateName("CarbonSavingKG")
                              ? findAlternateName("CarbonSavingKG")
                              : "Carbon Saving KG"
                          }
                          hidden={!checkVisibility("CarbonSavingKG")}
                          filterField="carbonSavingKG"
                          sortable
                          filterMenuStyle={{ width: "14rem" }}
                          field="carbonSavingKG"
                          showFilterMatchModes={false}
                          filter
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          //editor={(options) => inputComponent(options)}
                          editor={getEditorComponent}
                          filterElement={(option) =>
                            rowFilterTemplate(
                              option,
                              carbonSavingKGFilter,
                              findAlternateName("CarbonSavingKG")
                                ? findAlternateName("CarbonSavingKG")
                                : "Carbon Saving KG"
                            )
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header={
                            findAlternateName("NewRoomName")
                              ? findAlternateName("NewRoomName")
                              : "New Room Name"
                          }
                          hidden={!checkVisibility("NewRoomName")}
                          filterField="newRoomName"
                          sortable
                          filterMenuStyle={{ width: "14rem" }}
                          field="newRoomName"
                          showFilterMatchModes={false}
                          filter
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          //editor={(options) => inputComponent(options)}
                          editor={getEditorComponent}
                          filterElement={(option) =>
                            rowFilterTemplate(
                              option,
                              newRoomNameFilter,
                              findAlternateName("NewRoomName")
                                ? findAlternateName("NewRoomName")
                                : "New Room Name"
                            )
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header={
                            findAlternateName("NewRoomNumber")
                              ? findAlternateName("NewRoomNumber")
                              : "New Room Number"
                          }
                          hidden={!checkVisibility("NewRoomNumber")}
                          filterField="newRoomNumber"
                          sortable
                          filterMenuStyle={{ width: "14rem" }}
                          field="newRoomNumber"
                          showFilterMatchModes={false}
                          filter
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          //editor={(options) => inputComponent(options)}
                          editor={getEditorComponent}
                          filterElement={(option) =>
                            rowFilterTemplate(
                              option,
                              newRoomNumberFilter,
                              findAlternateName("NewRoomNumber")
                                ? findAlternateName("NewRoomNumber")
                                : "New Room Number"
                            )
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          hidden={
                            userRole !== "Client" && userRole !== "ClientUser"
                          }
                          header={
                            findAlternateName("RemarksComment")
                              ? findAlternateName("RemarksComment")
                              : "Remarks"
                          }
                          body={(row) =>
                            userId === row.remarksCommentedBy
                              ? row.remarksComment
                              : ""
                          }
                          bodyClassName="text-center"
                        />
                        {/* <Column
                          hidden={
                            userRole !== "Client" && userRole !== "ClientUser"
                          }
                          header={
                            findAlternateName("ApprovalsComment")
                              ? findAlternateName("ApprovalsComment")
                              : "Approval Comment"
                          }
                          body={(row) =>
                            !!row.remarksCommentedBy &&
                            userId !== row.remarksCommentedBy
                              ? ""
                              : row.approvalsComment
                          }
                          bodyClassName="text-center"
                        /> */}
                        {!enableRequestRowEdit && (
                          <Column
                            hidden={
                              userRole !== "Client" && userRole !== "ClientUser"
                            }
                            header="Action"
                            body={requestForChange}
                            bodyClassName="text-center"
                          />
                        )}
                        <Column
                          hidden={!checkVisibility("PhotographCode")}
                          header="View Image"
                          body={viewImage}
                          bodyClassName="text-center"
                        />

                        <Column
                          header="QRCode"
                          hidden
                          filterField="qrCode"
                          sortable
                          filterMenuStyle={{ width: "14rem" }}
                          field="qrCode"
                          showFilterMatchModes={false}
                          filter
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          //editor={(options) => inputComponent(options)}
                          editor={getEditorComponent}
                          filterElement={(option) =>
                            rowFilterTemplate(option, qrCodeFilter, "QRCode")
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          hidden={!checkVisibility("QRCode")}
                          header="View QR"
                          body={viewQR}
                          bodyClassName="text-center"
                        />
                      </DT>
                      <Paginator
                        template={template2}
                        first={first}
                        rows={perPage}
                        totalRecords={noOfRecords}
                        onPageChange={(e) => onPageChange(e, 1)}
                        className="justify-content-end"
                      />
                    </>
                  )}
                  {/* <Paginator
                  template={template2}
                  first={first}
                  rows={perPage}
                  totalRecords={noOfRecords}
                  onPageChange={(e) => onPageChange(e, 1)}
                  className="justify-content-end"
                /> */}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {/* {(loading || loadingProjectReport) && <Loader />} */}
            {(loading) && <Loader />}
            {/* <div className="mainContent px-4 pt-3"> */}
            {noOfRecords > 0 ? (
              <div className="row mt-4">
                <div className="col-md-12">
                  {/* <div className="page-header mb-3">
                <div className="row align-items-center">
                  <div className="col-sm-12">
                    <div className="page-sub-header">
                      <h3 className="page-title">Dashboard</h3>
                      <div className="page-title-right d-flex"></div>
                    </div>
                  </div>
                </div>
              </div> */}
                  <div className="row">
                    <div className="col-12">
                      <div className="card shadow-sm border-0">
                        <div className="card-body p-9">
                          <div className="d-lg-flex gap-1 justify-content-between">
                            <div className="d-flex">
                              <div className="client-icon">
                                {/* <i className="bi bi-cast"></i> */}
                                {clientId && (
                                  <img
                                    src={`${IMAGE_LOGO_BASE_URL}/${clientId}.jpg?timestamp=${Date.now()}`}
                                    alt="Logo"
                                    className="project-logo-img client-logo"
                                  />
                                )}
                              </div>
                              <div>
                                <h6 className="my-0 fw-normal">
                                  {projectName}{" "}
                                </h6>
                                {/* {selectedProjectName}  */}
                                <h6 className="my-0">
                                  Date:{" "}
                                  {reportRecordsCounts &&
                                    moment(
                                      reportRecordsCounts?.projectCreationDate
                                    ).format("DD/MM/YYYY")}{" "}
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            {/* <div className="col-lg-4 mb-2">
                            <div className="form-group">
                              <label className="form-label mb-0">Project Name</label>
                              <select
                                value={projectIdDash}
                                onChange={(e) => handleProjectNameSelect(e.target.value)}
                                className="form-select"
                              >
                                {noOfRecords > 0 && projects.projects.listData &&
                                  projects.projects.listData.map((project) => (
                                    <option key={project.id} value={project.id}>
                                      {project.name}
                                    </option>
                                  ))}
                              </select>

                            </div>
                          </div> */}
                            <div
                              className="col-lg-4 mb-2"
                              hidden={!checkVisibility("RoomNumber")}
                            >
                              <div className="form-group">
                                <label className="form-label mb-0">
                                  {findAlternateName("RoomNumber")
                                    ? findAlternateName("RoomNumber")
                                    : "Room Number"}
                                </label>
                                <Multiselect
                                  options={roomNumber.options || []}
                                  onSelect={handleRoomNumberSelect}
                                  ref={roomNumberRef}
                                  // onRemove={(selectedList, removedItem) => {
                                  //   setSelectedRoomNumber(selectedList);
                                  // }}
                                  onRemove={(selectedList, removedItem) => {
                                    const updatedSelectedRoomNumber =
                                      selectedRoomNumber.filter(
                                        (item) => item.name !== removedItem.name
                                      );
                                    setSelectedRoomNumber(
                                      updatedSelectedRoomNumber
                                    );
                                  }}
                                  displayValue="name"
                                  placeholder={
                                    selectedRoomNumber.length > 0
                                      ? ""
                                      : "Select " +
                                        (findAlternateName("RoomNumber")
                                          ? findAlternateName("RoomNumber")
                                          : "Room Number")
                                  }
                                  loading={
                                    roomNumber.options.length > 0 ? false : true
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className="col-lg-4 mb-2"
                              hidden={!checkVisibility("RoomName")}
                            >
                              <div className="form-group">
                                <label className="form-label mb-0">
                                  {findAlternateName("RoomName")
                                    ? findAlternateName("RoomName")
                                    : "Room Name"}
                                </label>
                                <Multiselect
                                  options={roomName.options || []}
                                  onSelect={handleRoomNameSelect}
                                  ref={roomNameRef}
                                  // onRemove={(selectedList, removedItem) => {
                                  //   setSelectedRoomName(selectedList);
                                  // }}
                                  onRemove={(selectedList, removedItem) => {
                                    const updatedSelectedRoomName =
                                      selectedRoomName.filter(
                                        (item) => item.name !== removedItem.name
                                      );
                                    setSelectedRoomName(
                                      updatedSelectedRoomName
                                    );
                                  }}
                                  displayValue="name"
                                  placeholder={
                                    selectedRoomName.length > 0
                                      ? ""
                                      : "Select " +
                                        (findAlternateName("RoomName")
                                          ? findAlternateName("RoomName")
                                          : "Room Name")
                                  }
                                  loading={
                                    roomName.options.length > 0 ? false : true
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className="col-lg-4 mb-2"
                              hidden={!checkVisibility("Description")}
                            >
                              <div className="form-group">
                                <label className="form-label mb-0">
                                  {findAlternateName("Description")
                                    ? findAlternateName("Description")
                                    : "Description"}
                                </label>
                                <Multiselect
                                  options={description.options || []}
                                  onSelect={handleDescriptionSelect}
                                  ref={descriptionRef}
                                  // onRemove={(selectedList, removedItem) => {
                                  //   setSelectedDescription(selectedList);
                                  // }}
                                  onRemove={(selectedList, removedItem) => {
                                    const updatedSelectedDescription =
                                      selectedDescription.filter(
                                        (item) => item.name !== removedItem.name
                                      );
                                    setSelectedDescription(
                                      updatedSelectedDescription
                                    );
                                  }}
                                  displayValue="name"
                                  placeholder={
                                    selectedDescription.length > 0
                                      ? ""
                                      : "Select " +
                                        (findAlternateName("Description")
                                          ? findAlternateName("Description")
                                          : "Description")
                                  }
                                  loading={
                                    description.options.length > 0
                                      ? false
                                      : true
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className="col-lg-4 mb-2"
                              hidden={!checkVisibility("ItemType")}
                            >
                              <div className="form-group">
                                <label className="form-label mb-0">
                                  {findAlternateName("ItemType")
                                    ? findAlternateName("ItemType")
                                    : "Item Type"}
                                </label>
                                <Multiselect
                                  options={itemType.options || []}
                                  onSelect={handleItemTypeSelect}
                                  ref={itemTypeRef}
                                  // onRemove={(selectedList, removedItem) => {
                                  //   setSelectedItemType(selectedList);
                                  // }}
                                  onRemove={(selectedList, removedItem) => {
                                    const updatedSelectedItemType =
                                      selectedItemType.filter(
                                        (item) => item.name !== removedItem.name
                                      );
                                    setSelectedItemType(
                                      updatedSelectedItemType
                                    );
                                  }}
                                  displayValue="name"
                                  placeholder={
                                    selectedItemType.length > 0
                                      ? ""
                                      : "Select " +
                                        (findAlternateName("ItemType")
                                          ? findAlternateName("ItemType")
                                          : "Item Type")
                                  }
                                  loading={
                                    itemType.options.length > 0 ? false : true
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className="col-lg-4 mb-2"
                              hidden={!checkVisibility("Condition")}
                            >
                              <div className="form-group">
                                <label className="form-label mb-0">
                                  {findAlternateName("Condition")
                                    ? findAlternateName("Condition")
                                    : "Condition"}
                                </label>
                                <Multiselect
                                  options={condition.options || []}
                                  onSelect={handleConditionSelect}
                                  ref={conditionRef}
                                  // onRemove={(selectedList, removedItem) => {
                                  //   setSelectedCondition(selectedList);
                                  // }}
                                  onRemove={(selectedList, removedItem) => {
                                    const updatedSelectedCondition =
                                      selectedCondition.filter(
                                        (item) => item.name !== removedItem.name
                                      );
                                    setSelectedCondition(
                                      updatedSelectedCondition
                                    );
                                  }}
                                  displayValue="name"
                                  placeholder={
                                    selectedCondition.length > 0
                                      ? ""
                                      : "Select " +
                                        (findAlternateName("Condition")
                                          ? findAlternateName("Condition")
                                          : "Condition")
                                  }
                                  loading={
                                    condition.options.length > 0 ? false : true
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className="col-lg-4 mb-2"
                              hidden={!checkVisibility("DFEStandard")}
                            >
                              <div className="form-group">
                                <label className="form-label mb-0">
                                  {findAlternateName("DFEStandard")
                                    ? findAlternateName("DFEStandard")
                                    : "DFE Standard"}
                                </label>
                                <Multiselect
                                  options={dfeStandard.options || []}
                                  onSelect={handleDFEStandardSelect}
                                  ref={dfeStandardRef}
                                  // onRemove={(selectedList, removedItem) => {
                                  //   setSelectedDFEStandard(selectedList);
                                  // }}
                                  onRemove={(selectedList, removedItem) => {
                                    const updatedSelectedDFEStandard =
                                      selectedDFEStandard.filter(
                                        (item) => item.name !== removedItem.name
                                      );
                                    setSelectedDFEStandard(
                                      updatedSelectedDFEStandard
                                    );
                                  }}
                                  displayValue="name"
                                  placeholder={
                                    selectedDFEStandard.length > 0
                                      ? ""
                                      : "Select " +
                                        (findAlternateName("DFEStandard")
                                          ? findAlternateName("DFEStandard")
                                          : "DFE Standard")
                                  }
                                  loading={
                                    dfeStandard.options.length > 0
                                      ? false
                                      : true
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className="col-lg-4 mb-2"
                              hidden={!checkVisibility("LifeExpectancy")}
                            >
                              <div className="form-group">
                                <label className="form-label mb-0">
                                  {findAlternateName("LifeExpectancy")
                                    ? findAlternateName("LifeExpectancy")
                                    : "LifeExpectancy"}
                                </label>
                                <Multiselect
                                  options={lifeExpectancy.options || []}
                                  onSelect={handleLifeExpectancySelect}
                                  ref={lifeExpectancyRef}
                                  // onRemove={(selectedList, removedItem) => {
                                  //   setSelectedLifeExpectancy(selectedList);
                                  // }}
                                  onRemove={(selectedList, removedItem) => {
                                    const updatedSelectedLifeExpectancy =
                                      selectedLifeExpectancy.filter(
                                        (item) => item.name !== removedItem.name
                                      );
                                    setSelectedLifeExpectancy(
                                      updatedSelectedLifeExpectancy
                                    );
                                  }}
                                  displayValue="name"
                                  placeholder={
                                    selectedLifeExpectancy.length > 0
                                      ? ""
                                      : "Select " +
                                        (findAlternateName("LifeExpectancy")
                                          ? findAlternateName("LifeExpectancy")
                                          : "LifeExpectancy")
                                  }
                                  loading={
                                    lifeExpectancy.options.length > 0
                                      ? false
                                      : true
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-lg-2 mt-4">
                              {/* <label className="form-label mb-0"></label> */}
                              <button
                                className="btn btn-primary reset-button-height"
                                onClick={handleResetFilter}
                              >
                                Reset Filter
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Charts NEEL 02-10-2024 */}
                  <div className="row mt-3">
                    {(checkVisibility("Condition") ||
                      checkVisibility("CarbonSavingKG") ||
                      checkVisibility("DFEStandard")) && (
                      <>
                        {checkVisibility("Condition") && (
                          <div className="col-lg-4 mb-2">
                            <div className="card shadow-sm border-0 h-100">
                              <div className="card-body p-9 d-flex align-items-center justify-content-center">
                                <div className="progress-wrapper">
                                  {checkVisibility("Condition") ? (
                                    <>
                                      <div className="progress-title dark-blue-font">
                                        {suitablePerDashboard} %{" "}
                                      </div>
                                      {/* <span className="sup-script">%</span></div> */}
                                      <strong>
                                        <div className="fnt-20 dark-blue-font font-bold">
                                          Suitable to repurpose{" "}
                                        </div>
                                      </strong>
                                      <div className="fnt-16 dark-blue-font font-bold">
                                        Within the next 2-3 years of present
                                        usage
                                      </div>{" "}
                                    </>
                                  ) : (
                                    "No data found"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {checkVisibility("CarbonSavingKG") && (
                          <div className="col-lg-4 mb-2">
                            <div className="card shadow-sm border-0 h-100">
                              <div className="card-body p-9 d-flex align-items-center justify-content-center">
                                <div className="progress-wrapper">
                                  {checkVisibility("CarbonSavingKG") ? (
                                    <>
                                      <div className="progress-title green-font">
                                        {/* {reportRecordsCountsDash?.carbonSavingKG} */}
                                        {reportRecordsCounts?.carbonSavingKG}
                                        {/* NEEL 01-10-2024 */}
                                        kg of Co2
                                      </div>
                                      {/* <span className="fnt-16 sup-script">kg of Co2</span></div> */}
                                      {/* <div className="fnt-20 fw-bold mt-2">{projectName}</div> */}
                                      <strong>
                                        <div className="fnt-20 dark-blue-font font-bold">
                                          {projectName} Potential carbon
                                          reduction
                                        </div>
                                      </strong>
                                      {/* <div className="fnt-20 fw-bold mt-2">{selectedProjectName.length > 0 && selectedProjectName[0].name}</div>
                          <strong><div className="fnt-20">{selectedProjectName} Potential carbon reduction</div></strong> */}
                                      <div className="fnt-16 dark-blue-font font-bold">
                                        repurposing quality FF&E
                                      </div>{" "}
                                    </>
                                  ) : (
                                    "No data found"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {checkVisibility("DFEStandard") && (
                          <div className="col-lg-4 mb-2">
                            <div className="card shadow-sm border-0 h-100">
                              {checkVisibility("DFEStandard") ? (
                                <div className="card-body p-9">
                                  <div className="text-center doughnut-chart">
                                    <Doughnut
                                      data={donutChartData}
                                      options={donutChartOptions}
                                      plugins={[ChartDataLabels]}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div className="card-body p-9 d-flex align-items-center justify-content-center">
                                  No data found
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    {(checkVisibility("Condition") ||
                      checkVisibility("LifeExpectancy")) && (
                      <>
                        {checkVisibility("Condition") && (
                          <div className="col-lg-6 mb-2">
                            <div className="card shadow-sm border-0 h-100">
                              {checkVisibility("Condition") ? (
                                <div className="card-body p-9">
                                  <Bar
                                    data={barChartData}
                                    options={barCharOptions}
                                    plugins={[ChartDataLabels]}
                                  />
                                </div>
                              ) : (
                                <div className="card-body p-9 d-flex align-items-center justify-content-center">
                                  No data found
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {checkVisibility("LifeExpectancy") && (
                          <div className="col-lg-6 mb-2">
                            <div className="card shadow-sm border-0 h-100">
                              {checkVisibility("LifeExpectancy") ? (
                                <div className="card-body p-9">
                                  <Bar
                                    data={horizontalBarChartData}
                                    options={horizontalBarCharOptions}
                                    plugins={[ChartDataLabels]}
                                  />
                                </div>
                              ) : (
                                <div className="card-body p-9 d-flex align-items-center justify-content-center">
                                  No data found
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {/* NEEL 02-10-2024 */}

                  {/* {JSON.stringify(tableData)} */}
                  {/* {JSON.stringify(tableColumns)} */}
                  {checkVisibility("Condition") &&
                    checkVisibility("Description") && (
                      <div className="table-tbody-fixed">
                        {tableData && tableData.length > 0 ? (
                          <DataTable
                            columns={tableColumns}
                            data={tableData}
                            className="table table-bordered table-striped"
                            customStyles={customStylesDataTable}
                            conditionalRowStyles={conditionalRowStyles}
                          />
                        ) : (
                          <div className="row mt-4 mb-4">
                            <div className="col-md-12">
                              <div className="card shadow-sm border-0 h-100">
                                <div className="card-body p-9 d-flex align-items-center justify-content-center">
                                  Loading ...
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                </div>
              </div>
            ) : (
              <div className="row mt-4">
                {" "}
                <div className="col-md-12">No Record found</div>
              </div>
            )}
          </>
        )}
      </div>

      {/* {isOpenModalImage && (
        <Lightbox
          image={modalImage}
          onClose={() => {
            setModalImage(null);
            setIsOpenModalImage(false);
          }}
          title={modalImageName}
        />
      )} */}

      {isOpenModalImage && modalImage.length === 1 && (
        <Lightbox
          image={modalImage[0].url}
          title={modalImage[0].title}
          onClose={() => {
            handleLightBoxClosed();
          }}
        />
      )}

      {isOpenModalImage && modalImage.length > 1 && (
        <Lightbox
          images={modalImage}
          onClose={() => {
            handleLightBoxClosed();
          }}
        />
      )}

      {/* <Modal
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(false)}
        // fullscreen={true}
        size="lg"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Project Details History
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataTable
            columns={projectDetailsHistoryColumns}
            data={projectDetailsHistory}
            className="table table-bordered table-striped"
            filterServer={true}
            striped={true}
            fixedHeader={true}
            pagination
            paginationServer
            paginationTotalRows={noOfRecordsHistory}
            subHeader
            subHeaderComponent={
              <div className="col-md-2">
                <input
                  onChange={(e) => setSearchHistoryText(e.target.value)}
                  placeholder="Search"
                  className="form form-control"
                />
              </div>
            }
            onChangeRowsPerPage={handleProjectDetailsHistoryPerRowsChange}
            onChangePage={handleProjectDetailsHistoryPageChange}
            onSort={handleProjectDetailsHistorySort}
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            sortServer
            highlightOnHover
            searchableRows={true}
            responsive={true}
            customStyles={customStylesDataTable}
          />
        </Modal.Body>
      </Modal> */}
      <Modal
        centered
        show={isVisibleDialog}
        onHide={() => setVisibleDialog(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your changes are unsaved. <br />
          Are you sure you want to move to another page?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setVisibleDialog(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setTriggerExit((obj) => ({
                ...obj,
                onOk: true,
              }));
              setVisibleDialog(false);
            }}
          >
            Yes, I want to move to another page
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={modalIsOpen}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => closeModal()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mb-3">
              <label htmlFor="remarks" className="form-label">
                Remarks<span className="text-danger"> * </span>
              </label>
              <textarea
                name="remarks"
                className={`form-control ${
                  formik.touched.remarks && formik.errors.remarks
                    ? "is-invalid"
                    : ""
                }`}
                id="remarks"
                placeholder="Enter Remarks for the Change Request"
                value={!!formik.values.remarks ? formik.values.remarks : ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                rows={5}
              />
              {formik.touched.remarks && formik.errors.remarks && (
                <div className="invalid-feedback">{formik.errors.remarks}</div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={closeModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleReqForChange}
            disabled={loader}
          >
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(false)}
        size="lg"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Project Report History
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataTable
            columns={projectReportsHistoryColumns}
            data={projectReportsHistory}
            className="table table-bordered table-striped"
            filterServer={true}
            striped={true}
            fixedHeader={true}
            pagination
            paginationServer
            paginationTotalRows={noOfRecordsHistory}
            subHeader
            subHeaderComponent={
              <div className="col-md-2">
                <input
                  onChange={(e) => setSearchHistoryText(e.target.value)}
                  placeholder="Search"
                  className="form form-control"
                />
              </div>
            }
            onChangeRowsPerPage={(e) => {
              if (page !== 1) {
                handleProjectDetailsHistoryPerRowsChange();
              }
            }}
            onChangePage={(e) => {
              if (page !== 1) {
                handleProjectDetailsHistoryPageChange();
              }
            }}
            onSort={handleProjectDetailsHistorySort}
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            sortServer
            highlightOnHover
            searchableRows={true}
            responsive={true}
            customStyles={customStylesDataTable}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProjectReports;
