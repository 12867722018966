import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import {
  API_LIST_ALL_PROJECT_REPORT,
  API_LIST_PROJECT_REPORT,
  API_LIST_PROJECT_REPORT_DETAILED_LEGACY_REPORT,
  API_PROJECT_COLOUR,
  API_PROJECT_REPORT_DASHBOARD,
  API_PROJECT_REPORT_FILTER_OPTIONS,
  API_PROJECT_REPORT_TABLE,
} from "../../APIProxy/api";

//Project Report APIs
const token =
  !!Cookies.get("COOKIE_JWTTOKEN") && Cookies.get("COOKIE_JWTTOKEN");
let decodedToken = "";

if (token) {
  const decryptedToken = CryptoJS.AES.decrypt(token, "CRYPTO_SECRET_KEY");
  decodedToken = decryptedToken.toString(CryptoJS.enc.Utf8);
}
export const projectsReport = createAsyncThunk(
  "project/projectsReport",
  async (data) => {
    const response = await axios.get(`${API_LIST_PROJECT_REPORT}`, {
      headers: { Authorization: `Bearer ${decodedToken}` },
      params: data,
    });
    return response.data;
  }
);

export const projectsReportDashboard = createAsyncThunk(
  "project/projectsReportDashBoard",
  async (data) => {
    const response = await axios.get(`${API_PROJECT_REPORT_DASHBOARD}`, {
      headers: { Authorization: `Bearer ${decodedToken}` },
      params: data,
    });
    return response.data;
  }
);

export const projectsReportTable = createAsyncThunk(
  "project/projectsReportTable",
  async (data) => {
    const response = await axios.get(`${API_PROJECT_REPORT_TABLE}`, {
      headers: { Authorization: `Bearer ${decodedToken}` },
      params: data,
    });
    return response.data;
  }
);

export const projectsReportFilterOptions = createAsyncThunk(
  "project/projectsReportFilterOptions",
  async (data) => {
    const response = await axios.get(`${API_PROJECT_REPORT_FILTER_OPTIONS}`, {
      headers: { Authorization: `Bearer ${decodedToken}` },
      params: data,
    });
    return response.data;
  }
);

export const projectsReportDetailedLegacySurveyTable = createAsyncThunk(
  "project/projectsReportDetailedLegacySurveyTable",
  async (data) => {
    const response = await axios.get(`${API_LIST_PROJECT_REPORT_DETAILED_LEGACY_REPORT}`, {
      headers: { Authorization: `Bearer ${decodedToken}` },
      params: data,
    });
    return response.data;
  }
);

export const allProjectsReportList = createAsyncThunk(
  "project/allProjectsReportList",
  async (data) => {
    const response = await axios.get(`${API_LIST_ALL_PROJECT_REPORT}`, {
      headers: { Authorization: `Bearer ${decodedToken}` },
      params: {
        projectId: data.projectId,
        isShowChangeRequest: data.isShowChangeRequest,
      },
    });

    return response.data;
  }
);

//Project Colour
export const projectColour = createAsyncThunk(
  "project/projectColour",
  async () => {
    const response = await axios.get(API_PROJECT_COLOUR, {
      headers: { Authorization: `Bearer ${decodedToken}` },
    });
    return response.data;
  }
);
