import { createSlice } from "@reduxjs/toolkit";
import {
  allProjectsReportList,
  projectsReport,
  projectsReportDashboard,
  projectsReportDetailedLegacySurveyTable,
  projectsReportFilterOptions,
  projectsReportTable,
} from "../Actions/projectsReportActions";
import { projectReportHistoryList } from "../Actions/projectsActions";

const initialState = {
  projectsReport: [],
  projectReport: [],
  projectReportDDList: [],
  conditionBreakdownList: [],
  conditionBreakdownListTotal: [],
  noOfRecords: 0,
  reportRecordsCounts: null,
  projectName: null,
  clientName: null,
  author: null,
  ffeSurveyors: null,
  columnJSON: null,
  loading: false,
  error: null,
  projectReportsHistory: [],
  suitablePer: 0.0,
  conditionDescriptionWithCounts: null,
  distinctRoomNames:null,
  distinctRoomNumbers:null,
  distinctDescription:null,
  distinctItemType:null,
  distinctCondition:null,
  distinctDFEStandard:null,
  distinctLifeExpectancy:null,
};

const projectReportSlice = createSlice({
  name: "projectsReport",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(projectsReport.pending, (state) => {
        state.loading = true;
        state.projectReport = [];
        state.projectName = null;
        state.clientName = null;
        state.author = null;
        state.ffeSurveyors = null;
        state.columnJSON = null;
      })
      .addCase(projectsReport.fulfilled, (state, action) => {
        state.loading = false;
        state.projectReport = action.payload.projectReportList;
        //state.conditionBreakdownList = action.payload.conditionBreakdownList;
        state.reportRecordsCounts = action.payload.reportRecordsCounts;
        state.noOfRecords = action.payload.noOfRecords;
        state.projectName = action.payload.projectName;
        state.clientName = action.payload.clientName;
        state.author = action.payload.author;
        state.ffeSurveyors = action.payload.ffeSurveyors;
        state.columnJSON = action.payload.columnJSON;
      })
      .addCase(projectsReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(allProjectsReportList.pending, (state) => {
        state.projectReportDDList = [];
      })
      .addCase(allProjectsReportList.fulfilled, (state, action) => {
        state.projectReportDDList = action.payload;
      })
      .addCase(allProjectsReportList.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(projectReportHistoryList.pending, (state) => {
        state.loading = true;
        state.projectReportsHistory = [];
        state.noOfRecords = 0;
      })
      .addCase(projectReportHistoryList.fulfilled, (state, action) => {
        state.loading = false;
        state.projectReportsHistory = action.payload.projectReportHistoryList;
        state.noOfRecords = action.payload.noOfRecords;
      })
      .addCase(projectReportHistoryList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(projectsReportDashboard.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(projectsReportDashboard.fulfilled, (state, action) => {
        state.loading = false;
        state.conditionBreakdownList = action.payload.conditionBreakdownList;
        state.conditionBreakdownListTotal =
          action.payload.conditionBreakdownListTotal;
        state.reportRecordsCounts = action.payload.reportRecordsCounts;
        //state.conditionDescriptionWithCounts = action.payload.conditionDescriptionWithCounts;
        state.suitablePer = action.payload.suitablePer;
        state.projectName = action.payload.projectName;
        state.clientName = action.payload.clientName;
        state.author = action.payload.author;
        state.ffeSurveyors = action.payload.ffeSurveyors;
        state.columnJSON = action.payload.columnJSON;
        state.noOfRecords = action.payload.conditionBreakdownListTotal.length;
      })
      .addCase(projectsReportDashboard.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(projectsReportTable.pending, (state, action) => {
        //state.loading = true;
      })
      .addCase(projectsReportTable.fulfilled, (state, action) => {
        state.loading = false;
        state.conditionDescriptionWithCounts =
          action.payload.conditionDescriptionWithCounts;
      })
      .addCase(projectsReportTable.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(projectsReportFilterOptions.pending, (state, action) => {
        //state.loading = true;
      })
      .addCase(projectsReportFilterOptions.fulfilled, (state, action) => {
        state.loading = false;
        state.distinctRoomNames = action.payload.distinctRoomNames;
        state.distinctRoomNumbers = action.payload.distinctRoomNumbers;
        state.distinctDescription = action.payload.distinctDescription;
        state.distinctItemType = action.payload.distinctItemType;
        state.distinctCondition = action.payload.distinctCondition;
        state.distinctDFEStandard = action.payload.distinctDFEStandard;
        state.distinctLifeExpectancy = action.payload.distinctLifeExpectancy;
      })
      .addCase(projectsReportFilterOptions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(projectsReportDetailedLegacySurveyTable.pending, (state) => {
        //state.loading = true;
        state.projectReport = [];
      })
      .addCase(projectsReportDetailedLegacySurveyTable.fulfilled, (state, action) => {
        state.loading = false;
        state.projectReport = action.payload.projectReportList;
        state.conditionBreakdownListTotal =
          action.payload.conditionBreakdownList;
        state.noOfRecords = action.payload.noOfRecords;
      })
      .addCase(projectsReportDetailedLegacySurveyTable.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default projectReportSlice.reducer;
