/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import * as Yup from "yup";
import * as CryptoJS from "crypto-js";
import { useFormik } from "formik";
import {
  addProject,
  addProjectDetails,
  addProjectReportDetails,
  deleteProject,
  editProject,
  listProjects,
  publishProject,
} from "../../Redux/Actions/projectsActions";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import Loader from "../Common/Loader/loader";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Cookies from "js-cookie";
import { secret_key } from "../../APIProxy/secret";
import Swal from "sweetalert2";
import moment from "moment";
import { imagesUpload } from "../../Redux/Actions/imagesUploadActions";
import { projectsReport } from "../../Redux/Actions/projectsReportActions";
import ExcelJS from "exceljs";
import config from "../../config";

const customStylesDataTable = {
  headCells: {
    style: {
      fontSize: "16px",
      fontWeight: "Bold",
    },
  },
};

const minDate = "2023-10-01";
const maxDate = new Date().toISOString().split("T")[0];
//const projectNameRegExp = /^[A-Za-z0-9 ]+$/; ///^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/;
const projectNameRegExp =
  /^(?!.*\s{3})[A-Za-z0-9]+(?:[A-Za-z0-9 ]*[A-Za-z0-9])?$/;

const editValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Project name is required")
    .matches(projectNameRegExp, "Invalid project name")
    .min(3, "Name is too short")
    .max(200, "Name is too long"),
  author: Yup.string()
    .required("Author name is required")
    .matches(projectNameRegExp, "Invalid Author name")
    .min(3, "Author name is too short")
    .max(100, "Author name is too long"),
  ffeSurveyors: Yup.string()
    .required("FF&E Surveyors is required")
    .matches(projectNameRegExp, "Invalid FF&E Surveyors")
    .min(3, "FF&E Surveyors is too short")
    .max(200, "FF&E Surveyors is too long"),
  createdDate: Yup.date()
    .min(minDate, `Date must be after ${minDate}`)
    .max(maxDate, `Date cannot be in the future`),
  //.required('Required')
});

const addValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Project name is required")
    .matches(projectNameRegExp, "Invalid project name")
    .min(3, "Name is too short")
    .max(200, "Name is too long"),
  author: Yup.string()
    .required("Author name is required")
    .matches(projectNameRegExp, "Invalid Author name")
    .min(3, "Author name is too short")
    .max(100, "Author name is too long"),
  ffeSurveyors: Yup.string()
    .required("FF&E Surveyors is required")
    .matches(projectNameRegExp, "Invalid FF&E Surveyors")
    .min(3, "FF&E Surveyors is too short")
    .max(200, "FF&E Surveyors is too long"),
  createdDate: Yup.date()
    .min(minDate, `Date must be after ${minDate}`)
    .max(maxDate, `Date cannot be in the future`),
  //.required('Required')
});

const ProjectList = ({
  uploadingFiles,
  setUploadingFiles,
  successUploadCount,
  setSuccessUploadCount,
  clearUploadStatus,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId } = useParams();
  const IMAGE_BASE_URL = config.LOGO_URL;

  const userRole = Cookies.get("userRole");

  const loginUserId =
    !!Cookies.get("userId") &&
    CryptoJS.AES.decrypt(Cookies.get("userId"), secret_key).toString(
      CryptoJS.enc.Utf8
    );
  const projects = useSelector((state) => state.projects);
  const noOfRecords = useSelector(
    (state) => state.projects.projects.noOfRecords
  );
  const loading = useSelector((state) => state.projects.loading);
  const loadingImages = useSelector((state) => state.images.loading);
  const clientName = useSelector((state) => state.projects.clientName);
  const email = useSelector((state) => state.projects.clientEmail);
  const noOfRecordsProjectReport = useSelector(
    (state) => state.projectsReport.noOfRecords
  );
  // const projectReport = useSelector((state) => state.projectsReport);
  const [searchText, setSearchText] = useState(null);
  const [filteredData, setFilteredData] = useState();
  const [data, setUsersdata] = useState("");
  const [usersData, setdata] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [id, setId] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [updateList, setUpdateList] = useState(false);
  const [notInsertedRecords, setNotInsertedRecords] = useState();
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [csvModel, setCsvModel] = useState(false);
  const [csvReportModel, setCsvReportModel] = useState(false);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  // const [uploadingFiles, setUploadingFiles] = useState([]);
  const [showDialog, setShowDialog] = useState(true);
  const [uploadCanceled, setUploadCanceled] = useState(false);
  // const [successUploadCount, setSuccessUploadCount] = useState(0);
  const [failedUploadCount, setFailedUploadCount] = useState(0);
  const [actualProgressCount, setActualProgressCount] = useState(0);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState("p.name");
  const [sortDirection, setSortDirection] = useState("asc");
  const [isAccordianOpen, setIsAccordianOpen] = useState(false);

  const [headings, setHeadings] = useState([]);
  const [selectedHeadings, setSelectedHeadings] = useState([]);

  const toggleAccordion = () => {
    setIsAccordianOpen(!isAccordianOpen);
  };

  const fixedColumns = [
    { columnName: "QRCode", alternateColumnName: "", visible: false },
    { columnName: "LegacyCode", alternateColumnName: "", visible: false },
    { columnName: "Level", alternateColumnName: "", visible: false },
    { columnName: "RoomNumber", alternateColumnName: "", visible: false },
    { columnName: "RoomName", alternateColumnName: "", visible: false },
    { columnName: "Description", alternateColumnName: "", visible: false },
    { columnName: "Quantity", alternateColumnName: "", visible: false },
    { columnName: "ItemType", alternateColumnName: "", visible: false },
    { columnName: "DFECode", alternateColumnName: "", visible: false },
    { columnName: "Condition", alternateColumnName: "", visible: false },
    { columnName: "LifeExpectancy", alternateColumnName: "", visible: false },
    { columnName: "MakeModel", alternateColumnName: "", visible: false },
    { columnName: "Length", alternateColumnName: "", visible: false },
    { columnName: "Depth", alternateColumnName: "", visible: false },
    { columnName: "Height", alternateColumnName: "", visible: false },
    { columnName: "PrimaryColour", alternateColumnName: "", visible: false },
    { columnName: "SecondaryColour", alternateColumnName: "", visible: false },
    { columnName: "DFEStandard", alternateColumnName: "", visible: false },
    { columnName: "Comments", alternateColumnName: "", visible: false },
    { columnName: "Cost", alternateColumnName: "", visible: false },
    { columnName: "CarbonSavingKG", alternateColumnName: "", visible: false },
    { columnName: "NewRoomName", alternateColumnName: "", visible: false },
    { columnName: "NewRoomNumber", alternateColumnName: "", visible: false },
    { columnName: "PhotographCode", alternateColumnName: "", visible: false },
    // { columnName: 'Photograph', alternateColumnName: '', visible: true },
  ];
  const [columnData, setColumnData] = useState(fixedColumns);
  // Function to generate the column JSON
  const generateColumnJSON = () => {
    const columnJSON = {};
    columnData.forEach((column) => {
      columnJSON[column.columnName] = {
        ALternateColumnName: column.alternateColumnName,
        Visible: column.visible,
      };
    });
    return JSON.stringify(columnJSON);
  };

  const generateDetailsColumnJSON = (transformedDetailData) => {
    const columnJSON = {};

    // Loop through columnData and check for visibility from transformedDetailData
    columnData.forEach((column) => {
      // Find the matching column in transformedDetailData by columnName
      const transformedColumn = transformedDetailData.find(
        (transformed) => transformed.columnName === column.columnName
      );

      columnJSON[column.columnName] = {
        ALternateColumnName: column.alternateColumnName,
        // Update the Visible field based on transformedDetailData match
        Visible: transformedColumn ? transformedColumn.visible : column.visible,
      };
    });

    return JSON.stringify(columnJSON);
  };

  // Function to handle updating the column data
  const handleUpdateColumnData = (index, key, value) => {
    const updatedColumnData = [...columnData];
    updatedColumnData[index][key] = value;

    if (value === "") {
      updatedColumnData[index].visible = false;
    } else if (key === "alternateColumnName") {
      // If a valid alternate column name is selected, set the visibility to true
      updatedColumnData[index].visible = true;
    }

    setColumnData(updatedColumnData);

    // Update visibility to true when alternateColumnName is selected
    // if (key === 'alternateColumnName') {
    //     updatedColumnData[index].visible = true;
    // }

    if (key === "alternateColumnName") {
      const updatedSelectedHeadings = [...selectedHeadings];
      updatedSelectedHeadings[index] = value;
      setSelectedHeadings(updatedSelectedHeadings);
    }
  };

  useEffect(() => {
    const updatedSelectedHeadings = columnData.map(
      (column) => column.alternateColumnName
    );
    setSelectedHeadings(updatedSelectedHeadings);
  }, [columnData]);

  const GetAllProjectList = (
    sort_column_name,
    sort_column_dir,
    page,
    page_size
  ) => {
    const sortcolumn = !!sort_column_name ? sort_column_name : null;
    const sortdir = !!sort_column_dir ? sort_column_dir : null;
    dispatch(
      listProjects({
        clientId: userId,
        loginUserId: loginUserId,
        searchTerm: !!searchText ? searchText : null,
        sortBy: sortcolumn,
        sortDirection: sortdir,
        page: page,
        pageSize: page_size,
      })
    );
  };

  const handleSort = (column, sortDirection) => {
    if (Object.keys(column).length !== 0 && !!sortDirection) {
      setSortBy(column.sortField);
      setSortDirection(sortDirection);
      GetAllProjectList(column.sortField, sortDirection, page, pageSize);
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
    GetAllProjectList(sortBy, sortDirection, page, pageSize);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPageSize(newPerPage);
    setPage(page);
    GetAllProjectList(sortBy, sortDirection, page, newPerPage);
  };

  useEffect(() => {
    GetAllProjectList("p.name", "asc", 1, 10);
  }, [userId, searchText]);

  useEffect(() => {
    setFilteredData(projects?.projects?.listData);
  }, [projects]);

  // useEffect(() => {
  //   const fd =
  //     filteredData &&
  //     filteredData.filter(
  //       (item) =>
  //         item?.name?.toLowerCase().includes(searchText?.toLowerCase()) ||
  //         item?.email?.toLowerCase().includes(searchText?.toLowerCase())
  //     );
  //   setFilteredData(fd);
  // }, [searchText]);

  useEffect(() => {
    if (isEdit && id) {
      const project = data && data.find((item) => item.id === id);

      const projectcolumnJSON = JSON.parse(project.columnJSON);
      // Ensure columnData is initialized as an array
      let convertedData = [];
      if (projectcolumnJSON && typeof projectcolumnJSON === "object") {
        convertedData = Object.keys(projectcolumnJSON).map((key) => ({
          columnName: key,
          alternateColumnName: projectcolumnJSON[key].ALternateColumnName,
          visible: projectcolumnJSON[key].Visible,
        }));
      }
      if (!!projectcolumnJSON) setColumnData(convertedData);
      else setColumnData(fixedColumns);

      formik.setValues({
        name: project.name,
        createdDate: project.createdDate.split("T")[0],
        author: project.author,
        ffeSurveyors: project.ffeSurveyors,
      });
      setId(project.id);
    } else {
      formik.setValues({
        name: "",
        createdDate: "",
        author: "",
        ffeSurveyors: "",
      });
    }
  }, [isEdit, id]);

  const getStatusClassName = (status) => {
    const statusValue = status.replace(/\s/g, "").toLowerCase();
    switch (statusValue) {
      case "notstarted":
        return "danger";
      case "inprogress":
        return "orange";
      case "published":
        return "success";
      default:
        return "danger";
    }
  };

  const validationSchema = isEdit ? editValidationSchema : addValidationSchema;

  const formik = useFormik({
    initialValues: {
      name: "",
      createdDate: "",
      author: "",
      ffeSurveyors: "",
    },
    validationSchema,
    onSubmit: async () => {
      openModal(true);
      // const password = generatePassword();
      if (formik.isValid) {
        const columnJSON = generateColumnJSON();
        if (isEdit) {
          const res = await dispatch(
            editProject({
              id,
              ...formik.values,
              clientId: userId,
              createdBy: loginUserId,
              createdDate: !!formik.values.createdDate
                ? formik.values.createdDate
                : null,
              author: formik.values.author,
              ffeSurveyors: formik.values.ffeSurveyors,
              columnJSON: columnJSON,
            })
          );
          closeModal();
          Swal.fire({
            title: res?.payload?.message,
            confirmButtonColor: "#000000",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "confirm-button-swal",
            },
          });
          setUpdateList(true);
        } else {
          const res = await dispatch(
            addProject({
              createdDate: !!formik.values.createdDate
                ? formik.values.createdDate
                : null,
              author: formik.values.author,
              ffeSurveyors: formik.values.ffeSurveyors,
              name: formik.values.name,
              clientId: userId,
              createdBy: loginUserId,
              columnJSON: columnJSON,
            })
          );
          closeModal();
          if (res.payload.result > 0) {
            Swal.fire({
              title: res?.payload?.message,
              confirmButtonColor: "#000000",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "confirm-button-swal",
              },
            });
          } else {
            Swal.fire({
              title: res?.payload?.message,
              confirmButtonColor: "#000000",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "confirm-button-swal",
              },
            });
          }
          setUpdateList(true);
        }
        setUpdateList(true);
        GetAllProjectList("p.name", "asc", page, pageSize);
        setIsEdit(false);
      }
    },
  });

  const handleDeleteProject = async (row) => {
    var res;
    Swal.fire({
      title: "Are you sure?",
      html: `You want to delete <b>${row?.name}</b>!`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#000000",
      cancelButtonColor: "#dd3333",
      confirmButtonText: "Delete",
      customClass: {
        confirmButton: "confirm-button-swal",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        res = await dispatch(deleteProject(row.id));
        Swal.fire({
          title: res?.payload?.message,
          confirmButtonColor: "#000000",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "confirm-button-swal",
          },
        });
        GetAllProjectList("p.name", "asc", page, pageSize);
      }
    });
  };
  const publishProjectStatus = (projectId, projectName) => {
    Swal.fire({
      title: "Are you sure?",
      html: `You want to publish <b>${projectName}</b>!`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#000000",
      cancelButtonColor: "#dd3333",
      confirmButtonText: "Publish",
      customClass: {
        confirmButton: "confirm-button-swal",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await dispatch(
          publishProject({
            project_id: projectId,
            published_by: loginUserId,
            projectName: projectName,
            email: email,
          })
        );
        if (res.payload.result > 0) {
          GetAllProjectList("p.name", "asc", page, pageSize);
          Swal.fire({
            title: res?.payload?.message,
            confirmButtonColor: "#000000",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "confirm-button-swal",
            },
          });
        } else {
          Swal.fire({
            title: res?.payload?.message,
            confirmButtonColor: "#000000",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "confirm-button-swal",
            },
          });
        }
      }
    });
  };

  const selectInputValidationSchema = Yup.object().shape({
    csvfile: Yup.mixed()
      .required("Please select file")
      .test(
        "type",
        "Only the following formats are accepted: .csv, .xls, .xlsx",
        (value) => {
          return (
            value &&
            (value.type === ".csv" ||
              value.type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
              value.type === "text/csv" ||
              value.type === "application/vnd.ms-excel")
          );
        }
      ),
  });

  const formikSubmitCSV = useFormik({
    initialValues: {
      csvfile: null,
      projectId: null,
    },
    validationSchema: selectInputValidationSchema,
    onSubmit: async () => {
      if (formikSubmitCSV.isValid) {
        const res = await handleFileUpload(
          formikSubmitCSV.values.csvfile,
          formikSubmitCSV.values.projectId
        );
        if (res.payload.result >= 0) {
          if (!!res.payload.legacyCode) {
            formikSubmitCSV.resetForm();
            setCsvModel(false);
            setIsOpenModel(true);
            GetAllProjectList("p.name", "asc", page, pageSize);
            setNotInsertedRecords(res.payload.legacyCode.split(","));
            Swal.fire({
              title: res?.payload?.message,
              confirmButtonColor: "#000000",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "confirm-button-swal",
              },
            });
          } else {
            formikSubmitCSV.resetForm();
            setCsvModel(false);
            GetAllProjectList("p.name", "asc", page, pageSize);
            Swal.fire({
              title: res?.payload?.message,
              confirmButtonColor: "#000000",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "confirm-button-swal",
              },
            });
          }
        }
      }
    },
  });

  const handleFileChange = (e) => {
    formikSubmitCSV.setFieldValue("csvfile", e.target.files[0]);
  };
  const handleFileUpload = async (e, pid) => {
    return new Promise(async (resolve, reject) => {
      const file = e;

      if (file) {
        const reader = new FileReader();

        reader.onload = async (event) => {
          const binaryString = event.target.result;
          const workbook = XLSX.read(binaryString, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const data = XLSX.utils.sheet_to_json(sheet, {
            header: 1,
            blankrows: false,
          });
          const jsonData = JSON.parse(JSON.stringify(data));

          const SurveyorName = data[0].indexOf("surveyor_name");
          const Level = data[0].indexOf("level");
          const RoomNumber = data[0].indexOf("room_number");
          const RoomName = data[0].indexOf("room_name");
          const Description = data[0].indexOf("description");
          const Quantity = data[0].indexOf("quantity");
          const Condition = data[0].indexOf("condition");
          const MakeModel = data[0].indexOf("makemodel");
          const Length = data[0].indexOf("length");
          const Depth = data[0].indexOf("depth");
          const Height = data[0].indexOf("height");
          const PrimaryColour = data[0].indexOf("primary_colour");
          const SecondaryColour = data[0].indexOf("secondary_colour");
          const Comments = data[0].indexOf("comments");
          const PhotographCode = data[0].indexOf("photos");
          // const PhotosCaption = data[0].indexOf("photos_caption");
          // const PhotosURL = data[0].indexOf("photos_url");
          const QRCode = data[0].indexOf("qr_code");
          const LegacyCode = data[0].indexOf("legacy_code");

          const trimAndSanitize = (str) =>
            str.replace(/[,\s/()\-_]/g, "").toLowerCase();

          const initialColumnData = data[0].reduce((acc, heading) => {
            var trimmedHeading = trimAndSanitize(heading);

            // Check if heading is "photos" and set to "photographcode"
            if (trimmedHeading === "photos") {
              trimmedHeading = "photographcode";
            }
            acc[trimmedHeading] = {
              alternateColumnName: heading,
              visible: true,
            };
            return acc;
          }, {});

          const transformedDetailData = fixedColumns.map((column) => {
            const trimmedColumnName = trimAndSanitize(column.columnName);
            const columnData = initialColumnData[trimmedColumnName];

            return {
              ...column,
              alternateColumnName: columnData
                ? columnData.alternateColumnName
                : "",
              visible: columnData ? columnData.visible : false,
            };
          });

          setColumnData(transformedDetailData);

          const columnJSONString = generateDetailsColumnJSON(
            transformedDetailData
          );

          const transformedData = jsonData
            .filter((x, i) => i !== 0)
            .map((data, index) => {
              return {
                projectId: pid,
                surveyorName: !!data[SurveyorName]
                  ? data[SurveyorName].toString()
                  : null,
                level: !!data[Level] ? data[Level].toString() : null,
                roomNumber: !!data[RoomNumber]
                  ? data[RoomNumber].toString()
                  : null,
                roomName: !!data[RoomName] ? data[RoomName].toString() : null,
                rawDescription: !!data[Description]
                  ? data[Description].toString()
                  : null,
                quantity: !!data[Quantity] ? data[Quantity].toString() : null,
                condition: !!data[Condition]
                  ? data[Condition].toString()
                  : null,
                makeModel: !!data[MakeModel]
                  ? data[MakeModel].toString()
                  : null,
                length: !!data[Length] ? data[Length].toString() : null,
                depth: !!data[Depth] ? data[Depth].toString() : null,
                height: !!data[Height] ? data[Height].toString() : null,
                rawPrimaryColour: !!data[PrimaryColour]
                  ? data[PrimaryColour].toString()
                  : null,
                rawSecondaryColour: !!data[SecondaryColour]
                  ? data[SecondaryColour].toString()
                  : null,
                comments: !!data[Comments] ? data[Comments].toString() : null,
                photograph: !!data[PhotographCode]
                  ? data[PhotographCode].toString()
                  : null,
                isActive: true,
                isDeleted: true,
                createdBy: loginUserId,
                createdDateTime: new Date(),
                qrCode: !!data[QRCode] ? data[QRCode].toString() : null,
                legacyCode: !!data[LegacyCode]
                  ? data[LegacyCode].toString()
                  : null,
              };
            });

          const res = await dispatch(
            addProjectDetails({
              projectDetailsList: transformedData,
              isUpdated: false,
              columnJSON: columnJSONString,
            })
          );
          resolve(res);
          //GetAllProjectList("p.name", "asc", page, pageSize);
        };

        reader.readAsBinaryString(file);
      }
    });
  };
  const formikSubmitReportCSV = useFormik({
    initialValues: {
      csvfile: null,
      projectId: null,
    },
    validationSchema: selectInputValidationSchema,
    // validate: values => {
    //   const errors = {};
    //   if (!values.csvfile) {
    //     errors.csvfile = 'CSV file is required';
    //   }
    //   if (!values.projectId) {
    //     errors.projectId = 'Project ID is required';
    //   }
    //   return errors;
    // },
    onSubmit: async () => {
      if (formikSubmitReportCSV.isValid) {
        setIsAccordianOpen(false);
        const res = await handleReportFileUpload(
          formikSubmitReportCSV.values.csvfile,
          formikSubmitReportCSV.values.projectId
        );
        if (res.payload.result >= 0) {
          if (!!res.payload.legacyCode) {
            formikSubmitReportCSV.resetForm();
            setCsvReportModel(false);
            setIsOpenModel(true);
            GetAllProjectList("p.name", "asc", page, pageSize);
            setNotInsertedRecords(res.payload.legacyCode.split(","));
            Swal.fire({
              title: res?.payload?.message,
              confirmButtonColor: "#000000",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "confirm-button-swal",
              },
            });
          } else {
            formikSubmitReportCSV.resetForm();
            setCsvReportModel(false);
            GetAllProjectList("p.name", "asc", page, pageSize);
            Swal.fire({
              title: res?.payload?.message,
              confirmButtonColor: "#000000",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "confirm-button-swal",
              },
            });
          }
        }
      }
    },
  });

  function getFileExtension(filename) {
    // Use regex to extract the extension from the filename
    const match = filename.match(/\.([a-zA-Z0-9]+)$/);
    return match ? match[1] : null;
  }
  const handleReportFileChange = (e) => {
    const extension = getFileExtension(e.target.files[0].name);
    if (extension === "csv" || extension === "xls" || extension === "xlsx") {
      setIsAccordianOpen(true);
      const file = e.target.files[0]; //.values.csvfile;
      const files = formikSubmitReportCSV;

      if (file) {
        const reader = new FileReader();

        reader.onload = async (event) => {
          const binaryString = event.target.result;
          const workbook = XLSX.read(binaryString, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const data = XLSX.utils.sheet_to_json(sheet, {
            header: 1,
            blankrows: false,
          });
          const jsonData = JSON.parse(JSON.stringify(data));
          const json = XLSX.utils.sheet_to_json(sheet, {
            header: 1,
            blankrows: false,
          });
          setHeadings(json[0]);

          const trimAndSanitize = (str) =>
            str.replace(/[,\s/()\-_]/g, "").toLowerCase();

          // Create initialColumnData with trimmed, sanitized, and lowercase keys
          const initialColumnData = json[0].reduce((acc, heading) => {
            var trimmedHeading = trimAndSanitize(heading);
            // Check if heading is "photos" and set to "photographcode"
            if (trimmedHeading === "photos") {
              trimmedHeading = "photographcode";
            }
            acc[trimmedHeading] = {
              alternateColumnName: heading,
              visible: true,
            };
            return acc;
          }, {});

          // Update predefined columns visibility based on initial column data
          const transformedData = fixedColumns.map((column) => {
            const trimmedColumnName = trimAndSanitize(column.columnName);
            const columnData = initialColumnData[trimmedColumnName];

            return {
              ...column,
              alternateColumnName: columnData
                ? columnData.alternateColumnName
                : "",
              visible: columnData ? columnData.visible : false,
            };
          });

          setColumnData(transformedData);
          setSelectedHeadings(json[0]);
        };

        reader.readAsBinaryString(file); // This line was missing
      }
    }
    formikSubmitReportCSV.setFieldValue("csvfile", e.target.files[0]);
  };

  const handleReportFileUpload = async (e, pid) => {
    const columnJSON = generateColumnJSON();
    const project = usersData && usersData.find((item) => item.id === pid);
    await dispatch(
      editProject({
        id,
        name: project.name,
        createdDate: project.createdDate,
        ffeSurveyors: project.ffeSurveyors,
        author: project.author,
        clientId: userId,
        columnJSON: columnJSON,
      })
    );
    return new Promise(async (resolve, reject) => {
      const file = e;

      if (file) {
        const reader = new FileReader();

        reader.onload = async (event) => {
          const binaryString = event.target.result;
          const workbook = XLSX.read(binaryString, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const data = XLSX.utils.sheet_to_json(sheet, {
            header: 1,
            blankrows: false,
          });
          const jsonData = JSON.parse(JSON.stringify(data));
          setHeadings(data[0]);

          let convertedData = [];

          const projectcolumnJSON = !!columnJSON && JSON.parse(columnJSON);
          if (projectcolumnJSON && typeof projectcolumnJSON === "object") {
            convertedData = Object.keys(projectcolumnJSON).map((key) => ({
              columnName: key,
              alternateColumnName: projectcolumnJSON[key].ALternateColumnName,
              visible: projectcolumnJSON[key].Visible,
            }));
          }
          function findAlternateName(name) {
            const column =
              !!convertedData &&
              convertedData.find((column) => column.columnName === name);
            return column ? column.alternateColumnName : null;
          }
          const LevelAlternate = findAlternateName("Level")
            ? findAlternateName("Level")
            : "Level";
          const RoomNumberAlternate = findAlternateName("RoomNumber")
            ? findAlternateName("RoomNumber")
            : "RoomNumber";
          const RoomNameAlternate = findAlternateName("RoomName")
            ? findAlternateName("RoomName")
            : "RoomName";
          const DescriptionAlternate = findAlternateName("Description")
            ? findAlternateName("Description")
            : "Description";
          const QuantityAlternate = findAlternateName("Quantity")
            ? findAlternateName("Quantity")
            : "Quantity";
          const ConditionAlternate = findAlternateName("Condition")
            ? findAlternateName("Condition")
            : "Condition";
          const MakeModelAlternate = findAlternateName("MakeModel")
            ? findAlternateName("MakeModel")
            : "MakeModel";
          const LengthAlternate = findAlternateName("Length")
            ? findAlternateName("Length")
            : "Length";
          const DepthAlternate = findAlternateName("Depth")
            ? findAlternateName("Depth")
            : "Depth";
          const HeightAlternate = findAlternateName("Height")
            ? findAlternateName("Height")
            : "Height";
          const PrimaryColourAlternate = findAlternateName("PrimaryColour")
            ? findAlternateName("PrimaryColour")
            : "PrimaryColour";
          const SecondaryColourAlternate = findAlternateName("SecondaryColour")
            ? findAlternateName("SecondaryColour")
            : "SecondaryColour";
          const CommentsAlternate = findAlternateName("Comments")
            ? findAlternateName("Comments")
            : "Comments";
          const PhotographCodeAlternate = findAlternateName("PhotographCode")
            ? findAlternateName("PhotographCode")
            : "PhotographCode";
          const QRCodeAlternate = findAlternateName("QRCode")
            ? findAlternateName("QRCode")
            : "QRCode";
          const LegacyCodeAlternate = findAlternateName("LegacyCode")
            ? findAlternateName("LegacyCode")
            : "LegacyCode";
          const CarbonSavingKGAlternate = findAlternateName("CarbonSavingKG")
            ? findAlternateName("CarbonSavingKG")
            : "CarbonSavingKG";
          const ItemTypeAlternate = findAlternateName("ItemType")
            ? findAlternateName("ItemType")
            : "ItemType";
          const LifeExpectancyAlternate = findAlternateName("LifeExpectancy")
            ? findAlternateName("LifeExpectancy")
            : "LifeExpectancy";
          const DFECodeAlternate = findAlternateName("DFECode")
            ? findAlternateName("DFECode")
            : "DFECode";
          const DFEStandardAlternate = findAlternateName("DFEStandard")
            ? findAlternateName("DFEStandard")
            : "DFEStandard";
          const CostAlternate = findAlternateName("Cost")
            ? findAlternateName("Cost")
            : "Cost";
          const NewRoomNameAlternate = findAlternateName("NewRoomName")
            ? findAlternateName("NewRoomName")
            : "NewRoomName";
          const NewRoomNumberAlternate = findAlternateName("NewRoomNumber")
            ? findAlternateName("NewRoomNumber")
            : "NewRoomNumber";

          const SurveyorName = data[0].indexOf("Surveyor Name");
          const Level = data[0].indexOf(LevelAlternate);
          const RoomNumber = data[0].indexOf(RoomNumberAlternate);
          const RoomName = data[0].indexOf(RoomNameAlternate);
          const Description = data[0].indexOf(DescriptionAlternate);
          const Quantity = data[0].indexOf(QuantityAlternate);
          const Condition = data[0].indexOf(ConditionAlternate);
          const MakeModel = data[0].indexOf(MakeModelAlternate);
          const Length = data[0].indexOf(LengthAlternate);
          const Depth = data[0].indexOf(DepthAlternate);
          const Height = data[0].indexOf(HeightAlternate);
          const PrimaryColour = data[0].indexOf(PrimaryColourAlternate);
          const SecondaryColour = data[0].indexOf(SecondaryColourAlternate);
          const Comments = data[0].indexOf(CommentsAlternate);
          //const PhotographCode = data[0].indexOf("Photograph Code");
          // const PhotosCaption = data[0].indexOf("photos_caption");
          // const PhotosURL = data[0].indexOf("photos_url");
          const QRCode = data[0].indexOf(QRCodeAlternate);
          const LegacyCode = data[0].indexOf(LegacyCodeAlternate);
          const CarbonSavingKG = data[0].indexOf(CarbonSavingKGAlternate);
          const ItemType = data[0].indexOf(ItemTypeAlternate);
          const LifeExpectancy = data[0].indexOf(LifeExpectancyAlternate);
          const DFECode = data[0].indexOf(DFECodeAlternate);
          const DFEStandard = data[0].indexOf(DFEStandardAlternate);
          const PhotographCode = data[0].indexOf(PhotographCodeAlternate);
          const Cost = data[0].indexOf(CostAlternate);
          const NewRoomName = data[0].indexOf(NewRoomNameAlternate);
          const NewRoomNumber = data[0].indexOf(NewRoomNumberAlternate);

          const transformedData = jsonData
            .filter((x, i) => i !== 0)
            .map((data, index) => {
              return {
                projectId: pid,
                surveyorName: !!data[SurveyorName]
                  ? data[SurveyorName].toString()
                  : null,
                level: !!data[Level] ? data[Level].toString() : null,
                roomNumber: !!data[RoomNumber]
                  ? data[RoomNumber].toString()
                  : null,
                roomName: !!data[RoomName] ? data[RoomName].toString() : null,
                rawDescription: !!data[Description]
                  ? data[Description].toString()
                  : null,
                quantity: !!data[Quantity] ? data[Quantity].toString() : null,
                condition: !!data[Condition]
                  ? data[Condition].toString()
                  : null,
                makeModel: !!data[MakeModel]
                  ? data[MakeModel].toString()
                  : null,
                length: !!data[Length] ? data[Length].toString() : null,
                depth: !!data[Depth] ? data[Depth].toString() : null,
                height: !!data[Height] ? data[Height].toString() : null,
                primaryColour: !!data[PrimaryColour]
                  ? data[PrimaryColour].toString()
                  : null,
                secondaryColour: !!data[SecondaryColour]
                  ? data[SecondaryColour].toString()
                  : null,
                comments: !!data[Comments] ? data[Comments].toString() : null,
                photograph: !!data[PhotographCode]
                  ? data[PhotographCode].toString()
                  : null,
                isActive: true,
                isDeleted: true,
                createdBy: loginUserId,
                createdDateTime: new Date(),
                qrCode: !!data[QRCode] ? data[QRCode].toString() : null,
                legacyCode: !!data[LegacyCode]
                  ? data[LegacyCode].toString()
                  : null,
                carbonSavingKG: !!data[CarbonSavingKG]
                  ? data[CarbonSavingKG].toString()
                  : null,
                itemType: !!data[ItemType] ? data[ItemType].toString() : null,
                lifeExpectancy: !!data[LifeExpectancy]
                  ? data[LifeExpectancy].toString()
                  : null,
                esfaCode: !!data[DFECode] ? data[DFECode].toString() : null,
                abnormal: !!data[DFEStandard]
                  ? data[DFEStandard].toString()
                  : null,
                cost: !!data[Cost] ? data[Cost].toString() : null,
                newRoomName: !!data[NewRoomName]
                  ? data[NewRoomName].toString()
                  : null,
                newRoomNumber: !!data[NewRoomNumber]
                  ? data[NewRoomNumber].toString()
                  : null,
              };
            });
          const res = await dispatch(
            addProjectReportDetails({
              projectReportDetailsList: transformedData,
              isUpdated: false,
            })
          );
          resolve(res);
          //GetAllProjectList("p.name", "asc", page, pageSize);
        };

        reader.readAsBinaryString(file);
      }
    });
  };

  const handleEditProject = () => {
    formik.handleSubmit();
  };

  const handleAddProject = () => {
    setIsEdit(false);
    formik.handleSubmit();
  };

  useEffect(() => {
    const handleWindowClose = (event) => {
      if (!uploadCanceled && uploadingFiles.length > 0) {
        const confirmationMessage = "Are you sure you want to leave?";
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    window.addEventListener("beforeunload", handleWindowClose);

    return () => {
      window.removeEventListener("beforeunload", handleWindowClose);
    };
  }, [uploadCanceled, uploadingFiles]);

  // const clearUploadStatus = () => {
  //   setSuccessUploadCount(0);
  //   setUploadingFiles([]);
  // };

  const handleFolderChange = (e, id) => {
    const files = e.target.files;
    if (!files || files.length === 0) {
      Swal.fire({
        title: "The selected folder is empty!",
        confirmButtonColor: "#000000",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "confirm-button-swal",
        },
      });
    } else {
      // file.type === "image/jpeg"
      const imageFiles = Array.from(files).filter((file) =>
        file.type.startsWith("image/") && file.size > 0
      );
      if (imageFiles.length > 0) {
        Swal.fire({
          title: "Are you sure?",
          html: `You want to upload <b>${imageFiles.length} </b>Images!!`,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#000000",
          cancelButtonColor: "#dd3333",
          confirmButtonText: "Upload",
          customClass: {
            confirmButton: "confirm-button-swal",
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            setUploadingFiles([]);
            await handleFolderUpload(id, imageFiles);
          }
        });
      } else {
        Swal.fire({
          title: "Please select folder that contains images",
          confirmButtonColor: "#000000",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "confirm-button-swal",
          },
        });
      }
    }
  };

  const handleFolderUpload = async (projectId, files) => {
    const chunkSize = 20; // Number of files to upload in each chunk
    const totalChunks = Math.ceil(files.length / chunkSize); // Calculate total number of chunks
    let successCount = 0; // Track successful uploads
    let failedCount = 0; // Track failed uploads

    setSuccessUploadCount(0);
    setFailedUploadCount(0);

    const sortedFiles = Array.from(files).sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    setUploadingFiles(sortedFiles);

    for (let i = 0; i < totalChunks; i++) {
      const start = i * chunkSize;
      const end = Math.min(start + chunkSize, sortedFiles.length);
      const chunk = files.slice(start, end);
      const formData = new FormData();
      formData.append("projectId", projectId);
      chunk.forEach((file) => {
        formData.append("files", file, file.name);
      });

      try {
        const res = await dispatch(imagesUpload({ files: formData }));
        if (res.payload.result > 0) {
          setSuccessUploadCount((prevCount) => prevCount + res.payload.count);
        } else {
          setFailedUploadCount((prevCount) => prevCount + res.payload.count);
        }
      } catch (error) {
        console.error("Error uploading files:", error);
        setFailedUploadCount((prevCount) => prevCount + chunk.length);
      }
    }
  };

  useEffect(() => {
    if (projects?.projects?.listData?.length) {
      setUsersdata(projects?.projects?.listData);
      setdata(projects?.projects?.listData);
    }
    const project =
      projects.projects.listData &&
      projects.projects.listData.find((item) => item.clientId === userId);
    if (project && project.createdDate)
      Cookies.set("projectCreationDate", project.createdDate);
  }, [projects?.projects?.listData]);

  // useEffect(() => {
  //   const fd =
  //     data &&
  //     data.filter(
  //       (item) =>
  //         item?.name?.toLowerCase().includes(searchText?.toLowerCase()) ||
  //         item?.email?.toLowerCase().includes(searchText?.toLowerCase())
  //     );
  //   setFilteredData(fd);
  // }, [data, searchText]);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsAccordianOpen(false);
    setColumnData(fixedColumns);
    formik.resetForm();
    setIsEdit(false);
    setId(0);
    setIsOpen(false);
  }

  const ImportProjectDetailsFileTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {/* Import Project Details */}
      Import Fulcrum Data
    </Tooltip>
  );

  const ImportProjectReportFileTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {/* Import Project Report */}
      Import Final Project Report
    </Tooltip>
  );

  const ViewProjectDetailsTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View Project Details
    </Tooltip>
  );

  const PublishProjectDetailsTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Publish
    </Tooltip>
  );

  const AddProjectTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Add Project
    </Tooltip>
  );

  const EditProjectTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Edit Project
    </Tooltip>
  );

  const DeleteProjectTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Delete Project
    </Tooltip>
  );

  const ViewProjectReportTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Project Report
    </Tooltip>
  );

  const ExportProjectReportTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Export Report
    </Tooltip>
  );

  const UploadImagesTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Upload Images
    </Tooltip>
  );
  const handleExportToExcel = (row) => {
    const datas = {
      page_number: 1,
      page_size: 1,
      projectId: row,
    };
    let projectReportnoOfRecords = 0;
    dispatch(projectsReport(datas)).then((resData) => {
      projectReportnoOfRecords = resData.payload.noOfRecords;
      const data = {
        page_number: 1,
        page_size: projectReportnoOfRecords, //100,
        projectId: row,
      };

      const setAlignmetCenter = { vertical: "middle", horizontal: "center" };
      const setAlignmetRight = { vertical: "bottom", horizontal: "right" };
      const setAlignmetLeft = { vertical: "bottom", horizontal: "left" };
      const fontArialSmall = { font: { name: "Arial", size: 10.5 } };
      const boldAndArialSmall = {
        font: { name: "Arial", size: 10.5, bold: true },
      };
      const fontGreenArialSmall = {
        font: { name: "Arial", size: 10.5, color: { argb: "FF40c540" } },
      };
      const fontRedArialSmall = {
        font: { name: "Arial", size: 10.5, color: { argb: "FFe31d1d" } },
      };
      // dispatch(projectsReport(data)).then((data) => {
      //   const projectReport = data.payload;
      Swal.fire({
        title: "Download?",
        html: "Project report.",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#000000",
        cancelButtonColor: "#dd3333",
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "confirm-button-swal",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          dispatch(projectsReport(data)).then((data) => {
            const projectcolumnJSON =
              !!data.payload.columnJSON && JSON.parse(data.payload.columnJSON);
            let convertedData = [];
            if (projectcolumnJSON && typeof projectcolumnJSON === "object") {
              convertedData = Object.keys(projectcolumnJSON).map((key) => ({
                columnName: key,
                alternateColumnName: projectcolumnJSON[key].ALternateColumnName,
                visible: projectcolumnJSON[key].Visible,
              }));
            }
            let columnData;
            if (!!projectcolumnJSON) columnData = convertedData;
            else columnData = fixedColumns;

            const projectReport = data.payload;
            if (!!projectReport.projectName) {
              const projectName = projectReport.projectName;
              const ffeSurveyors = projectReport.ffeSurveyors;
              const author = projectReport.author;
              const reportRecordsCounts = projectReport.reportRecordsCounts;
              const conditionBreakdownList =
                projectReport.conditionBreakdownList;
              const projectReportList = projectReport.projectReportList;
              const setBorder = {
                top: { style: "thin", color: { argb: "FF000000" } },
                left: { style: "thin", color: { argb: "FF000000" } },
                bottom: { style: "thin", color: { argb: "FF000000" } },
                right: { style: "thin", color: { argb: "FF000000" } },
              };

              const workbook = new ExcelJS.Workbook();
              const worksheet = workbook.addWorksheet("Project Report");
              const boldStyle = { bold: true };
              worksheet.getCell("D3").value = "Detailed Legacy Survey";
              worksheet.getCell("D4").value = projectName;
              worksheet.getCell("C5").value = "Date";
              worksheet.getCell("C6").value = "FF&E Surveyors";
              worksheet.getCell("D6").value = ffeSurveyors;
              worksheet.getCell("C7").value = "Author";
              worksheet.getCell("D7").value = author;
              worksheet.getCell("C8").value = "Suitable to re-purpose";
              const cellAddFontBold = ["C5", "C6", "C7", "C8"];
              cellAddFontBold.forEach((cellAddress) => {
                worksheet.getCell(cellAddress).style = boldAndArialSmall;
              });
              worksheet.getCell("D5").value = new Date(
                reportRecordsCounts.projectCreationDate
              ).toLocaleDateString();
              worksheet.getCell("E5").value = "Potential Carbon Reduction";
              worksheet.getCell("F5").value =
                reportRecordsCounts.carbonSavingKG + " kg of Co2";
              worksheet.getCell("F5").style = fontGreenArialSmall;
              worksheet.getCell("E7").value = "DfE Standard Size Count";
              worksheet.getCell("F7").value = reportRecordsCounts.standardCount;
              worksheet.getCell("G7").value =
                reportRecordsCounts.standardPer + "%";
              worksheet.getCell("G7").style = fontGreenArialSmall;

              worksheet.getCell("E8").value = "DfE Abnormal Size Count";
              worksheet.getCell("F8").value = reportRecordsCounts.abnormalCount;
              worksheet.getCell("G8").value =
                reportRecordsCounts.abnormalPer + "%";
              worksheet.getCell("G8").style = fontRedArialSmall;

              const cellAddfontArialSmall = [
                "D5",
                "D6",
                "D7",
                "E5",
                "E7",
                "E8",
                "F7",
                "F8",
              ];
              cellAddfontArialSmall.forEach((cellAddress) => {
                worksheet.getCell(cellAddress).style = fontArialSmall;
              });

              const cellAddToBorder = [
                "C5",
                "C6",
                "C7",
                "C8",
                "D5",
                "D6",
                "D7",
                "D8",
                "E5",
                "E7",
                "E8",
                "F5",
                "F7",
                "F8",
                "G7",
                "G8",
              ];
              cellAddToBorder.forEach((cellAddress) => {
                worksheet.getCell(cellAddress).border = setBorder;
              });
              const cellAddToRightAlign = [
                "C5",
                "C6",
                "C7",
                "C8",
                "E5",
                "E7",
                "E8",
                "F7",
                "F8",
                "G7",
                "G8",
              ];
              cellAddToRightAlign.forEach((cellAddress) => {
                worksheet.getCell(cellAddress).alignment = setAlignmetRight;
              });
              const cellAddToLeftAlign = ["D5", "D6", "D7", "F5"];
              cellAddToLeftAlign.forEach((cellAddress) => {
                worksheet.getCell(cellAddress).alignment = setAlignmetLeft;
              });

              worksheet.getCell("G7").alignment = setAlignmetRight;
              worksheet.getCell("D3").style = {
                font: { name: "Arial", size: 10.5 },
              };
              worksheet.getCell("D4").style = {
                font: { name: "Arial", size: 10.5 },
              };
              worksheet.getCell("D3").border = {};
              worksheet.getCell("D4").border = {};

              worksheet.getCell("L1").value = "Condition Breakdown";
              worksheet.getCell("L1").style = {
                font: { name: "Arial", size: 10.5 },
              };
              const conditionBreakdownHeaders = [
                "Condition",
                "Life Expectancy",
                "Count",
                "Percentage",
              ];
              for (let i = 0; i < conditionBreakdownList.length; i++) {
                const rowData = conditionBreakdownList[i];

                const color = getColorHash(rowData.colour);
                const argb = !!color ? getColorARGB(color) : "FFFFFFFF";
                const setBackColor = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: argb },
                };
                worksheet.getCell(`L${i + 2}`).value = rowData.condition;
                worksheet.getCell(`M${i + 2}`).value = rowData.lifeExpectancy;
                worksheet.getCell(`N${i + 2}`).value = rowData.count;
                worksheet.getCell(`O${i + 2}`).value =
                  rowData.percentage != null ? rowData.percentage + "%" : "";
                worksheet.getCell(`L${i + 2}`).style = {
                  font: { name: "Arial", size: 10.5 },
                };
                worksheet.getCell(`M${i + 2}`).style = {
                  font: { name: "Arial", size: 10.5 },
                };
                worksheet.getCell(`N${i + 2}`).style = {
                  font: { name: "Arial", size: 10.5 },
                };
                worksheet.getCell(`O${i + 2}`).style = {
                  font: { name: "Arial", size: 10.5 },
                };
                worksheet.getCell(`L${i + 2}`).fill = setBackColor;
                worksheet.getCell(`M${i + 2}`).fill = setBackColor;
                worksheet.getCell(`N${i + 2}`).fill = setBackColor;
                worksheet.getCell(`O${i + 2}`).fill = setBackColor;
                worksheet.getCell(`L${i + 2}`).border = setBorder;
                worksheet.getCell(`M${i + 2}`).border = setBorder;
                worksheet.getCell(`N${i + 2}`).border = setBorder;
                worksheet.getCell(`O${i + 2}`).border = setBorder;
              }
              worksheet.getCell("Q2").value = "Colour Index Key";
              worksheet.getCell("Q2").style = {
                font: { name: "Arial", size: 10.5 },
              };
              for (let i = 0; i < conditionBreakdownList.length; i++) {
                const rowData = conditionBreakdownList[i];
                const color = getColorHash(rowData.colour);
                const argb = !!color ? getColorARGB(color) : "FFFFFFFF";
                worksheet.getCell(`Q${i + 3}`).value = " ";
                worksheet.getCell(`Q${i + 3}`).fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: argb },
                };
              }
              for (let i = 0; i < conditionBreakdownList.length - 1; i++) {
                worksheet.getCell(`Q${i + 3}`).border = setBorder;
              }
              for (let i = 0; i < conditionBreakdownList.length; i++) {
                const rowData = conditionBreakdownList[i];
                worksheet.getCell(`R${i + 3}`).value = rowData.description;
                worksheet.getCell(`R${i + 3}`).style = {
                  font: { name: "Arial", size: 10.5 },
                };
              }
              for (let i = 0; i < conditionBreakdownList.length - 1; i++) {
                worksheet.getCell(`R${i + 3}`).border = setBorder;
              }
              worksheet.columns.forEach((column) => {
                let maxLength = 0;
                column.eachCell({ includeEmpty: true }, (cell) => {
                  const length = cell.value ? cell.value.toString().length : 0;
                  if (length > maxLength) {
                    maxLength = length;
                  }
                });
                column.width = maxLength < 10 ? 10 : maxLength + 2;
              });

              // const mainTableHeaders = [
              //   'No.','Legacy code', 'Level', 'Room Number', 'Room Name', 'Description', 'Quantity', 'Item Type',
              //   'DFE Code', 'Condition', 'Life Expectancy', 'Make/Model', 'Length', 'Depth', 'Height',
              //   'Primary Colour', 'Secondary Colour', 'DFE standard', 'Comments', 'Cost', 'Carbon saving kg',
              //   'New Room Name', 'New Room Number'
              // ];
              // const keys = [
              //   1, 'legacyCode', 'level', 'roomNumber', 'roomName', 'description', 'quantity', 'itemType',
              //   'esfaCode', 'condition', 'lifeExpectancy', 'makeModel', 'length', 'depth', 'height',
              //   'primaryColour', 'secondaryColour', 'abnormal', 'comments', 'cost', 'carbonSavingKG',
              //   'newRoomName', 'newRoomNumber'
              // ];
              const mainTableHeaders = [];
              const keys = [];

              for (const column of columnData) {
                //const key = column.columnName.charAt(0).toLowerCase() + column.columnName.slice(1);
                let key;
                if (column.columnName == "QRCode") {
                  key = "qrCode";
                } else if (column.columnName == "DFEStandard") {
                  key = "abnormal";
                } else if (column.columnName == "DFECode") {
                  key = "esfaCode";
                } else {
                  key =
                    column.columnName.charAt(0).toLowerCase() +
                    column.columnName.slice(1);
                }
                if (column.visible) {
                  if (column.alternateColumnName !== "") {
                    mainTableHeaders.push(column.alternateColumnName);
                    keys.push(key);
                  } else {
                    mainTableHeaders.push(column.columnName);
                    keys.push(key);
                  }
                }
              }

              mainTableHeaders.unshift("No."); // Add "No." header at the beginning
              keys.unshift(1); // Add corresponding key for "No."

              let rowCounter = 1;
              const rows = projectReportList.map((item) => {
                const mtColor = getColorHash(item.colour);
                const mtArgb = !!mtColor ? getColorARGB(mtColor) : "FFFFFFFF";
                const noValue = rowCounter++;
                return mainTableHeaders.map((header) => {
                  const keyIndex = mainTableHeaders.indexOf(header);
                  if (header === "No.") {
                    return { value: noValue, color: "FFa6a6a6" };
                  } else if (header === "Legacy code") {
                    const value = item[keys[keyIndex]] || "";
                    return { value, color: "FFa6a6a6" };
                  } else {
                    const value = item[keys[keyIndex]] || "";
                    return { value, color: mtArgb };
                  }
                });
              });

              worksheet.addTable({
                name: "MyTable",
                ref: "B11",
                headerRow: true,
                style: {
                  showRowStripes: true,
                },
                columns: mainTableHeaders.map((header, index) => ({
                  name: header,
                  key: keys[index],
                  filterButton: true,
                })),
                rows: rows.map((row, rowIndex) => {
                  return row.map((cell, cellIndex) => {
                    const cellValue = cell.value;
                    const cellColor = cell.color;
                    const cellRef = worksheet.getCell(
                      rowIndex + 12,
                      cellIndex + 2
                    );
                    cellRef.style = { font: { name: "Arial", size: 10.5 } };
                    cellRef.fill = {
                      type: "pattern",
                      pattern: "solid",
                      fgColor: { argb: cellColor },
                    };

                    return cellValue;
                  });
                }),
              });

              const numColumns = mainTableHeaders.length;
              const numRows = projectReportList.length;

              for (let col = 1; col <= numColumns; col++) {
                const columnName = getExcelColumnName(col + 1);
                const headerText = mainTableHeaders[col - 1];
                const cell = worksheet.getCell(columnName + "11");
                cell.style = {
                  font: {
                    name: "Arial",
                    size: 10.5,
                    bold: true,
                    color: { argb: "FFFFFFFF" },
                  },
                };
                cell.border = setBorder;
                cell.alignment = setAlignmetCenter;
                cell.fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: "FF595959" }, //FFa6a6a6
                };

                const headerLength = headerText.length;
                const width = Math.max(20, headerLength + 2);

                worksheet.getColumn(columnName).width = width;
              }

              const startRow = 12;
              const startCol = 2;

              for (let row = startRow; row <= numRows + startRow - 1; row++) {
                for (
                  let col = startCol;
                  col <= numColumns + startCol - 1;
                  col++
                ) {
                  const cell = worksheet.getCell(getExcelColumnName(col) + row);
                  cell.border = setBorder;
                  cell.alignment = setAlignmetCenter;
                  worksheet.getRow(row).height = 50;
                }
              }

              // First, set frozen panes
              worksheet.views = [{ state: "frozen", xSplit: 3 }];

              // Then, remove grid lines
              worksheet.views[0].showGridLines = false;
              worksheet.freezePanes = "D3"; // This will freeze the panes from cell D3 onwards

              function getExcelColumnName(colNumber) {
                let dividend = colNumber;
                let columnName = "";
                let modulo;

                while (dividend > 0) {
                  modulo = (dividend - 1) % 26;
                  columnName = String.fromCharCode(65 + modulo) + columnName;
                  dividend = parseInt((dividend - modulo) / 26);
                }

                return columnName;
              }

              worksheet.columns.forEach((column) => {
                let maxLength = 0;
                column.eachCell({ includeEmpty: true }, (cell) => {
                  const length = cell.value ? cell.value.toString().length : 0;
                  if (length > maxLength) {
                    maxLength = length;
                  }
                });
                column.width = maxLength < 10 ? 10 : maxLength + 2; // set column width
              });
              workbook.xlsx.writeBuffer().then((buffer) => {
                const blob = new Blob([buffer], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = projectName + " & FF&E Legacy Survey.xlsx";
                document.body.appendChild(a);
                a.click();
                setTimeout(() => {
                  URL.revokeObjectURL(url);
                  document.body.removeChild(a);
                }, 0);
              });
            }
          });
        }
        // });
      });
    });
  };

  function getColorHash(colorName) {
    const colorHashes = {
      "report-row-bg-green": "#e7efd6",
      "report-row-bg-blue": "#cbac4f",
      "report-row-bg-purple": "#dce6f2",
      "report-row-bg-orange": "#e4dfec",
      "report-row-bg-beat": "#fdd5b4",
      "report-row-bg-gray": "#e6b8b7",
      "report-row-bg-yellow": "#f2f2f2",
    };
    return colorHashes[colorName];
  }

  function getColorARGB(colorHash) {
    const colorHex = colorHash.slice(1);
    const alpha = "FF";
    const argb = alpha + colorHex.toUpperCase();
    return argb;
  }

  const columns = [
    {
      name: "Project Name",
      maxWidth: "450px",
      sortable: true,
      sortField: "p.name",
      wrap: true,
      style: {
        cursor: "pointer",
      },
      selector: (row) =>
        !!userRole && (userRole === "Admin" || userRole === "Employee") ? (
          <h5
            onClick={() =>
              !row.isManualImport
                ? navigate(`/ProjectDetails/${row.id}/${userId}`)
                : navigate(`/ProjectReport/${row.id}/${userId}`)
            }
            className="table-avatar"
          >
            <i className="fs-4 me-2 bi bi-kanban" /> {row.name}
            {/* {capitalizeFirstWord(row.name)} */}
          </h5>
        ) : (
          <h5
            // onClick={() => navigate(`/ProjectDetails/${row.id}/${userId}`)}
            className="table-avatar"
          >
            <i className="fs-4 me-2 bi bi-kanban" /> {row.name}
            {/* {capitalizeFirstWord(row.name)} */}
          </h5>
        ),
    },
    {
      name: "Status",
      sortable: true,
      sortField: "status",
      maxWidth: "150px",
      selector: (row) => (
        <span
          className={`badge badge-light-${getStatusClassName(
            row.status
          )} fw-bold me-auto px-3 py-2`}
        >
          {row.status}
        </span>
      ),
    },
    {
      name: "Created By",
      sortField: "u.name",
      maxWidth: "150px",
      sortable: true,
      selector: (row) => row.createdBy,
    },
    {
      name: "Created On",
      sortable: true,
      sortField: "p.createdDate",
      maxWidth: "180px",
      selector: (row) => moment(row.createdDate).format("DD MMM YYYY hh:mm A"),
    },
    {
      name: "Action",
      minWidth: "285px !important",
      cell: (row) => (
        <>
          <div className={`actions`}>
            {!!userRole &&
              (userRole === "Admin" || userRole === "Employee") && (
                <>
                  {row.status.replace(/\s/g, "").toLowerCase() ===
                    "notstarted" && (
                    <OverlayTrigger
                      placement="top"
                      overlay={ImportProjectDetailsFileTooltip}
                    >
                      <label
                        className="btn btn-sm bg-info-subtle me-2"
                        onClick={() => {
                          setCsvModel(true);
                          formikSubmitCSV.setFieldValue("projectId", row.id);
                        }}
                      >
                        <i className="bi bi-file-earmark-arrow-up"></i>
                      </label>
                    </OverlayTrigger>
                  )}
                  {row.status.replace(/\s/g, "").toLowerCase() ===
                    "notstarted" && (
                    <OverlayTrigger
                      placement="top"
                      overlay={ImportProjectReportFileTooltip}
                    >
                      <label
                        className="btn btn-sm bg-warning-subtle me-2"
                        onClick={() => {
                          setId(row.id);
                          setCsvReportModel(true);
                          const project =
                            usersData &&
                            usersData.find((item) => item.id === row.id);
                          const projectcolumnJSON =
                            !!project.columnJSON &&
                            JSON.parse(project.columnJSON);
                          let convertedData = [];
                          if (
                            projectcolumnJSON &&
                            typeof projectcolumnJSON === "object"
                          ) {
                            convertedData = Object.keys(projectcolumnJSON).map(
                              (key) => ({
                                columnName: key,
                                alternateColumnName:
                                  projectcolumnJSON[key].ALternateColumnName ||
                                  projectcolumnJSON[key].AlternateColumnName, // Handle both keys
                                visible: projectcolumnJSON[key].Visible,
                              })
                            );
                          }

                          if (!!projectcolumnJSON) setColumnData(convertedData);
                          else setColumnData(fixedColumns);

                          formikSubmitReportCSV.setFieldValue(
                            "projectId",
                            row.id
                          );
                        }}
                      >
                        <i className="bi bi-cloud-arrow-up"></i>
                      </label>
                    </OverlayTrigger>
                  )}
                  {!row.isManualImport &&
                    row.status.replace(/\s/g, "").toLowerCase() !==
                      "notstarted" && (
                      <>
                        <OverlayTrigger
                          placement="top"
                          overlay={ViewProjectDetailsTooltip}
                        >
                          <Link
                            to={`/ProjectDetails/${row.id}/${userId}`}
                            className="btn btn-sm bg-warning-subtle me-2"
                          >
                            <i className="bi bi-eye" />
                          </Link>
                        </OverlayTrigger>
                      </>
                    )}

                  {row.status.replace(/\s/g, "").toLowerCase() ===
                    "inprogress" && (
                    <>
                      <OverlayTrigger
                        placement="top"
                        overlay={PublishProjectDetailsTooltip}
                      >
                        <label
                          className="btn btn-sm bg-success-subtle me-2"
                          onClick={() => publishProjectStatus(row.id, row.name)}
                        >
                          <i className="bi bi-check2-circle"></i>
                        </label>
                      </OverlayTrigger>
                    </>
                  )}
                  <OverlayTrigger placement="top" overlay={EditProjectTooltip}>
                    <a className="btn btn-sm bg-primary-subtle me-2 ">
                      <i
                        className="bi bi-pencil-square"
                        onClick={() => {
                          setId(row.id);
                          setIsEdit(true);
                          openModal(true);
                        }}
                      />
                    </a>
                  </OverlayTrigger>
                  {(userRole === "Admin" || userRole === "Employee") && (
                    <OverlayTrigger
                      placement="top"
                      overlay={DeleteProjectTooltip}
                    >
                      <a className="btn btn-sm bg-danger-subtle me-2 ">
                        <i
                          className="bi bi-trash3"
                          onClick={() => {
                            handleDeleteProject(row);
                          }}
                        />
                      </a>
                    </OverlayTrigger>
                  )}
                  {row.status.replace(/\s/g, "").toLowerCase() !==
                    "notstarted" &&
                    !!userRole &&
                    (userRole === "Admin" || userRole === "Employee") && (
                      <div>
                        <OverlayTrigger
                          placement="top"
                          overlay={UploadImagesTooltip}
                        >
                          <label
                            htmlFor={`folderUpload-${row.id}`}
                            className="btn btn-sm bg-primary-subtle me-2"
                          >
                            <i className="bi bi-upload" />
                          </label>
                        </OverlayTrigger>
                        <input
                          key={new Date().getTime()}
                          type="file"
                          id={`folderUpload-${row.id}`}
                          className="d-none"
                          onChange={(e) => handleFolderChange(e, row.id)}
                          directory=""
                          webkitdirectory=""
                          mozdirectory=""
                          msdirectory=""
                          odirectory=""
                        />
                      </div>
                    )}
                </>
              )}
            {row.status.replace(/\s/g, "").toLowerCase() === "published" && (
              <>
                <OverlayTrigger
                  placement="top"
                  overlay={ViewProjectReportTooltip}
                >
                  <Link
                    to={`/ProjectReport/${row.id}/${userId}`}
                    className="btn btn-sm bg-warning-subtle me-2"
                  >
                    <i className="bi bi-file-earmark-richtext" />
                  </Link>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={ExportProjectReportTooltip}
                >
                  <button
                    onClick={() => handleExportToExcel(row.id)}
                    className="btn btn-sm bg-warning-subtle me-2"
                  >
                    <i className="bi bi-save" />
                  </button>
                </OverlayTrigger>
              </>
            )}
          </div>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "200px",
    },
  ];
  return (
    <div>
      {loading && <Loader />}
      {/* {(loading || loadingImages) && <Loader />} */}

      <div className="mainContent px-4 pt-3">
        <div className="page-header mb-3">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <div className="page-sub-header">
                <h3 className="page-title">Project List</h3>
                <div className="page-title-right d-flex">
                  {!!userRole &&
                    (userRole === "Admin" || userRole === "Employee") && (
                      <>
                        <Link
                          className="btn btn-outline-dark ms-2 d-flex"
                          to={`/clients`}
                        >
                          <i className="bi bi-arrow-left me-2"></i> Back
                        </Link>
                        <OverlayTrigger
                          placement="top"
                          overlay={AddProjectTooltip}
                        >
                          <a
                            className="btn btn-primary ms-2"
                            // data-bs-toggle="modal"
                            // data-bs-target="#exampleModal"
                            onClick={() => openModal()}
                          >
                            <span
                              className=""
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title="Add Project"
                            >
                              <i className="bi bi-person-add"></i>
                            </span>
                          </a>
                        </OverlayTrigger>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="card shadow-sm border-0">
              <div className="card-body p-9">
                <div className="d-flex gap-3 p-3">
                  <div className="d-lg-flex gap-1  d-none">
                    <div className="client-icon">
                      {/* <i className="bi bi-people-fill"></i> */}
                      {userId && (
                        <img
                          src={`${IMAGE_BASE_URL}/${userId}.jpg?timestamp=${Date.now()}`}
                          alt="Logo"
                          className="project-logo-img client-logo"
                        />
                      )}
                    </div>
                    <div>
                      <h5 className="my-0">Company Name</h5>
                      {/* Client Name */}
                      <h6 className="my-0 fw-normal">
                        {!!clientName ? clientName : "-"}
                      </h6>
                    </div>
                  </div>
                  {/* {uploadingFiles.length > 0 && (
                    <div className="">
                      <div className="d-flex align-items-center">
                        {successUploadCount !== uploadingFiles.length ? (
                          <>
                            <div className="upload-status-box">
                              <div
                                className="spinner-border text-success me-2"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>

                              <div className="ms-3">
                                <h6 className="my-0">Uploading...</h6>
                                <div className="my-0 fw-normal">
                                  {successUploadCount}/{uploadingFiles.length}{" "}
                                  Images
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="upload-status-box-success">
                              <div>
                                <h5 className="my-0">Uploaded Successfully</h5>
                                <h6 className="my-0 fw-normal">
                                  {successUploadCount} Images
                                </h6>
                              </div>

                              <div className="ms-3">
                                <button
                                  type="button"
                                  className="btn-close"
                                  aria-label="Close"
                                  onClick={() => clearUploadStatus()}
                                ></button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )} */}
                </div>
                <DataTable
                  pagination
                  noHeader={true}
                  paginationTotalRows={noOfRecords}
                  columns={columns}
                  data={filteredData}
                  className="table table-bordered table-striped"
                  filterServer={true}
                  paginationServer
                  paginationRowsPerPageOptions={[10, 20, 30, 40]}
                  paginationPerPage={pageSize}
                  subHeader
                  subHeaderComponent={
                    <div className="col-md-2">
                      <input
                        onChange={(e) => setSearchText(e.target.value)}
                        placeholder="Search"
                        className="form form-control"
                      />
                    </div>
                  }
                  paginationDefaultPage={page}
                  sortServer
                  onSort={handleSort}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  highlightOnHover
                  searchableRows={true}
                  customStyles={customStylesDataTable}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        scrollable={true}
        show={modalIsOpen}
        aria-labelledby="contained-modal-title-vcenter"
        className="modal-lg"
        centered
        onHide={() => closeModal()}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {isEdit ? "Edit " : "Add "}Project
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {/* {!isEdit ? (
              <>
                <div className="col-md-12 mb-3">
                  <label htmlFor="name" className="form-label">
                    Project Name<span className="text-danger"> * </span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      formik.touched.name && formik.errors.name
                        ? "is-invalid"
                        : ""
                    }`}
                    id="name"
                    placeholder="Enter Project Name"
                    value={!!formik.values.name ? formik.values.name : ''}
                      // capitalizeFirstWord(formik.values.name) : ''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="invalid-feedback">{formik.errors.name}</div>
                  )}
                </div>
              </>
            ) : (
              <> */}
            <div className="col-md-6 mb-3">
              <label htmlFor="name" className="form-label">
                Project Name<span className="text-danger"> * </span>
              </label>
              <input
                type="text"
                className={`form-control ${
                  formik.touched.name && formik.errors.name ? "is-invalid" : ""
                }`}
                id="name"
                placeholder="Enter Project Name"
                value={!!formik.values.name ? formik.values.name : ""}
                // capitalizeFirstWord(formik.values.name) : ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.name && formik.errors.name && (
                <div className="invalid-feedback">{formik.errors.name}</div>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="createdDate" className="form-label">
                Project Date
              </label>
              <input
                type="date"
                className={`form-control ${
                  formik.touched.createdDate && formik.errors.createdDate
                    ? "is-invalid"
                    : ""
                }`}
                max={maxDate}
                min={minDate}
                id="createdDate"
                placeholder="Enter Date"
                value={
                  !!formik.values.createdDate ? formik.values.createdDate : ""
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.createdDate && formik.errors.createdDate && (
                <div className="invalid-feedback">
                  {formik.errors.createdDate}
                </div>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="author" className="form-label">
                Author<span className="text-danger"> * </span>
              </label>
              <input
                type="text"
                className={`form-control ${
                  formik.touched.author && formik.errors.author
                    ? "is-invalid"
                    : ""
                }`}
                id="author"
                placeholder="Enter Author"
                value={!!formik.values.author ? formik.values.author : ""}
                // capitalizeFirstWord(formik.values.author) : ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.author && formik.errors.author && (
                <div className="invalid-feedback">{formik.errors.author}</div>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="ffeSurveyors" className="form-label">
                FF&E Surveyors<span className="text-danger"> * </span>
              </label>
              <input
                type="text"
                className={`form-control ${
                  formik.touched.ffeSurveyors && formik.errors.ffeSurveyors
                    ? "is-invalid"
                    : ""
                }`}
                id="ffeSurveyors"
                placeholder="Enter FF&E Surveyors"
                value={
                  !!formik.values.ffeSurveyors ? formik.values.ffeSurveyors : ""
                }
                // capitalizeFirstWord(formik.values.name) : ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.ffeSurveyors && formik.errors.ffeSurveyors && (
                <div className="invalid-feedback">
                  {formik.errors.ffeSurveyors}
                </div>
              )}
            </div>
            {!!columnData && (
              <div>
                <div className="project-list-accordian-title pt-0 fs-5 ps-2">
                  {" "}
                  Column setting{" "}
                </div>
                <div
                  onClick={toggleAccordion}
                  className="icon-top-right-projectlist-accordion"
                >
                  {isAccordianOpen ? (
                    <i
                      className="bi bi-caret-up-fill"
                      style={{ fontSize: "1.2em" }}
                    ></i>
                  ) : (
                    <i
                      className="bi bi-caret-down-fill"
                      style={{ fontSize: "1.2em" }}
                    ></i>
                  )}
                </div>
              </div>
            )}
            {isAccordianOpen &&
              !!columnData &&
              columnData.map((column, index) => (
                <div className="col-md-4 mb-3" key={index}>
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <input
                          type="checkbox"
                          id={`visible-${index}`}
                          className="form-check-input me-2 mt-0"
                          checked={column.visible}
                          defaultChecked={false}
                          onChange={(e) =>
                            handleUpdateColumnData(
                              index,
                              "visible",
                              e.target.checked
                            )
                          }
                        />
                        {/* <h5 className="card-title h6">{column.columnName}</h5> */}
                        <h5 className="card-title h6">
                          {column.columnName === "RoomNumber"
                            ? "Room Number"
                            : "" || column.columnName === "QRCode"
                            ? "QR Code"
                            : "" || column.columnName === "LegacyCode"
                            ? "Legacy Code"
                            : "" || column.columnName === "Level"
                            ? "Level"
                            : "" || column.columnName === "RoomName"
                            ? "Room Name"
                            : "" || column.columnName === "Description"
                            ? "Description"
                            : "" || column.columnName === "Quantity"
                            ? "Quantity"
                            : "" || column.columnName === "ItemType"
                            ? "Item Type"
                            : "" || column.columnName === "DFECode"
                            ? "DFE Code"
                            : "" || column.columnName === "Condition"
                            ? "Condition"
                            : "" || column.columnName === "LifeExpectancy"
                            ? "Life Expectancy"
                            : "" || column.columnName === "MakeModel"
                            ? "Make Model"
                            : "" || column.columnName === "Length"
                            ? "Length"
                            : "" || column.columnName === "Depth"
                            ? "Depth"
                            : "" || column.columnName === "Height"
                            ? "Height"
                            : "" || column.columnName === "PrimaryColour"
                            ? "Primary Colour"
                            : "" || column.columnName === "SecondaryColour"
                            ? "Secondary Colour"
                            : "" || column.columnName === "DFEStandard"
                            ? "DFE Standard"
                            : "" || column.columnName === "Comments"
                            ? "Comments"
                            : "" || column.columnName === "Cost"
                            ? "Cost"
                            : "" || column.columnName === "CarbonSavingKG"
                            ? "Carbon Saving KG"
                            : "" || column.columnName === "NewRoomName"
                            ? "New Room Name"
                            : "" || column.columnName === "NewRoomNumber"
                            ? "New Room Number"
                            : "" || column.columnName === "PhotographCode"
                            ? "Photograph Code"
                            : ""}
                        </h5>
                      </div>
                      <div>
                        <input
                          placeholder="Alternate Name"
                          type="text"
                          id={`alternateColumnName-${index}`}
                          className="form-control"
                          value={column.alternateColumnName}
                          onChange={(e) =>
                            handleUpdateColumnData(
                              index,
                              "alternateColumnName",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {/* </>
            )} */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              closeModal();
            }}
          >
            Close
          </Button>
          <Button
            onClick={() => (isEdit ? handleEditProject() : handleAddProject())}
          >
            {isEdit ? "Update" : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isOpenModel}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setIsOpenModel(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Warning!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mb-3">
              <label htmlFor="name" className="form-label">
                Below Legacy code records are already inserted!
              </label>
              <div>
                {!!notInsertedRecords &&
                  notInsertedRecords.length > 0 &&
                  notInsertedRecords.map((x) => {
                    return (
                      <span
                        className={`badge badge-light-danger fw-bold me-auto px-3 py-2 mb-1 mx-1`}
                      >
                        {x}
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              setIsOpenModel(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={csvModel}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setCsvModel(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Import CSV
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mb-3">
              <label htmlFor="name" className="form-label">
                Select File<span className="text-danger"> * </span>
              </label>
              <input
                type="file"
                id="csvfile"
                className={`form-control ${
                  formikSubmitCSV.touched.csvfile &&
                  formikSubmitCSV.errors.csvfile
                    ? "is-invalid"
                    : ""
                }`}
                onChange={handleFileChange}
              />
              {formikSubmitCSV.touched.csvfile &&
                formikSubmitCSV.errors.csvfile && (
                  <div className="invalid-feedback">
                    {formikSubmitCSV.errors.csvfile}
                  </div>
                )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              formikSubmitCSV.resetForm();
              setCsvModel(false);
            }}
          >
            Close
          </Button>
          <Button onClick={() => formikSubmitCSV.handleSubmit()}>
            Import CSV
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={csvReportModel}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-lg"
        onHide={() => {
          setCsvReportModel(false);
          setIsAccordianOpen(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Import CSV for Project Report
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mb-3">
              <label htmlFor="name" className="form-label">
                Select File<span className="text-danger"> * </span>
              </label>
              <input
                type="file"
                id="csvfile"
                className={`form-control ${
                  formikSubmitReportCSV.touched.csvfile &&
                  formikSubmitReportCSV.errors.csvfile
                    ? "is-invalid"
                    : ""
                }`}
                onChange={handleReportFileChange}
              />
              {formikSubmitReportCSV.touched.csvfile &&
                formikSubmitReportCSV.errors.csvfile && (
                  <div className="invalid-feedback">
                    {formikSubmitReportCSV.errors.csvfile}
                  </div>
                )}
            </div>
            {/* {!!columnData && 
                <div>
                  <div className="project-list-accordian-title pt-0 fs-5 ps-2"> Column mapping </div>
                  <div onClick={toggleAccordion} className="icon-top-right-importReport-accordion">
                    {isAccordianOpen ? (
                      <i className="bi bi-caret-up-fill" style={{ fontSize: '1.2em' }}></i>
                    ) : (
                      <i className="bi bi-caret-down-fill" style={{ fontSize: '1.2em' }}></i>
                    )}
                  </div>
                </div>} */}
            {isAccordianOpen && (
              <div>
                <div className="project-list-accordian-title pt-0 fs-5 ps-2">
                  {" "}
                  Column mapping{" "}
                </div>
              </div>
            )}
            {isAccordianOpen &&
              !!columnData &&
              columnData.map((column, index) => (
                <div className="col-md-4 mb-3" key={index}>
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <input
                          type="checkbox"
                          id={`visible-${index}`}
                          className="form-check-input me-2 mt-0"
                          checked={column.visible}
                          // checked={false}
                          // defaultChecked={false}
                          onChange={(e) =>
                            handleUpdateColumnData(
                              index,
                              "visible",
                              e.target.checked
                            )
                          }
                        />
                        {/* <h5 className="card-title h6">{column.columnName}</h5> */}
                        <h5 className="card-title h6">
                          {column.columnName === "RoomNumber"
                            ? "Room Number"
                            : "" || column.columnName === "QRCode"
                            ? "QR Code"
                            : "" || column.columnName === "LegacyCode"
                            ? "Legacy Code"
                            : "" || column.columnName === "Level"
                            ? "Level"
                            : "" || column.columnName === "RoomName"
                            ? "Room Name"
                            : "" || column.columnName === "Description"
                            ? "Description"
                            : "" || column.columnName === "Quantity"
                            ? "Quantity"
                            : "" || column.columnName === "ItemType"
                            ? "Item Type"
                            : "" || column.columnName === "DFECode"
                            ? "DFE Code"
                            : "" || column.columnName === "Condition"
                            ? "Condition"
                            : "" || column.columnName === "LifeExpectancy"
                            ? "Life Expectancy"
                            : "" || column.columnName === "MakeModel"
                            ? "Make Model"
                            : "" || column.columnName === "Length"
                            ? "Length"
                            : "" || column.columnName === "Depth"
                            ? "Depth"
                            : "" || column.columnName === "Height"
                            ? "Height"
                            : "" || column.columnName === "PrimaryColour"
                            ? "Primary Colour"
                            : "" || column.columnName === "SecondaryColour"
                            ? "Secondary Colour"
                            : "" || column.columnName === "DFEStandard"
                            ? "DFE Standard"
                            : "" || column.columnName === "Comments"
                            ? "Comments"
                            : "" || column.columnName === "Cost"
                            ? "Cost"
                            : "" || column.columnName === "CarbonSavingKG"
                            ? "Carbon Saving KG"
                            : "" || column.columnName === "NewRoomName"
                            ? "New Room Name"
                            : "" || column.columnName === "NewRoomNumber"
                            ? "New Room Number"
                            : "" || column.columnName === "PhotographCode"
                            ? "Photograph Code"
                            : ""}
                        </h5>
                      </div>
                      <div>
                        {/* <input
                                        placeholder="Alternate Name"
                                        type="text"
                                        id={`alternateColumnName-${index}`}
                                        className="form-control"
                                        value={column.alternateColumnName}
                                        onChange={(e) => handleUpdateColumnData(index, 'alternateColumnName', e.target.value)}
                                    />
                                    */}
                        {/* <select
                                      id={`alternateColumnName-${index}`}
                                      className="form-control"
                                      value={column.alternateColumnName}
                                      onChange={(e) => handleUpdateColumnData(index, 'alternateColumnName', e.target.value)}
                                    >
                                      <option value="">Select an alternate name</option>
                                      {headings.filter(heading => !selectedHeadings.includes(heading) || heading === column.alternateColumnName).map((heading, idx) => (
                                        <option key={idx} value={heading}>{heading}</option>
                                      ))}
                                    </select> */}
                        <select
                          id={`alternateColumnName-${index}`}
                          className="form-control"
                          value={column.alternateColumnName}
                          onChange={(e) =>
                            handleUpdateColumnData(
                              index,
                              "alternateColumnName",
                              e.target.value
                            )
                          }
                          // onChange={(e) => {
                          //   const value = e.target.value;
                          //   if (value === "") {
                          //       // If "Select alternate name" is selected, set visibility to false
                          //       handleUpdateColumnData(index, 'visible', false);
                          //       handleUpdateColumnData(index, 'alternateColumnName', value);
                          //   } else {
                          //       // Otherwise, update alternate column name
                          //       handleUpdateColumnData(index, 'alternateColumnName', value);
                          //   }
                          //}}
                        >
                          <option value="">Select alternate name</option>
                          {headings
                            .filter(
                              (heading) =>
                                !selectedHeadings.includes(heading) ||
                                heading === column.alternateColumnName
                            )
                            .map((heading, idx) => (
                              <option key={idx} value={heading}>
                                {heading}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              setIsAccordianOpen(false);
              formikSubmitReportCSV.resetForm();
              setCsvReportModel(false);
            }}
          >
            Close
          </Button>
          <Button onClick={() => formikSubmitReportCSV.handleSubmit()}>
            Import CSV
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default ProjectList;
